import React, { useEffect } from 'react';

const ColorPages = () => {
  useEffect(() => {

 // Obtener los datos del localStorage y parsearlos como objeto
 const  data = localStorage.getItem('appDesign');

 if(!data  || data == 'undefined' || data == undefined ){
  
  return  null;
 }

 const appDesign = JSON.parse(data);

 // Verificar que los datos se hayan obtenido correctamente del localStorage
 if (appDesign) {
   // Asignar los valores del objeto a las variables CSS
   document.documentElement.style.setProperty('--primary-color', appDesign.main_color || '#FF7D41');
   document.documentElement.style.setProperty('--primary-color-text', appDesign.text_color_card);
   document.documentElement.style.setProperty('--splash-back-color', appDesign.color_back_splash || '#C8C8CB');
   localStorage.setItem("logo_superior_card", appDesign.logo_superior_card);
   localStorage.setItem("logo_inferior_card", appDesign.logo_inferior_card);
   localStorage.setItem("class_img_end_card", appDesign.class_img_end_card);
   localStorage.setItem("class_img_sup_card", appDesign.class_img_sup_card);
 }

  }, []);
  return () => { };
};

export default ColorPages;
