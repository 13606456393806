
import Headerback from "../layouts/Headerback";
import ItemProductBonocart from "../layouts/ItemProductBonoCart";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getsingle, post } from "../../apiService";
import useCart from "../utils/useCart";
import numeral from "numeral";
const PagoConfirmado = () => {
  const [totacart, setTotalcart] = useState(0);
  const navigate = useNavigate();
  const [isVisiblePayrewards, setisVisiblePayRewards] = useState(false);
  const [isVisiblePayu, setisVisiblePayu] = useState(false);
  const { addToCart, removeFromCart, cartList, total_cart } = useCart();
  const [products, setProducts] = useState([]);
  const [userPoints, setUserPoints] = useState({
    total: 0,
    name: "",
    puntosacanjear: 0,
    puntosrestantes: 0,
  });

  const fetchData = async () => {
    try {
      setProducts(cartList[0].products);
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      setTotalcart(cartList[0].cart.total_price);
      if (cartList[0].cart.cantidad_total == 0) {
        setTotalcart(0);
      }
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };
  
  useEffect(() => {
   
    fetchData();
  }, []);
  const go_bonos = () => {
    navigate("/home/bonos");
  }
  const handleUpdate = () => {
    var cart = JSON.parse(localStorage.getItem('cartList'));
    setTotalcart(cart[0].cart.total_price)


    if (cart[0].cart.total_price <= userPoints.total) {
      setisVisiblePayRewards(true);
    } else {
      setisVisiblePayRewards(false);
    }


    if (cart[0].cart.cantidad_total == 0) {
      setTotalcart(0)
      setisVisiblePayRewards(false);
      setisVisiblePayu(false);
    }



  };
  return (
    <div className="container ">
      <div>
        <img
          width="40"
          height="40"
          src="https://img.icons8.com/ultraviolet/40/ok--v1.png"
          alt="ok--v1"
        />
        <p class="card-text inter_14_gray mt-4" style={{ fontSize: "20px" }}>
          Confirmando el pago
        </p>
      </div>
      <div class=" cardPago row mt-4 mr-2 ml-2">
        <div class=" col-2">
          <img
            width="32"
            height="32"
            src="https://img.icons8.com/windows/32/info.png"
            alt="info"
          />
        </div>
        <div class=" col-10 inter_14_gray left mt-2">
          Estamos validando el pago
          <br />
          <span className="inter_12 gray mt-3 align-items-left">
            Pronto sera confirmado por su banco
          </span>
        </div>
      </div>

      <ul class="cardPago mt-4 " >
        <li className="" style={{ borderBottom: "1px solid gray" }}>
        <div className="container p-0 mb-2 ">
        <div className="col-12 row  d-flex">
          <div className="col-3 d-flex align-items-center">
          <img width="40" height="40" src="https://img.icons8.com/water-color/50/new-post.png" alt="new-post"/>
          </div>
          <div className="col inter_14_gray p-0 left mt-3">
            <span>Email</span>
          </div>
        </div>
      </div>
        </li>
        <li className="mt-2 mb-4" style={{ borderBottom: "1px solid gray" }}>
        <div className="container p-0 mb-2 ">
        <span className=" d-flex inter_14_400">Metodo de pago</span>
        <div className="row d-flex">
          <div className="col-3 d-flex align-items-center">
             
            <img
              width="48"
              height="48"
              src="https://img.icons8.com/color/48/mastercard.png"
              alt="mastercard"
            />
          </div>
          <div className="col  p-0 left mt-2   ">
            
            <span className="inter_14 mt-3 align-items-left">
            Credito **4528
            </span>
          </div>
          
        </div>
      </div>
        </li>
        <li className="mt-2 mb-4" style={{ borderBottom: "1px solid gray" }}>
        <div>
          <div className="d-flex inter_14_400">
            <p>Tu pedido</p>
          </div>
          {totacart > 0 ? (
            <ItemProductBonocart onUpdate={handleUpdate} />
          ) : (
            <div className='mt-2 text-center'>

              <p className='text-center py-5  px-3'>🛒 Tu carrito está vacío 🛒
                ¡Parece que aún no has añadido productos a tu carrito! Explora nuestro catálogo y descubre todo lo que tenemos para ti.
              </p>
              <p className='text-center py-3' style={{ display: "inline-block" }}>
                <button type="button" onClick={go_bonos} className="button-primary">🔍 ¡Explora Bonos!</button>
              </p>
            </div>
          )}

        </div>
        </li>
        <li>
        <div className="container p-0">
        <div className="row ">
          <p className="col align-items-center inter_14_400 justify-content-start " style={{ fontSize: "18px" }}>
            Costo total: 
          </p>
          <p  className="col d-flex justify-content-end inter_14_400 " >
          {numeral(totacart).format("$0,0")}
          </p>
        </div>
      </div>
        </li>
      </ul>
    </div>
  );
};

export default PagoConfirmado;
