import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import ColorPages from '../layouts/Colorpages';
import Headerback from '../layouts/Headerback';
import { getdata, post } from '../../apiService';
import NotificationModal from '../layouts/NotificationModal';


const DatosBancarios = () => {
  const navigate = useNavigate();
  const [banco, setBanco] = useState(-1);
  const [tipoCuenta, setTipoCuenta] = useState('');
  const [cuenta, setCuenta] = useState('');

  const [bancoError, setBancoError] = useState('');
  const [tipoCuentaError, setTipoCuentaError] = useState('');
  const [cuentaError, setCuentaError] = useState('');

  const [bancoFocused, setBancoFocused] = useState(false);
  const [tipoCuentaFocused, setTipoCuentaFocused] = useState(false);
  const [cuentaFocused, setCuentaFocused] = useState(false);
  const [account_type, setAccount_type] = useState([]);
  const [payment_method, setPayment_method] = useState([]);
  const [documentation, set_documentation] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [dataNotification, setdataNotification] = useState('');

  
  const fetchData = async () => {
    try {

      const account_typeResponse = await getdata("account_type");
      setAccount_type(account_typeResponse.data);

      const payment_methodReponse = await getdata("payment_method");
      setPayment_method(payment_methodReponse.data);

      setTipoCuentaFocused(true);
      setBancoFocused(true);


      const get_documentationResponse = await getdata("get_documentation");
      set_documentation(get_documentationResponse.data);
     if(get_documentationResponse.account_number!="")
     {
      setBanco(get_documentationResponse.payment_method.id_payment_method)
      setTipoCuenta(get_documentationResponse.account_type.id_account_type)
      setCuentaFocused(true);
      setCuenta(get_documentationResponse.account_number)
     }


    } catch (error) {
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  const handleInputFocus = (event) => {
    const { name } = event.target;



    if (name === 'cuenta') {
      setCuentaFocused(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'banco') {
      setBanco(value);
    } else if (name === 'tipoCuenta') {
      setTipoCuenta(value);
    } else if (name === 'cuenta') {
      setCuenta(value);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    if (name === 'banco') {
      setBancoError(value.trim() === '' ? 'Este campo es obligatorio' : '');
      setBancoFocused(false);
      if (value !== '') {
        setBancoFocused(true);
      }
    } else if (name === 'tipoCuenta') {
      setTipoCuentaError(value.trim() === '' ? 'Este campo es obligatorio' : '');
      setTipoCuentaFocused(false);
      if (value !== '') {
        setTipoCuentaFocused(true);
      }
    } else if (name === 'cuenta') {
      setCuentaError(value.trim() === '' ? 'Este campo es obligatorio' : '');
      setCuentaFocused(false);
      if (value !== '') {
        setCuentaFocused(true);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;


    if (banco.toString().trim() === '') {
      setBancoError('Este campo es obligatorio');
      hasError = true;
    }

    if (tipoCuenta.toString().trim() === '') {
      setTipoCuentaError('Este campo es obligatorio');
      hasError = true;
    }

    if (cuenta.toString().trim() === '') {
      setCuentaError('Este campo es obligatorio');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const fetchData = async () => {
      try {
        await post({ "account_number": cuenta, "account_type": { "id_account_type": tipoCuenta }, "payment_method": { "id_payment_method": banco } }, "setdocumentation");
        
        setdataNotification("Datos actualizados correctamente");
        setShowNotification(true);
   

      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    navigate('/home/Menu');
  };

  return (
    <div>
                {showNotification && (
      <NotificationModal
          message={dataNotification}
          onClose={handleCloseNotification}
        />
        )}
      <ColorPages />
      <Headerback title="Datos Bancarios" />


      <div className='page px-4'>
        <div className='row mt-3 mt-md-0 justify-content-center'>
          <div className='col'>
        <div className='d-none d-md-block'>
          <h2 className='left mx-md-auto w-50 '>Agrega tus datos bancarios</h2>
        </div>
        <div className='card_gray_light mb-4'>
          <img
            className='mx-3 my-1'
            src='../images/money.png'
            alt="Fleksi Logo"
            style={{ width: '76px', height: 'auto' }}
          />

          <div className='row'>
            <div className='inter_14_400_normal gray-dark'>Agrega una cuenta de banco para hacerte llegar tu cashback</div>

          </div>
          <div className='row mt-5'>

            <div className={`w-100 form-input-container ${bancoFocused ? 'input-filled' : ''}`}>
              <label className="form-label">Banco:</label>
              <select
                name="banco"
                value={banco}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input bg-gray-light">
                <option key="-1" value="-1">Seleccione un Banco</option>
                {payment_method.map((opcion) => (
                  <option key={opcion.id_payment_method} value={opcion.id_payment_method}>{opcion.payment_method}</option>
                ))}
              </select>



              {bancoError && <span className="error-label">{bancoError}</span>}
            </div>
            <div className={`w-100 form-input-container ${tipoCuentaFocused ? 'input-filled' : ''}`}>
              <label className="form-label">Tipo de cuenta</label>
              <select
                name="tipoCuenta"
                value={tipoCuenta}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input bg-gray-light"
              >
                <option key="-1" value="-1">Seleccione tipo de cuenta</option>

                {account_type.map((opcion) => (
                  <option key={opcion.id_account_type} value={opcion.id_account_type}>{opcion.account_type}</option>
                ))}
              </select>
              {tipoCuentaError && <span className="error-label">{tipoCuentaError}</span>}
            </div>
            <div className={`w-100 form-input-container ${cuentaFocused ? 'input-filled' : ''}`}>
              <label className="form-label">Cuenta:</label>
              <input
                type="text"
                name="cuenta"
                value={cuenta}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input bg-gray-light"
              />
              {cuentaError && <span className="error-label">{cuentaError}</span>}
            </div>

          </div>
          <div className='inter_14_400_normal gray-dark'>Agrega una cuenta de banco para hacerte llegar tu cashback</div>

        </div>

        <div className="center-content mt-5">
          <button type="submit" onClick={handleSubmit} className="button-primary">Continuar</button>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default DatosBancarios;
