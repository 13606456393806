import React from "react";
import Headerback from "../layouts/Headerback";
import ColorPages from "../layouts/Colorpages";

const DetalleMetodo = () => {
  const handleEliminarMetodo = () => {
    //eliminar el metodo
  };
  return (
    <div className="container">
      <div>
        <ColorPages></ColorPages>
        <Headerback title="" />
      </div>
      <div className="row">
        <div className="col-9 mt-5 inter_20_normal left ml-3 ">
          <span> Detalle </span>
        </div>
        <div className="col mt-5 inter_14_gray right mr-3">
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/external-bearicons-blue-bearicons/64/external-trash-can-graphic-design-bearicons-blue-bearicons.png"
            alt="external-trash-can-graphic-design-bearicons-blue-bearicons"
            onClick={handleEliminarMetodo}
          />
        </div>
      </div>
      <div className="card_main mt-4 p-0 ">
        <div className="row p-0 m-0">
          <div className="col-8 pl-4  left">
            <img
              src="https://img.icons8.com/fluency/48/sim-card-chip.png"
              alt="sim-card-chip"
              className=""
              style={{ width: "48px", height: "auto", marginTop: "40px" }}
            />
          </div>
          <div className="col p-0 m-0" style={{ marginTop: "38px" }}>
            <div className="inter_16_800 w-100"></div>
            <div className="inter_12 color-white"></div>
          </div>
        </div>

        <div className="row p-0 m-0">
          <div className="col-12 pl-4 pt-3 left inter_16_800">
            xxxx xxxx xxxx 4528
          </div>
          <div className="col-6 inter_16_800 mt-4    ">
            {" "}
            ANDRES GOMEZ
          </div>
          <div className="col-4 inter_16_800 mt-4 right mb-4 ">04/28</div>
        </div>
        
      </div>
      <div className="row ">
      <div className="col-1 mt-5 inter_18_600 ml-3 ">
      <img width="30" height="30" src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/64/external-shield-logistic-delivery-kiranshastry-gradient-kiranshastry.png" alt="external-shield-logistic-delivery-kiranshastry-gradient-kiranshastry"/>
        </div>
        <div className="col-8 mt-5 inter_18_600 left  align-items-center">
          <span> Pendientes de verificacion </span>
        </div>
        <div
          className="col mt-5 inter_14_gray right  align-items-center"
          
        >
          <img width="30" height="30" src="https://img.icons8.com/fluency/48/forward--v1.png" alt="forward--v1"/>
        </div>
      </div>
    </div>
  );
};

export default DetalleMetodo;
