import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import { getsingle } from "../../apiService";
import numeral from "numeral";
import useCart from "../utils/useCart";
import { useNavigate } from "react-router-dom";
import ItemProductBonocart from "../layouts/ItemProductBonoCart";
import ColorPages from "../layouts/Colorpages";
const Cart = () => {
  const { cartList, update_rewards } = useCart();
  const [products, setProducts] = useState([]);
  const [isVisiblePayu, setisVisiblePayu] = useState(false);
  const [isVisiblePayrewards, setisVisiblePayRewards] = useState(false);
  const [userPoints, setUserPoints] = useState({
    total: 0,
    name: "",
    puntosacanjear: 0,
    puntosrestantes: 0,
  });
  const [totacart, setTotalcart] = useState(0);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const fetchData = async () => {
    try {
      setProducts(cartList[0].products);
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      setTotalcart(cartList[0].cart.total_price);
      if (cartList[0].cart.cantidad_total == 0) {
        setTotalcart(0);
      }
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleGoPagar = () => {
    if (isChecked == true) {
      var valor_pse = Math.max(0, totacart - userPoints.total);
      if (valor_pse > 0) {
        update_rewards(userPoints.total, valor_pse);
        localStorage.setItem("valor_pse", valor_pse);
        navigate("/home/Psepayment");
      }
    } else {
      update_rewards("0", totacart);
      localStorage.setItem("valor_pse", totacart);
      navigate("/home/Psepayment");
    }
  };

  const handleUpdate = () => {
    var cart = JSON.parse(localStorage.getItem("cartList"));
    setTotalcart(cart[0].cart.total_price);

    if (cart[0].cart.total_price <= userPoints.total) {
      setisVisiblePayRewards(true);
    } else {
      setisVisiblePayRewards(false);
    }

    if (cart[0].cart.cantidad_total == 0) {
      setTotalcart(0);
      setisVisiblePayRewards(false);
      setisVisiblePayu(false);
    }
  };

  const go_bonos = () => {
    navigate("/home/Category?code=3");
  };
  const handleGoCambiarMetodoPago = () => {
    navigate("/home/MetodosDePago");
  };
  return (
    <div>
      <ColorPages />
      <div>
        <Headerback title="Tus compras" />
      </div>

      <div style={{ height: "calc(70vh - 100px)", marginTop: "100px" }}>
        {totacart > 0 ? (
          <div>
            <div className="content-container mx-auto">
              <div className="d-flex flex-row justify-content-between my-3 ">
                <div className=" inter_18_gray left ml-2 ">
                  <span> Metodo de pago </span>
                </div>
                <div
                  className="inter_14_gray right mr-2"
                  style={{ color: "#53B5F6" }}
                >
                  <span onClick={handleGoCambiarMetodoPago}>Cambiar</span>
                </div>
              </div>
              <div className="container ml-2">
                <div className="col-12 row  d-flex">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      width="48"
                      height="48"
                      src={process.env.PUBLIC_URL + "/images/icon_pse.jpg"}
                      alt="mastercard"
                    />
                  </div>
                  <div className="col inter_14_gray p-0 left mt-2   ">
                    <span>PSE ACH</span>
                    <br />
                    <span className="inter_12 gray mt-3 align-items-left"></span>
                  </div>
                  <div className="col-3 mr-4 mt-3">
                    <p className="inter_14_gray ml-2 "> </p>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "gray ",
                }}
              />
              <div className=" my-3 inter_18_gray left ml-2 ">
                <span> Tus productos </span>
              </div>
              <div className="container ml-2">
                <div className="col-12 row  d-flex">
                  <div className="col-3 d-flex align-items-center">
                    <img
                      width="48"
                      height="48"
                      src="../images/logo_letra_azul.png"
                      alt="puntos"
                    />
                  </div>
                  <div className="col inter_14_gray p-0 left mt-3 ">
                    <span>Puntos Fleksi</span>
                  </div>
                  <div className="col d-flex  justify-content-space-start mt-3">
                    <p className="inter_14_gray ml-2 "> {userPoints.total} </p>
                    <input
                      type="checkbox"
                      className="mb-3 ml-3"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "none",
                  height: "2px",
                  backgroundColor: "gray ",
                }}
              />
              <div
                style={{ height: `calc(100vh - 120px)`, overflow: "scroll" }}
              >
                <ItemProductBonocart onUpdate={handleUpdate} />
              </div>
            </div>
            <div
              className="content-container  mx-auto p-0 m-0"
              style={{ position: "fixed", bottom: "0px", width: "100%",right: "20px", left: "20px",  }}
            >
              <hr
                className="mx-2"
                style={{
                  border: "none",
                  height: "2px",
                  background:
                    "linear-gradient(to right, transparent, #53B5F6, transparent)",
                }}
              ></hr>

              <div className="row mt-3">
                <div className="col p-0 m-0  d-inline">
                  <div className="ml-3">
                    <img
                      width="25"
                      height="25"
                      src="../images/logo_letra_azul.png"
                      alt="puntos"
                    />
                  </div>
                  <div className="ml-2">
                    {isChecked
                      ? totacart > userPoints.total
                        ? numeral(userPoints.total).format("$0,0")
                        : numeral(totacart).format("$0,0")
                      : numeral(0).format("$0,0")}
                  </div>
                </div>

                <div className="col p-0 m-0  d-inline">
                  <div>
                    <img
                      width="20"
                      height="20"
                      src={process.env.PUBLIC_URL + "/images/icon_pse.jpg"}
                      alt="mastercard"
                    />
                  </div>
                  <div className="ml-2">
                    {isChecked &&
                      numeral(Math.max(0, totacart - userPoints.total)).format(
                        "$0,0"
                      )}
                    {!isChecked &&
                      numeral(Math.max(0, totacart)).format("$0,0")}
                  </div>
                </div>
              </div>

              <div className="row  m-0 p-0 mt-2 mb-2 mx-4">
                <p
                  className="col align-items-center d-flex justify-content-start"
                  style={{ fontSize: "18px" }}
                >
                  Total :{numeral(totacart).format("$0,0")}
                </p>
                <div className="col d-flex justify-content-end py-2" style={{}}>
                  <button
                    style={{ width: "80px", minWidth: "80px" }}
                    className="button-primary"
                    onClick={handleGoPagar}
                  >
                    Pagar
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="text-center content-container mx-auto"
            style={{ marginTop: "100px" }}
          >
            <p className="text-start py-5  px-3">
              🛒 Tu carrito está vacío 🛒 ¡Parece que aún no has añadido
              productos a tu carrito! Explora nuestro catálogo y descubre todo
              lo que tenemos para ti.
            </p>
            <p className="text-center py-3" style={{ display: "inline-block" }}>
              <button
                type="button"
                onClick={go_bonos}
                className="button-primary"
              >
                🔍 ¡Explora Bonos!
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
