import React from 'react';

import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import TransactionCard from '../layouts/TransactionCard';
import ColorPages from '../layouts/Colorpages';

const Verhistorial = () => {


  return (
    <div>
      <ColorPages />
      <HeaderTitle title="Historial de puntos por confirmar" />
      <div className='page px-4 py-4'>
        <div className='row'>
          <div className='content-container mx-auto'>
        <TransactionCard />
        </div>
        </div>
      </div>
    </div>
  );
};

export default Verhistorial;
