import React, { useEffect, useState } from "react";
import { getdata, getsingle, post } from "../../apiService";
import NotificationModal from "./NotificationModal";

const ModalDocumentTermsConditions = ({ activated, handleCloseModal }) => {
  const [documentFocused, setDocumentFocused] = useState(false);
  const [documentValue, setDocumentValue] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [termsUrl, setTermsUrl] = useState("");
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [TermsConditions, setTermsConditions] = useState({
    authorization: false,
    terms: false,
    accepted: false,
  });

  const handleTerminosChange = (event) => {
    setTermsConditions((prevTermsConditions) => ({
      ...prevTermsConditions,
      [event]: !prevTermsConditions[event],
      accepted: !prevTermsConditions[event] && prevTermsConditions.accepted,
    }));
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  const handleInputChange = (event) => {
    setDocumentValue(event.target.value);
  };

  const handleInputFocus = () => {
    setDocumentFocused(true);
  };

  const handleInputBlur = () => {
    setDocumentFocused(false);
  };
  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  const fetchData = async () => {
    try {

      const response = await post(
        { location: 0, device: 'mobile' },
        "acceptTerms"
      );

      console.log(response);
      setShowNotification(true);

      setTimeout(() => {
        handleCloseModal();
      }, 2000);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };
  const updateTerms = () => {
   
    fetchData();
  };


  return (
    <div id="modal" className={`modal ${activated ? "active" : ""}`}>
      {showNotification && (
        <NotificationModal
          message="¡Buenas noticias! haz aceptado nuestros terminos y condiciones"
          onClose={handleCloseNotification}
        />
      )}
      <div className="modal-content">
        <div className="inter_12_700 mt-2 left ">
          <p >Hemos actualizado nuestras condiciones</p>
          
        </div>
        <div className="inter_14_500 mt-2 ">
          <p className="left">Revisa nuestras actualizaciones a continuacion. 
          Si sigues utilizando Fleksi estaras aceptando estas actualizaciones.</p>
        </div>
        <div className="row mt-3 color-primary d-flex  align-items-center ">
        <div className="inter_12_700  mt-2 col-6">
          <div >
            
           Terminos y condiciones
         
          </div>
        </div>
        <div className="inter_12_700  mt-2 col-6">
          <div >
            
           Politica de privacidad
           
          </div>
        </div>
        </div>
        <div className="modal-footer">
          <a className="close-link" onClick={updateTerms}>
            Continuar
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalDocumentTermsConditions;
