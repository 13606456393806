import React, { useState } from 'react';
import { post } from '../../apiService';
import Headerback from '../layouts/Headerback';
import ColorPages from '../layouts/Colorpages';


const ChangePasswordView = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleInputFocus = (event) => {
    const { name } = event.target;

    if (name === 'newPassword') {
      setNewPasswordFocused(true);
    } else if (name === 'confirmPassword') {
      setConfirmPasswordFocused(true);
    }
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    if (name === 'newPassword') {
      setNewPasswordError(value.trim() === '' ? 'Este campo es obligatorio' : '');
      setNewPasswordFocused(false);
      if (value !== '') {
        setNewPasswordFocused(true);
      }
    } else if (name === 'confirmPassword') {
      setConfirmPasswordError(value.trim() === '' ? 'Este campo es obligatorio' : '');
      setConfirmPasswordFocused(false);
      if (value !== '') {
        setConfirmPasswordFocused(true);
      }
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Las contraseñas no coinciden.');
      return false;
    }
    if (newPassword.length < 8) {
      setNewPasswordError('La contraseña debe tener al menos 8 caracteres.');
      return false;
    }
    if (!/[A-Z]/.test(newPassword)) {
      setNewPasswordError('La contraseña debe contener al menos una letra mayúscula.');
      return false;
    }
    setNewPasswordError('');
    setConfirmPasswordError('');
    return true;
  };

  const resetPassword = async (password) => {
    const jsonObject = {
      password: password,
    };

    try {
      const response = await post(jsonObject, 'resset_password');
      setNewPasswordError('Contraseña actualizada con éxito.');
    } catch (error) {
      setNewPasswordError('Error al actualizar la contraseña. Inténtalo de nuevo.');
    }
    return () => {};
  };

  const handleSave = () => {
    if (validatePasswords()) {
      resetPassword(newPassword);
    }
  };

  return (
    <div>
    <ColorPages />
     <Headerback title="Cambiar contraseña" />
  <div className='page' style={{ padding: '20px' }}>
    <h1 style={{ fontFamily:'Inter', fontSize:'32px', fontWeight:'600',marginBottom:'40px'}}>Cambio de Contraseña</h1>
    <form className="card-form mb-4 mx-auto pt-3">
      <div className="center-content w-100 mb-3">
        <div className={`w-75 form-input-container ${newPasswordFocused ? 'input-filled' : ''}`}>
          <label className="form-label">Nueva Contraseña</label>
          <input
            type={showNewPassword ? 'text' : 'password'}
            name="newPassword"
            value={newPassword}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className="form-input"
          />
          <span className="password-toggle" onClick={toggleNewPasswordVisibility}>
            <img style={{ width: "25px" }}
              src={showNewPassword ? process.env.PUBLIC_URL + '/images/showpassword.png' : process.env.PUBLIC_URL + '/images/hidepassword.png'}
              alt={showNewPassword ? 'Show Password' : 'Hide Password'}
            />
          </span>
          {newPasswordError && <span className="error-label">{newPasswordError}</span>}
        </div>
        <div className={`w-75 form-input-container ${confirmPasswordFocused ? 'input-filled' : ''}`}>
          <label className="form-label">Confirmar Contraseña</label>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className="form-input"
          />
          <span className="password-toggle" onClick={toggleConfirmPasswordVisibility}>
            <img style={{ width: "25px" }}
              src={showConfirmPassword ? process.env.PUBLIC_URL + '/images/showpassword.png' : process.env.PUBLIC_URL + '/images/hidepassword.png'}
              alt={showConfirmPassword ? 'Show Password' : 'Hide Password'}
            />
          </span>
          {confirmPasswordError && <span className="error-label">{confirmPasswordError}</span>}
        </div>
        <button type="button" onClick={handleSave} className="button-primary" style={{maxWidth:'300px'}}>Cambiar Contraseña</button>
      </div>
    </form>
  </div>
  </div>
  );
};

export default ChangePasswordView;
