import React from 'react';

const TabLayout = ({ key, title, description, active, handleTabClick, Link_video = null, Link = null, ButtonText = null }) => {

  const goexternallink = (link) => {
    window.open(link, '_blank');
  };

  return (
    <li key={key} style={{ marginTop: "30px", marginBottom: "20px" }} className={` p-0 tab-item ${active ? 'active' : ''}`} onClick={handleTabClick}>
      <div className="tab-header">
        <div className="inter_14_500 color-primary text-responsive">{title}</div>
        <img
          src={`${process.env.PUBLIC_URL}/images/${active ? 'closed.png' : 'closed.png'}`}
          alt="Estado del acordeón"
          className={active ? 'rotate-icon' : ''}
        />
      </div>
      {active && (
        <div className="tab-content  p-0 mt-3">
          <p className="inter_12_400 text-responsive left">{description}</p>
          {Link_video && (
            <div onClick={() => goexternallink(Link_video)} style={{ textAlign: 'left !important' }}
              className="mt-3 left color-primary inter_12_400 left">
              Mira el video
            </div>
          )}
          {Link && ButtonText && (
            <div className='col m-0 p-0 center mt-2'>
              <button onClick={() => goexternallink(Link)}
                className="mt-3 button-primary "  style={{ height:'30px', width:"250px" }}>
                {ButtonText}
              </button>
            </div>
          )}

        </div>
      )}
    </li>
  );
};

export default TabLayout;
