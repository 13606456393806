import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import ColorPages from '../layouts/Colorpages';
import { getdata, post } from '../../apiService';
import Headerback from '../layouts/Headerback';
import { BsShop } from 'react-icons/bs';
import ItemHistorialCompras from '../layouts/ItemHistorialCompras';

const HistorialCompras = () => {

  const [transactions, setTransactions] = useState([]);

  const fetchData = async () => {
    try {

      const transactions = await post({},"get_shop_cart_data");
      setTransactions(transactions);
    

    } catch (error) {
      console.error(error);
    }

    return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
  <div>
       <ColorPages />
      <Headerback title="Historial de compras" />
      <div className='page px-4 py-4 container-historial'>

{transactions.length === 0 && 
  <div className="text-center">
    <BsShop size={60}   /> 
    <p  style={{ marginTop:"25px" }}>No has realizado ninguna compra aún. ¿Por qué no echas un vistazo a nuestros productos?</p>
  </div>
}


        <ItemHistorialCompras transactions={transactions} />
      </div>
    
  </div>
  );
};

export default HistorialCompras;
