import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';
import { getsingle , post, postReponse} from '../../apiService';
import Headerback from '../layouts/Headerback';
import numeral from 'numeral';
import NotificationModal from '../layouts/NotificationModal';

const Solicitarpuntos = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState('100%');
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [userPoints, setUserPoints] = useState({ total: 0, name: "", puntosacanjear: 0, puntosrestantes: 0 });
  
  const [showNotification, setShowNotification] = useState(false);
  const [dataNotification, setdataNotification] = useState('');

  const userPointsDatag = async () => {
    try {

      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      setUserPoints(prevUserPoints => ({ ...prevUserPoints, puntosacanjear: userPointsData.total, puntosrestantes: 0 }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => { 
    userPointsDatag();
  }, []);

  const goendcanjear = () => {
 
    const bonoReponseg = async () => {
      try {
        const bonoReponse =    await postReponse({ 
          "total_points_remaining": userPoints.puntosrestantes,
         "total_points_requested": userPoints.puntosacanjear,
         "monetizable_points":userPoints.puntosacanjear,
         "fleksi_points":userPoints.total }, "monetize");
       
       if(bonoReponse.code==200)
       {

        setShowNotification(true);
        setdataNotification("TU SOLICITUD HA SIDO CREADA");
        setTimeout(() => {
        navigate('/home/Menu');
       }, 4000);

        navigate("/home/Endcanjear")
       }
      else
       {
        setdataNotification( bonoReponse.data);
         setShowNotification(true);
       }



      } catch (error) {
        console.error(error);
      }
    };
    bonoReponseg();


  };

  const handleButtonClick = (buttonValue) => {
    setSelectedButton(buttonValue);

    if (buttonValue == "100%") {
      setUserPoints(prevUserPoints => ({ ...prevUserPoints, puntosacanjear: userPoints.total, puntosrestantes: 0 }));
    }
    if (buttonValue == "70%") {
      setUserPoints(prevUserPoints => ({ ...prevUserPoints, puntosacanjear: userPoints.total * 0.70, puntosrestantes: userPoints.total * 0.30 }));
    }
    if (buttonValue == "50%") {
      setUserPoints(prevUserPoints => ({ ...prevUserPoints, puntosacanjear: userPoints.total * 0.50, puntosrestantes: userPoints.total * 0.50 }));

    }
    if (buttonValue == "25%") {
      setUserPoints(prevUserPoints => ({ ...prevUserPoints, puntosacanjear: userPoints.total * 0.30, puntosrestantes: userPoints.total * 0.70 }));

    }
  };

  const handleDeliveryChange = (delivery) => {
    setSelectedDelivery(delivery);
  };

  
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <div>
            {showNotification && (
      <NotificationModal
          message={dataNotification}
          onClose={handleCloseNotification}
        />
        )}
      <ColorPages />
      <Headerback title="" />
      <div className='page px-4 content-container mx-auto d-flex flex-column align-items-center '>
        <div className='inter_20_500 gray-dark left mt-3'>¿Cuantos puntos quieres redimir hoy?</div>

        <div className='center m-0 p-0 py-3 mt-2 d-flex flex-row gap-4 w-100 '>
          <div
            className={`mx-1 py-3 button_card ${selectedButton === '100%' ? 'button_card_pressed' : ''}`}
            onClick={() => handleButtonClick('100%')}
          >
            100%
          </div>
          <div
            className={`mx-1 py-3 button_card ${selectedButton === '70%' ? 'button_card_pressed' : ''}`}
            onClick={() => handleButtonClick('70%')}
          >
            70%
          </div>
          <div
            className={`mx-1 py-3 button_card ${selectedButton === '50%' ? 'button_card_pressed' : ''}`}
            onClick={() => handleButtonClick('50%')}
          >
            50%
          </div>
          <div
            className={`mx-1 py-3 button_card ${selectedButton === '25%' ? 'button_card_pressed' : ''}`}
            onClick={() => handleButtonClick('25%')}
          >
            25%
          </div>
        </div>

        <div className='mt-4 px-4 w-50 mx-auto'>
          <div className='row'>
            <div className='col-7  left pl-3 inter_12'>Puntos a canjear:</div>
            <div className='col left inter_12'> {numeral(userPoints.puntosacanjear).format('0,0')} puntos</div>
          </div>
          <div className='row mt-2'>
            <div className='col-7 left pl-3 inter_12'>Puntos restantes:</div>
            <div className='col left inter_12'>{numeral(userPoints.puntosrestantes).format('0,0')} puntos</div>
          </div>
          <div className='row mt-2'>
            <div className='col-7 left pl-3 inter_12'>Dinero canjeado:</div>
            <div className='col left inter_12'>{numeral(userPoints.puntosacanjear).format('$0,0')} pesos</div>
          </div>
        </div>

{/* 
        <div className='mt-5 mx-4'>
          <div className='row'>
            <div className='col left inter_12 p-0 m-0'>
              <p className='inter_14_400 color-black'>Entrega éstandar:</p>
            </div>
            <div className='col left inter_12 p-0 m-0'>
              <div className='row col-12 p-0 m-0'>
                <div className='col-8 right p-0 m-0'>
                  +0 puntos
                </div>
                <div className='col ml-2 p-0 m-0'>
                  <input
                    type='radio'
                    name='delivery'
                    value='standard'
                    checked={selectedDelivery === 'standard'}
                    onChange={() => handleDeliveryChange('standard')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row left'>
            <p className='inter_10_400 mt-2'>Los puntos pueden tardar más tiempo en llegar</p>
          </div>
          <div className='bg-gray-light mt-4' style={{
            width: '100%',
            height: '0.5px'
          }}></div>
          <div className='row mt-4'>
            <div className='col left inter_12 p-0 m-0'>
              <p className='inter_14_400 color-black'>Entrega rápida:</p>
              <p className='inter_10_400 mt-1'>Los puntos llegan hoy mismo</p>
            </div>
            <div className='col left inter_12 p-0 m-0 mt-2'>
              <div className='row col-12 p-0 m-0'>
                <div className='col-8 right p-0 m-0'>
                  +50 puntos
                </div>
                <div className='col ml-2 p-0 m-0'>
                  <input
                    type='radio'
                    name='delivery'
                    value='fast'
                    checked={selectedDelivery === 'fast'}
                    onChange={() => handleDeliveryChange('fast')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        */}

      </div>

      <div className="lower-30-half border-box-top bg-white ">
        <div className="center-content">
          <button type="button" onClick={goendcanjear} className="button-primary">Canjear</button>
        </div>
      </div>
    </div>
  );
};

export default Solicitarpuntos;
