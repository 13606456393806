import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postsingle } from '../../apiService';
import Headerback from '../layouts/Headerback';
import ImageComponent from '../layouts/ImageComponent';
import TabLayout from '../layouts/TabLayout';
import TabLayoutLocation from '../layouts/TabLayoutLocation';
import TabLayoutHorarios from '../layouts/TabLayoutHorarios';
import TabLayoutLocation_open_google from '../layouts/TabLayoutLocation_open_google';
import ColorPages from '../layouts/Colorpages';


const UbicacionesExternal = () => {
    const [bono, setBono] = useState({});
    const [related_products, setRelated_products] = useState([]);
    const [related_productstwo, setRelated_productstwo] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const [activeTabs, setActiveTabs] = useState([0, 4]);

    const fetchData = async () => {
        try {
            const TiendaFisicaResponse = await postsingle({ "id_fleksi_products": code }, "productoexternal");

            setBono(TiendaFisicaResponse);
            setRelated_products(TiendaFisicaResponse.related_products.productsone);
            setRelated_productstwo(TiendaFisicaResponse.related_products.productstwo);

        } catch (error) {
            console.error(error);
        }
        return () => {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleTabClick = (index) => {
        if (activeTabs.includes(index)) {
            setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
        } else {
            setActiveTabs([...activeTabs, index]);
        }
    };

    return (
        <div>
            <ColorPages />
            <Headerback title={bono.ecommerce} />
            <div className='page px-4'>
                <div className='mt-2 center' style={{ height: "250px" }}>

                    <ImageComponent
                        src={bono.image}
                        alt={bono.shop_title}
                        defaultSrc={process.env.PUBLIC_URL + '/images/defaultimage.png'}
                        cssclass={'img-bono'} />00000000000000000
                </div>

                <div className='mt-2 col-12 row mt-4 '>
                    <div className='col'>
                        <p className='inter_22_400 left gray-dark'>{bono.title}</p>
                    </div>

                </div>

                <p className="inter_12_400 left">{bono.shop_subtitle}</p>

                <p className="inter_12_400 left">{bono.benefits}</p>

                <div className="tab-layout" >
                    <div className="tab-navigation p-0 m-0 ">
                        <ul>
                            {bono?.horarios && Object.keys(bono.horarios).length > 0 && (

                                <TabLayoutHorarios
                                    key={0}
                                    title="Horarios"
                                    locations={bono.horarios}
                                    active={activeTabs.includes(0)}
                                    handleTabClick={() => handleTabClick(0)}
                                />
                            )}

                            {bono?.locations && Object.keys(bono.locations).length > 0 && (
                                <TabLayoutLocation_open_google
                                    title="Ubicaciones"
                                    active={activeTabs.includes(4)}
                                    handleTabClick={() => handleTabClick(4)}
                                    locations={bono.locations}
                                    code={code}
                                />
                            )}



                        </ul>
                    </div>
                </div>

                <p className='inter_14_500 color-primary mt-3 left'>Cerca a mi</p>

                <div className='mt-3'>

                    <iframe
                        title='Google Maps'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1577.6743687967827!2d-74.07209259999999!3d4.609708300000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f8b74ff2e0aeb%3A0x1ff4df678260887f!2sCalle%2085%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1sen!2sus!4v1626538566510!5m2!1sen!2sus'
                        width='100%'
                        height='450'
                        style={{ border: 0 }}
                        loading='lazy'
                    ></iframe>
                </div>
            </div>

        </div>
    );
};

export default UbicacionesExternal;
