import React, { useEffect, useState } from "react";
import { getsingle, post } from "../../apiService";
import { useNavigate } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import useCart from "../utils/useCart";
import ItemProductBonocart from "../layouts/ItemProductBonoCart";
import ColorPages from "../layouts/Colorpages";
const ConfirmarPago = () => {
  const { addToCart, removeFromCart, cartList, total_cart } = useCart();
  const [products, setProducts] = useState([]);
  const [userPoints, setUserPoints] = useState({
    total: 0,
    name: "",
    puntosacanjear: 0,
    puntosrestantes: 0,
  });
  const [totacart, setTotalcart] = useState(0);
  const navigate = useNavigate();
  const [isVisiblePayrewards, setisVisiblePayRewards] = useState(false);
  const [isVisiblePayu, setisVisiblePayu] = useState(false);

  const fetchData = async () => {
    try {
      setProducts(cartList[0].products);
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      setTotalcart(cartList[0].cart.total_price);
      if (cartList[0].cart.cantidad_total == 0) {
        setTotalcart(0);
      }
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {    
    fetchData();
  }, []);
  const go_bonos = () => {
    navigate("/home/bonos");
  }
  const handelegoPagoConfirmado = () => {
    navigate("/home/PagoConfirmado");
  };
  const handleUpdate = () => {
    var cart = JSON.parse(localStorage.getItem('cartList'));
    setTotalcart(cart[0].cart.total_price)


    if (cart[0].cart.total_price <= userPoints.total) {
      setisVisiblePayRewards(true);
    } else {
      setisVisiblePayRewards(false);
    }


    if (cart[0].cart.cantidad_total == 0) {
      setTotalcart(0)
      setisVisiblePayRewards(false);
      setisVisiblePayu(false);
    }



  };
  return (
    <div className="container">
        <ColorPages/>
      <div>
        <Headerback title="Confirmar detalles de pago" />
      </div>
      <div className="row mt-3">
        <div className="col mt-5 inter_14_gray left ml-2 align-items-center ">
          <span> Informacion Detallada </span>
        </div>
      </div>
      <div className="container ml-2">
        <div className="col-12 row  d-flex">
          <div className="col-3 d-flex align-items-center">
          <img width="40" height="40" src="https://img.icons8.com/water-color/50/new-post.png" alt="new-post"/>
          </div>
          <div className="col inter_14_gray p-0 left mt-3">
            <span>Email</span>
          </div>
        </div>
      </div>
      <hr className="mx-2" />
      <div className="container ml-2">
        <div className="col-12 row  d-flex">
          <div className="col-3 d-flex align-items-center">
            <img
              width="48"
              height="48"
              src="https://img.icons8.com/color/48/mastercard.png"
              alt="mastercard"
            />
          </div>
          <div className="col inter_14_gray p-0 left mt-2   ">
            <span>Credito **4528</span>
            <br />
            <span className="inter_12 gray mt-3 align-items-left">
              Andres Gomez
            </span>
          </div>
        </div>
      </div>
      <hr className="mx-2"/>
      <div className="container ml-2">
        <div className="col-12 row  d-flex">
          <div className="col-3 d-flex align-items-center">
            <img
              width="48"
              height="48"
              src="../images/logo_letra_azul.png"
              alt="puntos"
            />
          </div>
          <div className="col inter_14_gray p-0 left mt-2 ">
            <span>Puntos Fleksi</span>
            <br />
            <span className="inter_12 gray mt-3 align-items-left"> {userPoints.total} </span>
          </div>
        </div>
      </div>
      <hr className="mx-2" />
      <div>
          {totacart > 0 ? (
            <ItemProductBonocart onUpdate={handleUpdate} />
          ) : (
            <div className='mt-2 text-center'>

              <p className='text-center py-5  px-3'>🛒 Tu carrito está vacío 🛒
                ¡Parece que aún no has añadido productos a tu carrito! Explora nuestro catálogo y descubre todo lo que tenemos para ti.
              </p>
              <p className='text-center py-3' style={{ display: "inline-block" }}>
                <button type="button" onClick={go_bonos} className="button-primary">🔍 ¡Explora Bonos!</button>
              </p>
            </div>
          )}

        </div>
        <div className="container">
        <div className="d-flex justify-content-center mt-5">
          <button className=" button-primary" onClick={handelegoPagoConfirmado}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmarPago;
