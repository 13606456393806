import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import ItemProductBono from "../layouts/ItemProductBono";
import CardComponentVerticalsmall from "../layouts/CardComponentVerticalsmall";
import TabLayout from "../layouts/TabLayout";
import ItemProductEcommerce from "../layouts/ItemProductEcommerce";
import { getdata, post, postarray } from "../../apiService";
import ImageComponent from "../layouts/ImageComponent";
import numeral from "numeral";
import ColorPages from "../layouts/Colorpages";

const EcommerceProducto = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState({});
  const [related_products, setRelated_products] = useState([]);
  const [related_productstwo, setRelated_productstwo] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const fetchData = async () => {
    try {
      const productResponse = await postarray(
        { id_fleksi_products: code },
        "productoecommerce_v3"
      );
      setProduct(productResponse);
      setRelated_products(productResponse.related_products.productsone);
      setRelated_productstwo(productResponse.related_products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goEcommerce = (link, code) => {
    const fetchData = async () => {
      try {
        await postarray({ id_items: code }, "save_buy_intention");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    window.open(link, "_blank");
  };

  const [activeTabs, setActiveTabs] = useState([0]);

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };
  const text_color_percentage = localStorage.getItem("text_color_percentage");
  return (
    <div>
      <ColorPages />
      <Headerback title={product.ecommerce} />
      <div className="page  mx-md-4 px-4 ">
        <div className="bono-container">
          <div className="image-container mt-2">
            <ImageComponent
              src={product.image}
              alt={product.title}
              defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
              cssclass={"img-bono"}
            />
          </div>

          <div className="info-container mt-4">
            <p className="inter_12 gray-dark left mt-2 m-0 subtitle-product">{product.subtitle}</p>
            <div 
              className="inter_14_700 left color-green ml-1 d-flex flex-row align-items-center justify-content-start gap-5"
              style={{ color: text_color_percentage }}
            >
            <p className="inter_18_600 left gray-dark mt-3">
              {numeral(product.valor_de_venta).format("$0,0")}
            </p>
            
              {product.shop_text_points}
            </div>
            <div className="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-start ">
            <button
                type="button"
                onClick={() => goEcommerce(product.link, product.id_items)}
                className="button-primary "
              >
               {product.Buttontext || "Ver en tienda Oficial"}
              </button>
              </div>
              <p className="inter_14_700 left  subtitle-product mt-4" >{product.instrucciones}</p>
          </div>
        </div>
        <div className="tab-layout ">
          <div className="tab-navigation">
            <ul>
              {product.benefits && (
                <TabLayout
                  key={3}
                  title="Beneficios"
                  description={product.benefits}
                  active={activeTabs.includes(0)}
                  handleTabClick={() => handleTabClick(0)}
                />
              )}
              {product.terms_and_conditions && (
                <TabLayout
                  key={4}
                  title="Términos y condiciones"
                  description={product.terms_and_conditions}
                  active={activeTabs.includes(1)}
                  handleTabClick={() => handleTabClick(1)}
                />
              )}
            </ul>
          </div>
        </div>

        <div style={{ marginTop: "32px" }}>
          <ItemProductEcommerce
            title={"Productos que te podrían gustar"}
            data={related_products}
          />
          <ItemProductEcommerce title={""} data={related_productstwo} />
        </div>
      </div>
    </div>
  );
};

export default EcommerceProducto;
