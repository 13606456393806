import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';
import { postarray, postsingle } from '../../apiService';

const Endbeneficios = () => {
  const navigate = useNavigate();
  const [bono, setBono] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const [benefitslist, setbenefitslist] = useState([]);
  const goubicaciones = (image, id_fleksi_products) => {
    localStorage.setItem('TiendaProductoFisicoImage', image)
    localStorage.setItem('TiendaProductoFisicoid_fleksi_products', id_fleksi_products);
    navigate(`/home/EndTienda`);
  };


  const fetchData = async () => {
    try {
      const TiendaFisicaResponse = await postarray({ "id_fleksi_products": code }, "tienda");
      setbenefitslist(TiendaFisicaResponse.benefitslist)
      setBono(TiendaFisicaResponse);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='App bg-primary-color' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <ColorPages />
      <div className='w-100 bg-primary-color pt-3' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
        <div className="banner">
          <div className="left-div" onClick={handleGoBack}>
            <img
              src={process.env.PUBLIC_URL + '/images/img-back-white.png'}
              alt="Slider 1"
              style={{ width: '28px', height: 'auto' }}
            />
          </div>
          <div className="center-div align-items-center">
            <div className="header-title left w-100"></div>
          </div>
          <div className="right-div"></div>
        </div>
      </div>
      <div className=' col-12 '>

        <div className=' col-12 '>

          {benefitslist.length > 0 ? (
            benefitslist.map((benefit, index) => (
              <div className='w-100 color-white inter_18 pb-3' key={index}>{benefit.benefits}</div>
            ))
          ) : (
            <></>
          )}

        </div>


        <div className=' col-12 center '>
          <button type="button" onClick={goubicaciones} className="button-secundary-login  button-text bg-white">Conoce ubicaciones</button>
        </div>

      </div>

    </div>

  );
};

export default Endbeneficios;
