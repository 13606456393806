import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getsingle } from '../../../apiService';
import Headerback from '../../layouts/Headerback';


const Terminosycondiciones = () => {
  

    return (
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Headerback title="Términos y Condiciones" />
          <iframe
            src="https://fleksirewards.com/terminosycondiciones_app.html"
            title="Términos y Condiciones"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          ></iframe>
        </div>
      );
    };
export default Terminosycondiciones;
