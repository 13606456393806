import React, { useState } from 'react';
import {  Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { post } from '../../../apiService';
import Headerback from '../../layouts/Headerback';


const PasswordRecovery = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [focused, setFocused] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordRecovery = async () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      setEmailError('Por favor ingresa un correo electrónico válido.');
      return;
    } else {
      setEmailError('');
    }
    try {
      const data = { email };
      const response = await post(data, 'solicitarpassword');
      console.log('Recuperación de contraseña solicitada:', response);
      setShowModal(true);
    } catch (error) {
      console.error('Error al solicitar la recuperación de contraseña:', error);
    }
  };


  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === '') {
      setFocused(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
    <Headerback title="Restablecer contraseña" />
    <div className='page'>
    <Container className="d-flex flex-column align-items-center justify-content-start vh-100 mt-4">
      <Row className="w-100">
        <Col>
          <div className=" p-4 rounded shadow content-container mx-auto ">
            <p className="text-center mb-4">
              Ingresa tu dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña de inmediato.
            </p>
            <form className="password-recovery-form">
            <div className={`form-input-container ${focused ? 'input-filled' : ''}`}>
                            <label className="form-label">Correo Electronico</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                className="form-input"
                            />
                            {emailError && <span className="error-label">{emailError}</span>}
                        </div>
              <Button
                variant="primary"
                type="button"
                className="w-100 custom-button button-primary"
                onClick={handlePasswordRecovery}
              >
                Recuperar Contraseña
              </Button>
            </form>
          </div>
        </Col>
      </Row>

      {/* Modal de Confirmación */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¡Hecho! Hemos recibido tu solicitud para restablecer la contraseña. En breve, recibirás un correo electrónico en la dirección proporcionada con un enlace para completar el proceso de recuperación. Por favor, verifica tu bandeja de entrada y también revisa la carpeta de correo no deseado si no recibes el mensaje en unos minutos.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </div>
    </div>
  );
};

export default PasswordRecovery;
