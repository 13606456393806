import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getdata, post, postAnyresponse, postReponse, postsingle } from '../../apiService';
import HeaderBonos from '../layouts/HeaderBonos';
import CategoryContent from '../layouts/CategoryContent';
import HeaderCategory from '../layouts/HeaderCategory';

const Category = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const [subcategoriesfleksi, setSubcategoriesfleksi] = useState([]);
  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [lomasnuevo, setLomasnuevo] = useState([]);
  const [destacados, setDestacados] = useState([]);
  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const [lomasnuevotwo, setLomasnuevotwo] = useState([]);
  const [destacadostwo, setDestacadostwo] = useState([]);
  const [comercios, setComercios] = useState([]);
  const [comerciostwo, setComerciostwo] = useState([]);
  const [desing, setDesing] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const clickload = (code) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    navigate(`/home/Subcategory?id_sub_categories_fleski=${code}&rand=${randomNumber}`);
    window.location.reload();
  };


  const fetchData = async () => {
    try {
   

      const bannersResponse = await postAnyresponse({ code: code },"bannersCategory");
      setBanners(bannersResponse.data);

      const comerciosReponse = await postAnyresponse({ code: code },"comerciosinHome");
      setComercios(comerciosReponse.data.shops);
      setComerciostwo(comerciosReponse.data.shopstwo);

      const subcategoriasResponse = await post({ code: code }, "subcategoriesfleksi_v3");
      setSubcategoriesfleksi(subcategoriasResponse);

      const homeresponse = await postAnyresponse({ code: code },"categoryHome");

          setDesing(homeresponse.desing);
          setCashbackespecial(homeresponse.cashbackespecial.productsone);
          setLomasnuevo(homeresponse.lomasnuevo.productsone);
          setDestacados(homeresponse.destacados.productsone);
          setCashbackespecialtwo(homeresponse.cashbackespecial.productstwo);
          setLomasnuevotwo(homeresponse.lomasnuevo.productstwo);
          setDestacadostwo(homeresponse.destacados.productstwo);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <HeaderCategory title="" pathsearch={desing.pathsearch} />
      {setIsLoading && <CategoryContent
        desing={desing}
        subcategoriesfleksi={subcategoriesfleksi}
        clickload={clickload}
        isLoading={isLoading}
        cashbackespecial={cashbackespecial}
        cashbackespecialtwo={cashbackespecialtwo}
        lomasnuevo={lomasnuevo}
        lomasnuevotwo={lomasnuevotwo}
        destacados={destacados}
        destacadostwo={destacadostwo}
        banners={banners}
        comercios={comercios}
        comerciostwo={comerciostwo}
      />}
    </div>
  );
};

export default Category;