import React, { useState, useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import NotificationModal from "../layouts/NotificationModal";
import ColorPages from "../layouts/Colorpages";
import Headerback from "../layouts/Headerback";
import { getdata } from "../../apiService";
import { Card } from "react-bootstrap";

const MisInvitados = () => {
  const [invitados, setInvitados] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const logo_inferior_card = localStorage.getItem("logo_inferior_card");
  const class_img_sup_card = localStorage.getItem("class_img_sup_card");

  const fetchData = async () => {
    try {
      const response = await getdata("get_my_tree");
      const invitadosData = response.data.flatMap(nivel => 
        nivel.response_tree.map(invitado => ({
          ...invitado,
          level_color: nivel.level_color,
        }))
      );
      setInvitados(invitadosData);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };
  useEffect(() => {
    fetchData();
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };
  const darkenColor = (color, amount) => {
    let usePound = false;

    if (color[0] === "r") {
      const sep = color.indexOf(",") > -1 ? "," : " ";
      color = color.substr(4).split(")")[0].split(sep);

      let r = (+color[0]).toString(16),
        g = (+color[1]).toString(16),
        b = (+color[2]).toString(16);

      if (r.length === 1) r = "0" + r;
      if (g.length === 1) g = "0" + g;
      if (b.length === 1) b = "0" + b;

      color = "#" + r + g + b;
    }

    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let g = ((num >> 8) & 0x00ff) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    let b = (num & 0x0000ff) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    return (
      (usePound ? "#" : "") +
      (r.toString(16).length === 1 ? "0" + r.toString(16) : r.toString(16)) +
      (g.toString(16).length === 1 ? "0" + g.toString(16) : g.toString(16)) +
      (b.toString(16).length === 1 ? "0" + b.toString(16) : b.toString(16))
    );
  };
  return (
    <div>
      <ColorPages />
      <Headerback title="Mis invitados" />
      <div className="page">
          {invitados.length === 0 ? (
            <div className=" d-flex flex-column align-items-center justify-content-center">
              <div className="icon-background">
                <BsFillPersonFill className="icon-large" />
              </div>

              <p className="inter_20_normal mt-3">No tienes invitados</p>
            </div>
          ) : (
            <div className="container-amigo  d-flex mt-4 p-0" >
              <div className="d-flex flex-row gap-2 ms-3 ">
                <h3 className="mb-4 left">Total invitados:</h3>
                <p className="fs-4">{invitados.length}</p>
              </div>
              <div className="container ">
                <div className="row">
                  <div className="col">
                {invitados.map((invitado, index) => (
                  <Card
                    key={index}
                    className="card-invitados mx-md-auto mb-5 p-0"
                    style={{
                      border: `1px solid ${invitado.level_color}`,
                      boxShadow: `0px 2px 4px ${invitado.level_color}`,
                      maxWidth: "500px",
                      
                    }}
                  >
                    <div className="header-invitados">
                      <div className="row flex-column gap-2">
                        <div className="col">
                          <p className="inter_20_normal left mb-0 ">
                            {invitado.first_name} {invitado.surname}
                          </p>
                        </div>
                        <div className="col">
                          <p className="fs-5 mb-0 left">Puntos ganados</p>
                        </div>
                      </div>
                      <div className="row flex-column gap-2">
                        <div className="col ">
                          <p className="fs-3 left mb-0 ">0</p>
                        </div>
                        <div className="col">
                          {/*<p className="fs-6 mb-0 ">Tienes 1 invitado</p>*/}
                        </div>
                      </div>
                    </div>
                    <div
                      className="footer-invitados "
                      style={{ backgroundColor: invitado.level_color }}
                    >
                      <div>
                        <img
                          src={logo_inferior_card}
                          alt="Logo"
                          className={`${class_img_sup_card} img-invitados`}
                          
                        />
                      </div>
                      <div>
                        <svg
                          width="20"
                          height="30"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.11585 0.995809L6.0813 5.99743L1.04699 10.9306"
                            stroke="url(#paint0_linear_2489_2296)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2489_2296"
                              x1="3.51318"
                              y1="10.9475"
                              x2="3.44704"
                              y2="1.01449"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0.5"
                                stop-color={darkenColor(
                                  invitado.level_color,
                                  -20
                                )}
                              />
                              <stop offset="0.5" stop-color="white" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <svg
                          width="20"
                          height="30"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.11585 1.04366L6.0813 6.04528L1.04699 10.9785"
                            stroke="url(#paint0_linear_2489_2295)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2489_2295"
                              x1="3.61579"
                              y1="1.06076"
                              x2="3.54783"
                              y2="10.9956"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0.5"
                                stop-color={darkenColor(
                                  invitado.level_color,
                                  -20
                                )}
                              />
                              <stop offset="0.5" stop-color="white" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </Card>
                ))}
                </div>
                </div>
              </div>
        </div>
          )}
        {showModal && (
          <NotificationModal message={modalMessage} onClose={closeModal} />
        )}
      </div>
    </div>
  );
};

export default MisInvitados;
