import React from 'react';

const TransactionCard = () => {
  const transactions = [
    {
      id: 1,
      title: 'Compra en Tienda ABC',
      number: 'NO 12345',
      date: '20-07-2023 09:30:00 AM',
      amount: '$150.00',
      points: 250,
      backgroundColor: "rgb(230, 230, 230)",
      imageSrc: "https://fleksirewards.com/images/comercios/logo-cinelandia.png"
    },
    {
      id: 2,
      title: 'Compra en Tienda XYZ',
      number: 'NO 54321',
      date: '21-07-2023 02:45:00 PM',
      amount: '$75.50',
      points: 100,
      backgroundColor: "#ffffff)",
      imageSrc: "https://fleksirewards.com/images/comercios/logo-popsy.jpg"
    },
    // Agrega más registros según sea necesario
  ];

  return (
    <>
      {transactions.map(transaction => (
        <div className='row  mt-4 MisPointsResponsive' key={transaction.id}>
          <div className='col-5 center'>
            <div className="card-rounded center" style={{ backgroundColor: transaction.backgroundColor }}>
              <div className="image-container">
                <img src={transaction.imageSrc} className="centered-image p-0" alt="Imagen" />
              </div>
            </div>
          </div>
      
          <div className='col left ml-2'>
            <p className='inter_16_500'>{transaction.title}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>{transaction.number}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>Fecha {transaction.date}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>Valor compra: {transaction.amount}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>Puntos por confirmar: {transaction.points}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default TransactionCard;
