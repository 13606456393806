import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import HeaderTitle from "../layouts/HeaderTitle";
import TabLayout from "../layouts/TabLayout";
import NotificationModal from "../layouts/NotificationModal";
import { postarray } from "../../apiService";
import numeral from "numeral";
import ImageComponent from "../layouts/ImageComponent";
import ItemProduct from "../layouts/ItemProduct";
import useCart from "../utils/useCart";
import TabLayoutLocation from "../layouts/TabLayoutLocation";
import ColorPages from "../layouts/Colorpages";

const Bono = () => {
  const [bono, setBono] = useState({});
  const [related_products, setRelated_products] = useState([]);
  const [related_productstwo, setRelated_productstwo] = useState([]);
  const location = useLocation();
  const { code } = useParams();
  const fetchData = async () => {
    setTotalcart(total_cart());
    try {
  
      const bonoReponse = await postarray(
        { id_fleksi_products: code },
        "bono_v3"
      );
      setBono(bonoReponse);
      setRelated_products(bonoReponse.related_products.productsone);
      setRelated_productstwo(bonoReponse.related_products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [valor, setValor] = useState(1);
  const [totalcart, setTotalcart] = useState(0);
  const [activeTabs, setActiveTabs] = useState([0, 4]);
  const [showNotification, setShowNotification] = useState(false);
  const { addToCart, total_cart } = useCart();

  const gocart = () => {
    addToCart({
      id_items: bono.id_items,
      actual_code_product: bono.actual_code_product,
      title: bono.title,
      quantity: valor,
      price: bono.valor_de_venta,
      total_price: bono.valor_de_venta * valor,
      image: bono.image,
    });
    setTotalcart(total_cart());

    navigate("/home/Cart");
  };

  const addtocart = () => {
    addToCart({
      id_items: bono.id_items,
      actual_code_product: bono.actual_code_product,
      title: bono.title,
      quantity: valor,
      price: bono.valor_de_venta,
      total_price: bono.valor_de_venta * valor,
      image: bono.image,
    });
    setTotalcart(total_cart());
  };
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  const incrementarValor = () => {
    setValor(valor + 1);
  };

  const decrementarValor = () => {
    if (valor > 1) {
      setValor(valor - 1);
    }
  };
  const text_color_percentage = localStorage.getItem("text_color_percentage");

  return (
    <div>
      <ColorPages />
      <HeaderTitle title={bono.ecommerce} totalcart={totalcart} />
      <div className="page mx-md-4 px-4">
        <div className="bono-container">
          <div className="image-container mt-2">
            <ImageComponent
              src={bono.image}
              alt={bono.title}
              defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
              cssclass={"img-bono"}
            />
          </div>

          <div className="info-container mt-4">
            <p className="inter_18_600 left gray-dark title-product ">{bono.title}</p>
            <p className="inter_12 gray-dark left mt-2 m-0 subtitle-product">{bono.subtitle}</p>
            <p className="inter_18_600 left gray-dark mt-3">
              {numeral(bono.valor_de_venta).format("$0,0")}
            </p>
            <div
              className="inter_14_700 left color-green ml-1"
              style={{ color: text_color_percentage }}
            >
              {bono.shop_text_points}
            </div>

            {showNotification && (
              <NotificationModal
                message="Agregado al carrito."
                onClose={handleCloseNotification}
              />
            )}

            <p className="mt-3 stock-responsive ">
              Cantidad Disponible : {bono.quantityAvaillable}
            </p>

            <div className="quantity-container">
              <div className="bono-redondo">
                <div className="botones center">
                  <button className="boton-menos" onClick={decrementarValor}>
                    {" "}
                    -{" "}
                  </button>
                  <div className="inter_14_400_normal gray-dark">{valor}</div>
                  <button className="boton-mas" onClick={incrementarValor}>
                    {" "}
                    +{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="center mt-4 mb-3 row px-4 button-container">
              <div className="col m-0 p-0 center">
                <button
                  type="button"
                  onClick={gocart}
                  className="button-primary w-100 mt-3 mr-3"
                >
                  Ir a pagar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-layout">
          <div className="tab-navigation p-0 m-0">
            <ul>
              {bono.subtitle && (
                <TabLayout
                  key={0}
                  title="Descripción"
                  description={bono.subtitle}
                  active={activeTabs.includes(0)}
                  handleTabClick={() => handleTabClick(0)}
                />
              )}
              {bono.description && (
                <TabLayout
                  key={1}
                  title="Comercio"
                  description={bono.description}
                  active={activeTabs.includes(1)}
                  handleTabClick={() => handleTabClick(1)}
                />
              )}
              {bono?.locations && Object.keys(bono.locations).length > 0 && (
                <TabLayoutLocation
                  title="Ubicaciones"
                  active={activeTabs.includes(4)}
                  handleTabClick={() => handleTabClick(4)}
                  locations={bono.locations}
                />
              )}
              {bono.benefits && (
                <TabLayout
                  key={3}
                  title="Beneficios"
                  description={bono.benefits}
                  active={activeTabs.includes(2)}
                  handleTabClick={() => handleTabClick(2)}
                />
              )}
              {bono.terms_and_conditions && (
                <TabLayout
                  key={4}
                  title="Términos y condiciones"
                  description={bono.terms_and_conditions}
                  active={activeTabs.includes(3)}
                  handleTabClick={() => handleTabClick(3)}
                />
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bono;
