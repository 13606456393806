import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ItemProductBono from "../layouts/ItemProductBono";
import HeaderEcommerce from "../layouts/HeaderEcommerce";
import { getdata, post, postarray, postsingle } from "../../apiService";
import Headerback from "../layouts/Headerback";
import ItemProductTienda from "../layouts/ItemProductTienda";
import ColorPages from "../layouts/Colorpages";
import HeaderTiendaFisica from "../layouts/HeaderTiendaFisica";

const TiendaComercio = () => {
  const [comercio, setComercio] = useState({ horarios: [] });

  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const fetchData = async () => {
    try {
      const TiendaComercioReponse = await postarray(
        { id_items: code },
        "tiendav3"
      );
      setComercio(TiendaComercioReponse);
      setCashbackespecial(TiendaComercioReponse.cashbackespecial.productsone);
      setCashbackespecialtwo(
        TiendaComercioReponse.cashbackespecial.productstwo
      );
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ColorPages />
      <HeaderTiendaFisica title="" />
      <div className="page px-4">
        <div className="content-container mx-md-auto mt-5 ">
          <div className=" d-block d-md-none text-start inter_20_700 gray-dark w-100 mt-3">
            {comercio.ecommerce}
          </div>
          <div className=" d-none d-md-block text-center inter_30_700 gray-dark w-100 ">
            {comercio.ecommerce}
          </div>
          <div className="image-container-comercio  justify-content-md-center mt-3 ">
            <img
              className="centered-image-comercio"
              src={comercio.shop_image}
              alt={comercio.ecommerce}
            />
          </div>
          <div className="w-100">
            <div className="inter_12 gray w-100 text-start text-md-center mt-3">
              {comercio.shop_subtitle}
            </div>
          </div>
          {comercio.horarios && comercio.horarios.length > 0 && (
            <div>
              <h3 className="inter_16_700 gray w-100 text-start text-md-center mt-3">Horario:</h3>
              {comercio.horarios.map((horario, index) => (
                <p className="text-start text-md-center" key={index}>
                  <strong>{horario.title}:</strong> {horario.time_text}
                </p>
              ))}
            </div>
          )}
          <div>
            <h3 className="inter_16_700 gray w-100 text-start text-md-center mt-3">Direccion:</h3>
            <p className="text-start text-md-center">
              {comercio.locations && comercio.locations.length > 0
                ? comercio.locations[0].address
                : "Dirección no disponible"}
            </p>
          </div>
        </div>
        <div style={{ marginTop: "40px" }}>
          <ItemProductTienda
            title="Cashbacks especiales para ti"
            data={cashbackespecial}
          />
        </div>
        <div style={{ marginTop: "40px" }}>
          {cashbackespecialtwo.length > 0 && (
            <ItemProductTienda title="" data={cashbackespecialtwo} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TiendaComercio;
