import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "../layouts/HeaderTitle";
import ItemHistorialPuntos from "../layouts/ItemHistorialPuntos";
import ColorPages from "../layouts/Colorpages";
import { getdata, post } from "../../apiService";
import Headerback from "../layouts/Headerback";
import { BsShop } from "react-icons/bs";

const HistorialPuntos = () => {
  const [transactions, setTransactions] = useState([]);

  const fetchData = async () => {
    try {
      const transactions = await getdata("get_my_transactions_buyer");
      setTransactions(transactions.data);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
    <ColorPages />
    <Headerback title="Mis puntos" />
    <div className="page px-4 py-4">
      <div className='row'>
        {transactions.length === 0 ? (
          <div className="text-center">
            <BsShop size={60} />
            <p style={{ marginTop: "25px" }}>
              No has realizado ninguna compra aún. ¿Por qué no echas un vistazo
              a nuestros productos?
            </p>
          </div>
        ) : (
          <div className="content-container mx-auto mt-0">
            <div className='col-12 ms-lg-5 '>
              <div className='left'>
                <h1>Mis Puntos</h1>
              </div>
            </div>
            <ItemHistorialPuntos transactions={transactions} />
          </div>
        )}
      </div>
    </div>
  </div>
  );
};

export default HistorialPuntos;
