import React, {useEffect, useState } from 'react';
import HeaderEcommerce from '../layouts/HeaderEcommerce';
import ItemComerciosBonos from '../layouts/ItemComerciosBonos';
import BannerSlider from '../layouts/BannerSlider';
import ItemProductBono from '../layouts/ItemProductBono';
import { getdata , getsingle,post} from '../../apiService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';
import HeaderBonos from '../layouts/HeaderBonos';

import ItemProductRefacil from '../layouts/ItemProductRefacil';
import CategorySectionRefacil from '../layouts/CategorySectionRefacil';

const Refacil = () => {
    let navigate = useNavigate();
    const [banners, setBanners] = useState([]);
    const [subcategoriesfleksi, Setsubcategoriesfleksi] = useState([]);
    const [cashbackespecial, setCashbackespecial] = useState([]);
    const [lomasnuevo, setLomasnuevo] = useState([]);
    const [destacados, setDestacados] = useState([]);
    const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
    const [lomasnuevotwo, setLomasnuevotwo] = useState([]);
    const [destacadostwo, setDestacadostwo] = useState([]);
    const [comercios, setComercios] = useState([]);
    const [comerciostwo, setComerciostwo] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var record = queryParams.get('record');
    var category_code= queryParams.get('category_code');
    var category_name= queryParams.get('category_name');
    const clickload = (code) => {  
    
        if(code=="topup")
        {
          navigate(`/home/Refacil?record=recargashome&category_code=topup&category_name=Recargas`);
          window.location.reload();
        }
        if(code=="pins")
        {
          navigate(`/home/Refacil?record=pineshome&category_code=pins&category_name=Pines`);
          window.location.reload();
        }

        if(code=="package")
        {
          navigate(`/home/Refacil?record=paqueteshome&category_code=package&category_name=Paquetes`);
          window.location.reload();
        }

        if(code=="gambling")
        {
          navigate(`/home/Refacil?record=apuestashome&category_code=gambling&category_name=Apuestas`);
          window.location.reload();
        }
      };


      const fetchData = async () => {
        try {  
          const refacilcategories = await getdata("refacilcategories");
          Setsubcategoriesfleksi(refacilcategories.data);

          const homeresponse = await getdata(record)
          setCashbackespecial(homeresponse.cashbackespecial.productsone);
          setLomasnuevo(homeresponse.lomasnuevo.productsone);
          setDestacados(homeresponse.destacados.productsone);   
          
          setCashbackespecialtwo(homeresponse.cashbackespecial.productstwo);
          setLomasnuevotwo(homeresponse.lomasnuevo.productstwo);
          setDestacadostwo(homeresponse.destacados.productstwo);  

      } catch (error) {
          console.error(error);
        }
        return () => {}
      };

    useEffect(() => {
        fetchData();
      }, []);    

    return (

        <div>
             <ColorPages/>
            <HeaderBonos title="" />
            <div className='page '>
            <div className='section mt-3 mb-4 w-100' style={{
                    position: 'fixed',
                    top: '60px',
                    zIndex: 1
                }}>
                    <div className='bannercomercio'>
                        <div id='title' >{category_name}</div>
                        <div className='inter_12 color-white left' >Recargas, pines, apuestas y paquetes a todo operador</div>
                    </div>
                </div>
                    
                <div style={{ marginTop: "200px" }}  >
                <CategorySectionRefacil  data={subcategoriesfleksi} code={category_code} clickload={clickload} />
                </div>

                <div className='mt-2'  >
                <ItemProductRefacil title="Lo mas nuevo" data={lomasnuevo} />
                {lomasnuevotwo.length > 0 && <ItemProductRefacil title="" data={lomasnuevotwo} />}
                </div>

                <div className='mt-2'  >
                <ItemProductRefacil title="Lo mas nuevo" data={destacados} />
                {destacadostwo.length > 0 && <ItemProductRefacil title="" data={destacadostwo} />}
                </div>

                <div className='mt-2'  >
                <ItemProductRefacil title="Lo mas nuevo" data={cashbackespecial} />
                {cashbackespecialtwo.length > 0 && <ItemProductRefacil title="" data={cashbackespecialtwo} />}
                </div>
                
                
             
            </div>
        </div>
    );
};

export default Refacil;
