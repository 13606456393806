import React from 'react';

const CardComponentVerticalsmall = ({  usuario, apellido ,cedula, puntos, puntosTexto }) => {

  const superior_card = localStorage.getItem('logo_superior_card');
const logo_inferior_card = localStorage.getItem('logo_inferior_card');
const class_img_end_card = localStorage.getItem('class_img_end_card');
const themecode =localStorage.getItem("themecode");

  return (
    <div className="card_main mt-0 p-2 pb-3">
      <div className="row p-0 m-0">
        <div className="col-12 pt-3 center">
          <img
           src={superior_card}
            alt="Logo"
            className={class_img_end_card}
            style={{ width: '112px', height: 'auto' }}
          />
        </div>

      </div>

      <div className="row p-0 m-0">
        <div className="col-12 pt-2 center">
          <div className="inter_20_normal color-white w-100">{usuario} {apellido}</div>
        </div>

        <div className="col-12  pt-1 center">
          <div className="inter_20_normal color-white  w-100"></div>
        </div>

        <div className="col-12  pt-1 center">
          <div className="inter_20_normal color-white w-100">CC{cedula}</div>
        </div>

      </div>


      <div className="row p-0 m-0">
        <div className="col-12 pt-4 right mr-4">
        {themecode!="Fleksi" &&
          <img
             src={logo_inferior_card}
            alt="Fleksi Logo"
            style={{ width: '76px', height: 'auto' }}
          />
  }
        </div>
      </div>
    </div>
  );
};

export default CardComponentVerticalsmall;
