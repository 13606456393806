import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "../layouts/HeaderTitle";
import ColorPages from "../layouts/Colorpages";
import Headerback from "../layouts/Headerback";
import { getsingle, post, postReponse } from "../../apiService";
import NotificationModal from "../layouts/NotificationModal";

const Perfil = () => {
  const navigate = useNavigate();

  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cumpleanos, setCumpleanos] = useState("");

  const [nombresError, setNombresError] = useState("");
  const [apellidosError, setApellidosError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [tipoDocumentoError, setTipoDocumentoError] = useState("");
  const [numeroDocumentoError, setNumeroDocumentoError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [cumpleanosError, setCumpleanosError] = useState("");

  const [emailFocused, setEmailFocused] = useState(false);
  const [nombresFocused, setNombresFocused] = useState(false);
  const [apellidosFocused, setApellidosFocused] = useState(false);
  const [numeroDocumentoFocused, setNumeroDocumentoFocused] = useState(false);
  const [telefonoFocused, setTelefonoFocused] = useState(false);
  const [cumpleanosFocused, setCumpleanosFocused] = useState(false);
  const [dataNotification, setdataNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  
  const fetchData = async () => {
    try {
      const info = await getsingle("me_personal_info");
      setNombres(info.first_name);
      setNombresFocused(true);

      setApellidos(info.surname);
      setApellidosFocused(true);

      setEmail(info.email);
      setEmailFocused(true);

      setTipoDocumento(info.id_document_type);
      setTelefonoFocused(true);

      setNumeroDocumento(info.document_number);
      setNumeroDocumentoFocused(true);

      setTelefono(info.cell_phone);
      setTelefonoFocused(true);

      setCumpleanos(info.birthdate);
      setCumpleanosFocused(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };
  const handleInputFocus = (event) => {
    const { name } = event.target;

    if (name === "email") {
      setEmailFocused(true);
    } else if (name === "nombres") {
      setNombresFocused(true);
    } else if (name === "apellidos") {
      setApellidosFocused(true);
    } else if (name === "numeroDocumento") {
      setNumeroDocumentoFocused(true);
    } else if (name === "telefono") {
      setTelefonoFocused(true);
    } else if (name === "cumpleanos") {
      setCumpleanosFocused(true);
    }
    // Agregar más condiciones para otros campos si es necesario
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "nombres") {
      setNombres(value);
    } else if (name === "apellidos") {
      setApellidos(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "tipoDocumento") {
      setTipoDocumento(value);
    } else if (name === "numeroDocumento") {
      setNumeroDocumento(value);
    } else if (name === "telefono") {
      setTelefono(value);
    } else if (name === "cumpleanos") {
      setCumpleanos(value);
    }
    // Agregar más condiciones para otros campos si es necesario
  };
  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    if (name === "nombres") {
      setNombresError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setNombresFocused(false);
      if (value !== "") {
        setNombresFocused(true);
      }
    } else if (name === "apellidos") {
      setApellidosError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setApellidosFocused(false);
      if (value !== "") {
        setApellidosFocused(true);
      }
    } else if (name === "email") {
      setEmailError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setEmailFocused(false);
      if (value !== "") {
        setEmailFocused(true);
      }
    } else if (name === "tipoDocumento") {
      setTipoDocumentoError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
    } else if (name === "numeroDocumento") {
      setNumeroDocumentoError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
      setNumeroDocumentoFocused(false);
      if (value !== "") {
        setNumeroDocumentoFocused(true);
      }
    } else if (name === "telefono") {
      setTelefonoError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setTelefonoFocused(false);
      if (value !== "") {
        setTelefonoFocused(true);
      }
    } else if (name === "cumpleanos") {
      setCumpleanosError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
      setCumpleanosFocused(false);
      if (value !== "") {
        setCumpleanosFocused(true);
      }
    }
  };

  const goIntereses = (event) => {
    navigate("/home/Misintereses");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;

    if (nombres.trim() === "") {
      setNombresError("Este campo es obligatorio");
      hasError = true;
    } else {
      setNombresError("");
    }

    if (apellidos.trim() === "") {
      setApellidosError("Este campo es obligatorio");
      hasError = true;
    } else {
      setApellidosError("");
    }

    if (email.trim() === "") {
      setEmailError("Este campo es obligatorio");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (tipoDocumento.toString().trim() === "") {
      setTipoDocumentoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setTipoDocumentoError("");
    }

    if (numeroDocumento.trim() === "") {
      setNumeroDocumentoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setNumeroDocumentoError("");
    }

    if (telefono.trim() === "") {
      setTelefonoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setTelefonoError("");
    }

    if (cumpleanos.trim() === "") {
      setCumpleanosError("Este campo es obligatorio");
      hasError = true;
    } else {
      setCumpleanosError("");
    }

    // Validación del formato de email
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Ingrese un email válido");
      hasError = true;
    }

    // Validación del número de teléfono
    if (telefono.length !== 10 || isNaN(telefono)) {
      setTelefonoError("Ingrese un número de teléfono válido (10 dígitos)");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const fetchData = async () => {
      try {
        await post(
          {
            first_name: nombres,
            surname: apellidos,
            email: email,
            id_document_type: tipoDocumento,
            document_number: numeroDocumento,
            cell_phone: telefono,
            birthdate: cumpleanos,
          },
          "prospects_update"
        );
        setShowNotification(true);

        setdataNotification("Datos actualizados correctamente");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    navigate("/home/Menu");
  };

  return (
    <div>
      {showNotification && (
        <NotificationModal
          message={dataNotification}
          onClose={handleCloseNotification}
        />
      )}
      <ColorPages />
      <Headerback title="Informacion Personal" />
      <div className="page">
        <div className="card-perfil mt-4">
          <div className="perfil-container">
        <div className="card_main perfil-responsive  mt-0 p-0 mx-4 ">
          <div className="container row p-0 m-0">
            <div className="col-4 col-md-6 pl-4 pt-2 left">
             <svg
              version="1.1"
              viewBox="0 0 1600 1600"
              width="91px"
              height="auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="var(--primary-color-text)"
              
            >
              <path
                transform="translate(769,250)"
                d="m0 0h59l21 2 28 5 26 7 20 7 21 9 16 8 20 11 24 16 16 12 14 12 7 7 8 7 7 8 11 12 11 14 13 18 11 18 12 23 9 19 9 23 10 37 5 26 2 19 1 19v15l-2 34-3 19-5 23-9 32-8 20-9 20-10 20-12 19-10 15-18 24h-2l-2 4-7 7-6 7-8 7-9 9-14 11-14 8-5 3h-2v2l9 3 18 10 22 12 23 14 22 15 13 10 14 11 10 9 11 9 7 7 8 7 12 12 7 8 11 12 8 10 10 13 13 18 15 23 13 24 9 17 12 27 11 29 8 27 7 28 5 28 6 43 3 23v7l-3 3-6 2-85 2-5-3-3-4-2-11-4-36-5-28-9-36-8-24-9-22-12-25-11-20-18-27-10-13-9-11-18-20-10-10-8-7-14-12-16-13-17-12-9-6-3 1-5 11-9 16-10 15-7 9-11 13-16 16-11 9-15 11-17 10-19 9-27 9-25 6-27 3h-27l-26-3-20-5-18-6-24-11-14-8-14-10-13-10v-2l-4-2-20-20-11-14-12-17-9-15-7-10-4-4-6 3-12 9-14 11-13 11-11 9-20 20-7 8-12 14-9 11-11 15-9 14-8 14-9 16-11 23-11 29-7 22-7 31-5 28-4 33-1 10-4 6-1 1h-80l-13-1-3-3v-14l5-47 6-37 7-28 10-33 9-24 10-23 8-16 13-24 13-21 10-15 7-10 10-13 12-14 6-8h2l2-4 15-16 11-11 8-7 10-9 11-9 8-7 13-10 17-12 22-14 21-12 27-14 8-4-2-4-21-16-13-12-11-11-2-1v-2h-2l-7-8-12-14-12-16-18-27-12-22-8-16-10-25-9-27-6-27-4-28-2-31v-18l2-24 5-30 8-31 8-24 7-16 12-25 9-16 7-11 8-12 10-14 13-15 16-17 14-14 10-8 12-10 16-11 18-11 22-12 15-7 20-8 21-7 30-7 25-4zm7 100-17 2-24 5-30 10-23 11-18 11-10 7-13 10-12 11-8 7-7 8-9 10-10 14-12 20-7 12-11 25-7 21-6 31-2 19v33l3 25 6 27 6 17 11 25 10 18 10 15 11 14 9 10 6 7h2l1 3 8 7 13 11 14 10 22 13 16 8 19 8 20 6 25 5 16 2h41l23-3 26-6 18-6 21-9 25-14 19-13 14-11 13-12 15-16 10-14 10-16 10-17 11-25 6-19 5-23 3-25v-35l-3-28-7-30-7-20-13-27-10-17-10-14-13-16-18-18-11-9-15-11-13-8-14-8-16-8-20-8-22-6-20-4-19-2zm4 600-27 2-32 5-22 5-14 5-1 6 9 16 11 15 5 6 8 7 11 9 13 8 17 8 19 5 8 1h30l16-3 19-7 15-8 13-10 12-11 7-7 13-18 4-9v-4h-2v-3h-2l-1-3-8-3-36-7-29-3-31-2z"
              />
              <path transform="translate(590,1017)" d="m0 0" />
              <path transform="translate(585,1016)" d="m0 0" />
              <path transform="translate(912,973)" d="m0 0" />
            </svg>
            </div>
            <div className="col p-0 m-0 d-flex flex-column justify-content-end justify-content-md-center  align-items-center ">
              <div
                className="inter_20_500 color-white w-100 left mb-4 ml-3"
                style={{ height: "20px" }}
              >
                TU PERFIL
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col container  p-5 d-flex flex-column justify-content-end letf">
          {/* Resto del contenido del perfil */}
          <form className="perfil-form">
            <div
              className={`w-100 form-input-container ${nombresFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Nombres</label>
              <input
                type="text"
                name="nombres"
                value={nombres}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="nombres"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {nombresError && (
                <span className="error-label">{nombresError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${apellidosFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Apellidos</label>
              <input
                type="text"
                name="apellidos"
                value={apellidos}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="apellidos"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {apellidosError && (
                <span className="error-label">{apellidosError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${emailFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="email"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {emailError && <span className="error-label">{emailError}</span>}
            </div>

            <div
              className={`w-100 form-input-container ${tipoDocumentoError ? "input-filled" : ""
                }`}
            >
              <label className="form-label"></label>
              <select
                name="tipoDocumento"
                value={tipoDocumento}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="tipoDocumento"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              >
                <option value="cc">Cédula de Ciudadanía</option>
                <option value="ce">Cédula de Extranjería</option>
              </select>
              {tipoDocumentoError && (
                <span className="error-label">{tipoDocumentoError}</span>
              )}
            </div>

            <div
              className={`w-100 form-input-container ${numeroDocumentoFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Número de Documento</label>
              <input
                type="text"
                name="numeroDocumento"
                value={numeroDocumento}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="numeroDocumento"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {numeroDocumentoError && (
                <span className="error-label">{numeroDocumentoError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${telefonoFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Teléfono</label>
              <input
                type="tel"
                name="telefono"
                value={telefono}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="telefono"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {telefonoError && (
                <span className="error-label">{telefonoError}</span>
              )}
            </div>
            <div className={`w-100 form-input-container input-filled`}>
              <label className="form-label">Cumpleaños</label>
              <input
                type="date"
                name="cumpleanos"
                value={cumpleanos}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="cumpleanos"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {cumpleanosError && (
                <span className="error-label">{cumpleanosError}</span>
              )}
            </div>
            

            <button
              type="button"
              onClick={handleSubmit}
              className="button-primary"
            >
              Guardar
            </button>

            {/*  <div className='center mt-4'>
              <div className='card_gray_hand p-1' onClick={goIntereses}>

                <img
                  className='mx-3 my-1'
                  src='../images/uphand.png'
                  alt="Fleksi Logo"
                  style={{ width: '76px', height: 'auto' }}
                />

              </div>
            </div>
              */}
          </form>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
