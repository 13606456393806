import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "../utils/ScrollContainer";

const ItemComercios = ({ title, data }) => {
  const [isDragging, setIsDragging] = useState(false);
  const randomNumber = Math.floor(Math.random() * 1000);
  let navigate = useNavigate();

  const handleImageClick = (code, id_type_of_shops, link) => {
    if (isDragging) {
      return;
    }

    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate(`/home/EcommerceComercio?code=${link}&rand=${randomNumber}`);
    }

    if (id_type_of_shops === 3) {
      navigate(`/home/BonosComercio?code=${code}&rand=${randomNumber}`);
    }

    if (id_type_of_shops === 2) {
      navigate(`/home/TiendaComercio?code=${code}&rand=${randomNumber}`);
    }
  };

  const getWidth = (item) => {
    const size = item.tam_div || 3; // Default to 3 if tam_div is not set
    

    let width;

    if (size == 1) {
        if (window.innerWidth > 420) {
          
            return 400;
            
        } else {
            width = window.innerWidth - 80;
        }
    } else {
        switch (size) {
            case 2:
                width = window.innerWidth / 2;
                break;
            case 3:
                width = window.innerWidth / 3;
                break;
            case 4:
                width = window.innerWidth / 4;
                break;
            default:
                width = window.innerWidth / 3;
                break;
        }

        if (window.innerWidth > 420) {
            // Set minimum and maximum widths to handle different screen sizes
            const minWidth = 150;  // Minimum width for the elements
            const maxWidth = 200;  // Maximum width for the elements
           

            if (width < minWidth) {
                width = minWidth;
            } else if (width > maxWidth) {
                width = maxWidth;
            }

           
        }
    }

    return width;
};

  const text_color_percentage = localStorage.getItem("text_color_percentage");
  const image_percentage = localStorage.getItem("image_percentage");

  return (
    
    <div className="mt-0 mb-0  pt-0 pb-0 ">
      <div className="inter_18_gray  left ml-3">{title}</div>
      <ScrollContainer onDragChange={setIsDragging} >
        <div style={{height:'170px', display: 'inline-flex'}}>
        {data.map((item) => (
          <div
            style={{ backgroundColor: item.background_color, }}
            className={`card-rounded-comercios comercios-products_min percentagecomercios_min mx-2 mt-3 `}
            key={item.id_items}
            onClick={() =>
              handleImageClick(item.id_items, item.id_type_of_shops, item.link)
            }
          >
            <div className="overlay-text " alt="splash" />

            <div
              className=" center"
              style={{
                backgroundColor: item.background_color,
                width: getWidth(item),
                height: "98px",
              }}
            >
              <img
                src={item.image}
                onDragStart={(e) => e.preventDefault()}
                style={{
                  width: getWidth(item) - 30,
                  objectFit: "contain",
                  height: "98px",
                }}
                alt="Imagen"
              />
            </div>
            <div className="row  center" style={{ marginTop: "14px" }}>
              <div
                className="inter_12_700 pl-1"
                style={{ color: text_color_percentage }}
              >
                {item.shop_text_points}
              </div>
            </div>
          </div>
        ))}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default ItemComercios;
