import React from 'react';
import SubCategorySection from './SubCategorySection';
import ItemProduct from './ItemProduct';
import ItemComercios from './ItemComercios';
import BannerSlider from './BannerSlider';
import ColorPages from './Colorpages';
import LoadingSpinner from './LoadingSpinner';
import HeaderBonos from './HeaderBonos';

const CategoryContent = ({desing, banners, subcategoriesfleksi, clickload, isLoading, cashbackespecial, lomasnuevo, destacados, cashbackespecialtwo, lomasnuevotwo, destacadostwo, comercios, comerciostwo }) => {
  return (
<div>
<ColorPages/>
{isLoading && <LoadingSpinner   />}
<div className='page '>
<div className='section mt-3 mb-4 w-100' style={{
       position: 'fixed',
       top: '60px',
       zIndex: 2
   }}>
       <div className='bannercomercio'  style={{  backgroundColor:desing.backcolor_card}}>
           <div id='title'  style={{  color:desing.text_color_card}} >{desing.title_card = null ? "Bonos" : desing.title_card}</div>
           <div className='inter_12 color-white left'  style={{  color:desing.text_color_card}} > {desing.sub_title_card}</div>
       </div>
   </div>
       
   <div style={{ marginTop: "150px" }}  >
   <SubCategorySection  data={subcategoriesfleksi} selectedCategoryId={0} clickload={clickload} />
   </div>
   <div  style={{ marginTop:"52px" }}  >                    
   <ItemProduct title="Productos destacados" data={destacados} />
   {destacadostwo.length > 0 && <ItemProduct title="" data={destacadostwo} />}
   </div>
   <ItemComercios  title={"Comercios Aliados"}  data={comercios}/>
   {comerciostwo.length > 0 && <ItemComercios title="" data={comerciostwo} />}
   <div  style={{ marginTop:"40px" }} >
   <BannerSlider  banners={banners} />
   </div>
   <div  style={{ marginTop:"43px" }} >
   <ItemProduct title="Cashbacks especiales para ti" data={cashbackespecial} />
   {cashbackespecialtwo.length > 0 && <ItemProduct title="" data={cashbackespecialtwo} />}
   </div>
   <div className='mt-2'  >
   <ItemProduct title="Lo mas nuevo" data={lomasnuevo} />
   {lomasnuevotwo.length > 0 && <ItemProduct title="" data={lomasnuevotwo} />}
   </div>
</div>
</div>
  );
};

export default CategoryContent;

