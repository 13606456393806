import React, { useEffect, useState } from "react";
import { getsingle } from "../../apiService";
import { useNavigate } from "react-router-dom";

const CardComponentBilletera = ({showMessage}) => {
  const [pointsData, setPointsData] = useState([]);
  const [messagePoints, setMessagePoints] = useState("");

  const navigate = useNavigate();
  const handleGoBonosDesEsp = (item) => {
    navigate("/home/BonosDestEsp", { state: { selectedCard: item, title: item.category_points, code: item.id_category_points} });
  };

  const fetchData = async () => {
    try {
      const response = await getsingle("getPointsbyCategory");
      setPointsData(response.data);
      setMessagePoints(response.message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (pointsData.length === 0) {
    return null; 
  }

  return (
    <div className="mt-3">
      {showMessage && (
      <>
      <div
        className="inter_18_gray left ms-md-0"
      >
        Redimir puntos en bonos
      </div>
      <div className="inter_12_gray left ">
        <p>{messagePoints}</p>
      </div>
      </>
      )}
      {pointsData.map((item, index) => (
        <div
          key={index}
          onClick={() => handleGoBonosDesEsp(item)}
          className="cardPago mt-4 py-2 align-items-center"
          style={{
            backgroundColor: item.card_color,
            borderColor: item.card_color,
            color: item.color_text,
          }}
        >
          <div className="col-2">
            <img
              width="40"
              height="40"
              src={item.icon}
              alt="fast-food--v2"
            />
          </div>
          <div className="col-6 inter_14 left mt-2 ml-2">
            {item.category_points.includes(" y ") ? (
              <div>
                {item.category_points.split(" y ")[0]} y <br />{" "}
                {item.category_points.split(" y ")[1]}
              </div>
            ) : (
              <div>{item.category_points}</div>
            )}
          </div>
          <div className="col-3 inter_20_700 left mt-2 d-flex flex-column">
            {item.total_points}
            <span className="inter_12 align-items-left">Mis puntos</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponentBilletera;
