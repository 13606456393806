import React, { useEffect, useState } from "react";
import ItemProductTienda from "../layouts/ItemProductTienda";
import { useNavigate, useLocation } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import { postarray, postsingle } from "../../apiService";
import ImageComponent from "../layouts/ImageComponent";
import TabLayoutLocation from "../layouts/TabLayoutLocation";
import TabLayout from "../layouts/TabLayout";
import numeral from "numeral";
import ColorPages from "../layouts/Colorpages";

const TiendaProductoFisico = () => {
  const [bono, setBono] = useState({});
  const [related_products, setRelated_products] = useState([]);
  const [related_productstwo, setRelated_productstwo] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const [activeTabs, setActiveTabs] = useState([0, 4]);
  const navigate = useNavigate();
  const goubicaciones = (image, id_fleksi_products) => {
    localStorage.setItem("TiendaProductoFisicoImage", image);
    localStorage.setItem(
      "TiendaProductoFisicoid_fleksi_products",
      id_fleksi_products
    );

    navigate(`/home/EndTienda`);
  };

  const fetchData = async () => {
    try {
      const TiendaFisicaResponse = await postarray(
        { id_fleksi_products: code },
        "productotienda"
      );
      setBono(TiendaFisicaResponse);
      setRelated_products(TiendaFisicaResponse.related_products.productsone);
      setRelated_productstwo(TiendaFisicaResponse.related_products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  return (
    <div>
      <ColorPages />
      <Headerback title={bono.ecommerce} />
      <div className="page mx-md-4 px-4">
        <div className="bono-container">
          <div className=" image-container mt-2">
            <ImageComponent
              src={bono.image}
              alt={bono.title}
              defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
              cssclass={"img-bono"}
            />
          </div>
          <div className="info-container">
            <p className="inter_20_normal left gray-dark title-product">{bono.title}</p>
            <p className=" inter_14_400_normal gray left subtitle-product" >{bono.subtitle}</p>
            <p className="inter_14_700 left  color-green ">
              Hasta {bono._return_to_presentation_users}% Cashback
            </p>
            <p className="inter_14_700 left  subtitle-product" >{bono.message}</p>
          </div>
        </div>
        <div className="tab-layout">
          <div className="tab-navigation p-0 m-0 ">
            <ul>
              {bono.description && (
                <TabLayout
                  key={0}
                  title="Descripción"
                  description={bono.description}
                  active={activeTabs.includes(0)}
                  handleTabClick={() => handleTabClick(0)}
                />
              )}

              {bono?.locations && Object.keys(bono.locations).length > 0 && (
                <TabLayoutLocation
                  title="Ubicaciones"
                  active={activeTabs.includes(4)}
                  handleTabClick={() => handleTabClick(4)}
                  locations={bono.locations}
                  code={code}
                />
              )}

              {bono.benefitslist && bono.benefitslist.length > 0 && (
                <div>
                  <div className="inter_14_500 color-primary left text-responsive">
                    Beneficios:
                  </div>
                  <ul>
                    {bono.benefitslist.map((benefit, index) => (
                      <li className="p-0 my-3 color-green left" key={index}>
                        {benefit.benefits}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {bono.terms_and_conditions && (
                <TabLayout
                  key={4}
                  title="Términos y condiciones"
                  description={bono.terms_and_conditions}
                  active={activeTabs.includes(3)}
                  handleTabClick={() => handleTabClick(3)}
                />
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="px-3">
        <ItemProductTienda
          title={"Productos que te podrían gustar"}
          data={related_products}
        />
        <ItemProductTienda title={""} data={related_productstwo} />
      </div>
    </div>
  );
};

export default TiendaProductoFisico;
