import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
const CardComponent = ({ usuario, puntos, puntosTexto }) => {

const superior_card = localStorage.getItem('logo_superior_card');
const logo_inferior_card = localStorage.getItem('logo_inferior_card');
const class_img_sup_card = localStorage.getItem('class_img_sup_card');
const themecode =localStorage.getItem("themecode");

///
let navigate = useNavigate();

const goredempoints = (event) => {
  navigate("/Home/Membresia")
}

  return (
    <div className="card_main mt-0 p-0" onClick={goredempoints}>
  <div className="row p-0 m-0">
    <div className="col-6 col-md-5 m-2 d-flex align-items-center">
      <img
        src={superior_card}
        alt="Logo"
        className={class_img_sup_card}
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
    <div className="col d-flex flex-column justify-content-center p-0 m-0" style={{ marginTop: "38px" }}>
      <div className="w-100 text-right">
        <span className="d-block d-md-none inter_16_800 primary-color-text">{puntos}</span>
        <span className="d-none d-md-block inter_20_500 primary-color-text">{puntos}</span>
      </div>
      <div className="textPuntos text-right">
        <span className="d-block d-md-none inter_14_500 primary-color-text">{puntosTexto}</span>
        <span className="d-none d-md-block inter_14  primary-color-text">{puntosTexto}</span>
      </div>
    </div>
  </div>

  <div className="row p-0 m-0">
    <div className="col-7 col-md-6 pl-4 pt-4 left pb-4" style={{overflow: 'hidden'}}>
      <div className="w-100">
        <span className="d-block d-md-none inter_16_800 primary-color-text"> {usuario}</span>
        <span className="d-none d-md-block inter_20_500 primary-color-text "> {usuario}</span>
      </div>
    </div>
    <div className="col d-flex align-items-center justify-content-center pr-4 pt-4 pb-4" style={{overflow: 'hidden'}}>
      {themecode !== "Fleksi" && (
        <img
          src={logo_inferior_card}
          alt="Fleksi Logo"
          style={{ width: '60px', height: 'auto' }}
          className='logo_inferior_card me-md-5 me-lg-0 ms-lg-5'
        />
      )}
    </div>
  </div>
</div>

  );
};

export default CardComponent;
