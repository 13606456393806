import React, { useEffect, useState } from 'react';
import { BsEnvelope, BsEnvelopeOpen, BsStar, BsStarFill } from 'react-icons/bs';
import { getdata } from '../../apiService';

const Mensajes = () => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await getdata("notificationsall");
        setMessages(response.data);
      } catch (err) {
        setError('Error fetching messages. Please try again later.');
      }
    };

    fetchMessages();
  }, []);

  return (
    <div className="container received-messages">
      <div className="row">
        <div className="col">
          <h2 className="my-4">
            <BsEnvelope /> Mensajes Recibidos
          </h2>
          {error && <p className="text-danger">{error}</p>}
          <ul className="list-messages p-0">
            {messages.map((message) => (
              <li
                key={message.id_notifications}
                className={`list-messages-item ${message.read_notification ? 'read' : 'unread'}`}
              >
                <div className="message-content">
                  <h5 className="mb-1">{message.subject}</h5>
                  <p className="mb-1">{message.content}</p>
                  <small className="text-muted">{message.date_text}</small>
                </div>
                <div className="message-icons">
                  {message.read_notification ? <BsEnvelopeOpen /> : <BsEnvelope />}
                  <BsStar className="ml-2" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mensajes;
