import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import ItemComercios from "../layouts/ItemComercios";
import { postAnyresponse } from "../../apiService";
import ItemProduct from "../layouts/ItemProduct";
import ItemProductBono from "../layouts/ItemProductBono";
import { useLocation } from "react-router-dom";
import CardComponentBilletera from "../layouts/CardComponentBilletera";
import ColorPages from "../layouts/Colorpages";
const BonosDestEsp = () => {
  const [productsone, setProductsOne] = useState([]);
  const [productstwo, setProductsTwo] = useState([]);
  const [message, setMessage] = useState([]);

  const location = useLocation();
  const { selectedCard, title, code } = location.state || {};

  const fetchData = async () => {
    try {
      const responseCategoryPointsHome = await postAnyresponse(
        code,
        "categoryPointsHome"
      );
      setMessage(responseCategoryPointsHome.message_puntos);
      setProductsOne(responseCategoryPointsHome.destacados.productsone);
      setProductsTwo(responseCategoryPointsHome.destacados.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="page">
        <ColorPages />
        <div>
          <Headerback title={selectedCard.title} />
        </div>

        <div className="content-container mx-lg-auto mx-2 mt-md-5">
          {selectedCard && <CardComponentBilletera showMessage={false} />}
        </div>
        <p className="left mx-3 " style={{ marginTop: "40px" }}>
          {message}
        </p>
        <div className="row">
          <div className="col pt-2 inter_20_normal center ml-2 ">
            <span> {title} </span>
          </div>
        </div>
        <div>
          <ItemProduct title="" data={productsone} />
          {productstwo.length > 0 && (
            <ItemProduct title="" data={productstwo} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BonosDestEsp;
