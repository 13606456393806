import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
const BannerSlider = ({ banners }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: '5%',
        },
      },
    ],
  };
  let navigate = useNavigate();
  const handleImageClick = (id, id_type_of_shops) => {

    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate("/home");
    }
    if (id_type_of_shops === 3) {
      navigate("/home");
    }
    if (id_type_of_shops === 2) {
      navigate("/home");
    }
  };

  return (
    <Slider  {...settings}>
      {banners.map((banner) => (
        <div className="slider-item" key={banner.id_banner_app}>
          <img
            src={banner.image}
            alt={banner.title}
            className="img_rounded "
            
            style={{ width: '100%', height: '100%',objectFit:"cover"}}
            onClick={() => handleImageClick(banner.id_banner_app, banner.id_type_of_shops)}
          />
        </div>
      ))}
    </Slider>
  );
};

export default BannerSlider;
