
import React, { useEffect, useState } from "react";
import { accestokenvalid, encrypt, generateI, generateRandomNumberWithTimestamp, getBaseUrl, getdata, getsingle, post, showErrorsFromJson } from "../../apiService";
import { useNavigate } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import ColorPages from "../layouts/Colorpages";
import numeral, { Numeral } from "numeral";
import axios from "axios";
import useCart from "../utils/useCart";
const CryptoJS = require("crypto-js");

const Psepayment = () => {
    const { cartList, update_rewards } = useCart();
    const navigate = useNavigate();
    const [banco, setBanco] = useState(-1);
    const [bancoFocused, setBancoFocused] = useState(false);
    const [bancoError, setBancoError] = useState('');

    const [prospectus_code, setProspectus_code] = useState("");
    const [last_name, setlast_name] = useState("");
    const [id, setid] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailFocused, setEmailFocused] = useState(false);
    const [payment_method, setPayment_method] = useState([]);

    const [telefono, setTelefono] = useState("");
    const [telefonoError, setTelefonoError] = useState("");
    const [telefonoFocused, setTelefonoFocused] = useState(false);

    const [tipoDocumento, setTipoDocumento] = useState("");
    const [tipoDocumentoError, setTipoDocumentoError] = useState("");

    const [nombres, setNombres] = useState("");
    const [nombresError, setNombresError] = useState("");
    const [nombresFocused, setNombresFocused] = useState(false);

    const [numeroDocumento, setNumeroDocumento] = useState("");
    const [numeroDocumentoError, setNumeroDocumentoError] = useState("");
    const [numeroDocumentoFocused, setNumeroDocumentoFocused] = useState(false);

    const [valor_pse] = useState(localStorage.getItem("valor_pse"));

    class FleksiEncrypt {
        constructor(key) {
            if (key.length < 32) {
                this.key = CryptoJS.enc.Utf8.parse(key.padEnd(32, '\0'));
            } else {
                this.key = CryptoJS.enc.Utf8.parse(key.slice(0, 32));
            }
            this.iv = CryptoJS.enc.Utf8.parse('0000000000000000');
        }

        encrypt(text) {
            // Agrega padding con ceros al final del texto si su longitud no es un múltiplo de 16
            while (text.length % 16 !== 0) {
                text += '\0';
            }

            const encrypted = CryptoJS.AES.encrypt(text, this.key, {
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.NoPadding
            });
            return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
        }
    }

    function cifrarTexto(texto, fleksiEncrypt) {
        if (!texto) {
            console.error('El texto a cifrar es nulo o indefinido');
            return '';
        }
        return fleksiEncrypt.encrypt(texto);
    }
    // Función para cifrar el texto
    const fetchData = async () => {
        try {


            const bancos = await getdata("getpsebanking");
            setPayment_method(bancos.data);

            const info = await getsingle("data_payment");

            setEmail(info.email);
            setEmailFocused(true);

            setNombres(info.first_name);
            setNombresFocused(true);

            setTipoDocumento(info.id_document_type);

            setTelefono(info.cell_phone);
            setTelefonoFocused(true);

            setNumeroDocumento(info.document_number);
            setNumeroDocumentoFocused(true);

            setlast_name(info.last_name)
            setid(info.id)

            setProspectus_code(info.prospectus_code)


        } catch (error) {
            console.error(error);
        }
        return () => {}
    };

    useEffect(() => {        
        fetchData();
    }, []);


    const handleInputFocus = (event) => {
        const { name } = event.target;
        if (name === "email") {
            setEmailFocused(true);
        } else if (name === "telefono") {
            setTelefonoFocused(true);
        } else if (name === "nombres") {
            setNombresFocused(true);
        } else if (name === "numeroDocumento") {
            setNumeroDocumentoFocused(true);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'banco') {

            setBanco(value);
        } else if (name === "email") {
            setEmail(value);
        } else if (name === "telefono") {
            setTelefono(value);
        } else if (name === "tipoDocumento") {
            setTipoDocumento(value);
        } else if (name === "numeroDocumento") {
            setNumeroDocumento(value);
        }
    };
    const handleInputBlur = (event) => {
        const { name, value } = event.target;

        if (name === "nombres") {
            setNombresError(value.trim() === "" ? "Este campo es obligatorio" : "");
            setNombresFocused(false);
            if (value !== "") {
                setNombresFocused(true);
            }
        }

        if (name === 'banco') {
            setBancoError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setBancoFocused(false);
            if (value !== '') {
                setBancoFocused(true);
            }
        } else if (name === "email") {
            setEmailError(value.trim() === "" ? "Este campo es obligatorio" : "");
            setEmailFocused(false);
            if (value !== "") {
                setEmailFocused(true);
            }
        }
        else if (name === "telefono") {
            setTelefonoError(value.trim() === "" ? "Este campo es obligatorio" : "");
            setTelefonoFocused(false);
            if (value !== "") {
                setTelefonoFocused(true);
            }
        }
        else if (name === "tipoDocumento") {
            setTipoDocumentoError(
                value.trim() === "" ? "Este campo es obligatorio" : ""
            );
        } else if (name === "numeroDocumento") {
            setNumeroDocumentoError(
                value.trim() === "" ? "Este campo es obligatorio" : ""
            );
        }
    }

    const handleTouchStart = (event) => {
        event.preventDefault();
    };

    const handleTouchMove = (event) => {
        event.preventDefault();
    };

    const handleWheel = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;

        if (nombres.trim() === "") {
            setNombresError("Este campo es obligatorio");
            hasError = true;
        } else {
            setNombresError("");
        }

        if (email.trim() === "") {
            setEmailError("Este campo es obligatorio");
            hasError = true;
        } else {
            setEmailError("");
        }

        if (telefono.trim() === "") {
            setTelefonoError("Este campo es obligatorio");
            hasError = true;
        } else if (!/^\d{10}$/.test(telefono)) {
            setTelefonoError("El número de teléfono debe ser numérico y de 10 dígitos");
            hasError = true;
        } else {
            setTelefonoError("");
        }

        if (tipoDocumento == "") {
            setTipoDocumentoError("Este campo es obligatorio");
            hasError = true;
        } else {
            setTipoDocumentoError("");
        }

        if (numeroDocumento.trim() === "") {
            setNumeroDocumentoError("Este campo es obligatorio");
            hasError = true;
        } else {
            setNumeroDocumentoError("");
        }


        // Validación del formato de email
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailError("Ingrese un email válido");
            hasError = true;
        }

        if (hasError) {
            return;
        }
        // Create PseModel object
        const sendpayment = async () => {
            const key = '16fc27927b98cc5bea397eeda8e7e77c'; // reemplaza esto con tu clave real
            const fleksiEncrypt = new FleksiEncrypt(key);
            const pse = {
                banco: await cifrarTexto(banco, fleksiEncrypt),
                tipo_persona: await cifrarTexto("0", fleksiEncrypt),
                tipo_doc: await cifrarTexto("CC", fleksiEncrypt),
                documento: await cifrarTexto(numeroDocumento, fleksiEncrypt),
                nombres: await cifrarTexto(nombres, fleksiEncrypt),
                apellidos: await cifrarTexto(last_name || "", fleksiEncrypt),
                email: await cifrarTexto(email, fleksiEncrypt),
                factura: await cifrarTexto(generateRandomNumberWithTimestamp() + "" + id, fleksiEncrypt),
                descripcion: await cifrarTexto("Compra en Fleksi", fleksiEncrypt),
                valor: await cifrarTexto(valor_pse, fleksiEncrypt),
                iva: await cifrarTexto("0", fleksiEncrypt),
                baseiva: await cifrarTexto("0", fleksiEncrypt),
                celular: await cifrarTexto(telefono.replace("-", ""), fleksiEncrypt),
                moneda: await cifrarTexto("COP", fleksiEncrypt),
                pais: await cifrarTexto("CO", fleksiEncrypt),
                url_respuesta: await cifrarTexto(getBaseUrl() + "by_epayco_v3", fleksiEncrypt),
                url_confirmacion: await cifrarTexto(getBaseUrl() + "by_epayco_v3", fleksiEncrypt),
                ip: await cifrarTexto("190.000.000.000", fleksiEncrypt),
                metodoconfirmacion: await cifrarTexto("POST", fleksiEncrypt),
                ico: await cifrarTexto("0", fleksiEncrypt),
                extra1: await cifrarTexto("0", fleksiEncrypt),
                extra2: await cifrarTexto(JSON.stringify(cartList[0]), fleksiEncrypt),
                extra3: await cifrarTexto(prospectus_code + "-" + id, fleksiEncrypt),
                ciudad: await cifrarTexto("", fleksiEncrypt),
                depto: await cifrarTexto("", fleksiEncrypt),
                direccion: await cifrarTexto("", fleksiEncrypt),
                public_key: await cifrarTexto("0", fleksiEncrypt),
                i: generateI().replace("\n", ""),
                lenguaje: "android"
            };
            try {
                const getpse = await axios.post(getBaseUrl() + "createPseTransaction", pse, {
                    headers: {
                        Authorization: `${accestokenvalid()}`, // Agrega el token de acceso como parte del encabezado
                        'Content-Type': 'application/json'
                    }
                });

                if (getpse.data.response.success == true) {
                    const link = getpse.data.response.data.urlbanco;
                    const x_ref_payco = getpse.data.response.data.ref_payco;
                    localStorage.setItem("x_ref_payco", x_ref_payco);
                    localStorage.setItem("link", link);
                    navigate("/home/NewOpenPseUrlActivity");

                } else {
                    showErrorsFromJson(getpse.data);
                }
            } catch (error) {

            }

        }

        sendpayment();

    }

    return (
        <div style={{ width: '100%', height: '100%'}}>
            <ColorPages></ColorPages>
            <div>
                <Headerback title="Pago" />
            </div>
            <div className="mt-md-4 container-pago" style={{ padding: 16, flex: 1, overflowY: 'auto' }}>
                <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: "70px", textAlign: "left" }}>Medio de pago</p>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 16 }}>
                    <img
                        style={{ width: 48, height: 48 }}
                        src={process.env.PUBLIC_URL + "/images/icon_pse.jpg"}
                        alt="PSE Logo"
                    />
                    <div style={{ paddingLeft: 16, paddingRight: 8, width: 120, marginTop: "15px" }}>
                        <p style={{ fontSize: 16 }}>PSE ACH</p>
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}>
                    <p style={{ fontSize: 14, }}>Total a pagar</p>
                    <p style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>{numeral(valor_pse).format("$0,0")}</p>
                </div>
                <p style={{ textAlign: 'left', fontSize: 14, color: '#393F42', marginTop: "20px" }}>Seleccione su Banco</p>
                <div style={{ height: 60, backgroundColor: 'white', marginTop: 6, justifyContent: 'center' }}>

                    <div className={`w-100 form-input-container ${bancoFocused ? 'input-filled' : ''}`}>
                        <label className="form-label"></label>
                        <select
                            name="banco"
                            value={banco}
                            onFocus={handleInputFocus}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className="form-input bg-gray-light">
                            {payment_method.map((opcion) => (
                                <option key={opcion.bankCode} value={opcion.bankCode}>{opcion.bankName}</option>
                            ))}
                        </select>
                        {bancoError && <span className="error-label">{bancoError}</span>}
                    </div>
                </div>


                <div
                    className={`w-100 form-input-container ${nombresFocused ? "input-filled" : ""
                        }`}
                >
                    <label className="form-label">Nombres</label>
                    <input
                        type="text"
                        name="nombres"
                        value={nombres}
                        onFocus={handleInputFocus}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-input disable-zoom"
                        id="nombres"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onWheel={handleWheel}
                    />
                    {nombresError && (
                        <span className="error-label">{nombresError}</span>
                    )}
                </div>
                <p style={{ textAlign: 'left', fontSize: 16, color: '#393F42', marginTop: 15 }}>Email donde enviaremos tus bonos</p>


                <div
                    className={`w-100 form-input-container ${emailFocused ? "input-filled" : ""
                        }`}
                >
                    <label className="form-label">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onFocus={handleInputFocus}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-input disable-zoom"
                        id="email"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onWheel={handleWheel}
                    />
                    {emailError && <span className="error-label">{emailError}</span>}
                </div>

                <div
                    className={`w-100 form-input-container ${telefonoFocused ? "input-filled" : ""
                        }`}
                >
                    <label className="form-label">Celular</label>
                    <input
                        type="tel"
                        name="telefono"
                        value={telefono}
                        onFocus={handleInputFocus}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-input disable-zoom"
                        id="telefono"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onWheel={handleWheel}
                    />
                    {telefonoError && (
                        <span className="error-label">{telefonoError}</span>
                    )}
                </div>


                <div
                    className={`w-100 form-input-container ${tipoDocumentoError ? "input-filled" : ""
                        }`}
                >
                    <label className="form-label"></label>
                    <select
                        name="tipoDocumento"
                        value={tipoDocumento}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-input disable-zoom"
                        id="tipoDocumento"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onWheel={handleWheel}
                    >
                        <option value="cc">Cédula de Ciudadanía</option>
                        <option value="ce">Cédula de Extranjería</option>
                    </select>
                    {tipoDocumentoError && (
                        <span className="error-label">{tipoDocumentoError}</span>
                    )}
                </div>

                <div
                    className={`w-100 form-input-container ${numeroDocumentoFocused ? "input-filled" : ""
                        }`}
                >
                    <label className="form-label">Número de Documento</label>
                    <input
                        type="text"
                        name="numeroDocumento"
                        value={numeroDocumento}
                        onFocus={handleInputFocus}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-input disable-zoom"
                        id="numeroDocumento"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onWheel={handleWheel}
                    />
                    {numeroDocumentoError && (
                        <span className="error-label">{numeroDocumentoError}</span>
                    )}
                </div>

            </div>
            <div className=" w-100   center">
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="button-primary"
                >
                    Continuar con el pago
                </button>         </div>
        </div>
    );
};

export default Psepayment;
