import React, { useState } from 'react';

const TabsComponentIntereses = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container">
      <div className="tabs-scroll">
        <ul className="nav nav-tabs">
          {tabsData.map((tab, index) => (
            <li
              key={index}
              className={`nav-item ${activeTab === index ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              <a className="nav-link" href="#">{tab.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="tab-content">
        {tabsData.map((tab, index) => (
          <div
            key={index}
            className={`tab-pane ${activeTab === index ? 'active' : 'fade'}`}
          >
            <div className="row px-4 m-0 p-0">
              {tab.items.map((item, itemIndex) => (
                <div className="tab-item col-6" key={itemIndex}>
                  <img src={item.image} alt={tab.title} className="img-fluid" />
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponentIntereses;
