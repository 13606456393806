import React, { useEffect, useState } from 'react';
import { getdata, getsingle, post } from '../../apiService';
import NotificationModal from './NotificationModal';
const ModalDocument = ({ activated, handleCloseModal }) => {

  const [documentFocused, setDocumentFocused] = useState(false);
  const [documentValue, setDocumentValue] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const checkDocumentNumberExists = await getsingle("checkDocumentNumberExists");

        if (checkDocumentNumberExists.message == "ok") {
         handleCloseModal();
        }


      } catch (error) {
        console.error(error);
      }
      return () => {}
    };

    fetchData();
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  const handleInputChange = (event) => {
    setDocumentValue(event.target.value);
  };

  const handleInputFocus = () => {
    setDocumentFocused(true);
  };

  const handleInputBlur = () => {
    setDocumentFocused(false);
  };

  const fetchData = async () => {
    try {
      await post({ document_number: documentValue }, "updateDocument");

      setShowNotification(true);

      setTimeout(() => {
        handleCloseModal();
      }, 2000);


    } catch (error) {
      console.error(error);
    }
  };

  const updateDocument = () => {

    if (documentValue != "") {    
      fetchData();
    }
  }


  return (

    <div id='modal' className={`modal ${activated ? 'active' : ''}`}>
      {showNotification && (
        <NotificationModal
          message="¡Buenas noticias! Tu documento ha sido guardado con éxito."
          onClose={handleCloseNotification}
        />
      )}
      <div className="modal-content">
        <img src={process.env.PUBLIC_URL + '/images/bell.png'} className='img_bell mt-3' alt="splash" />
        <div className='inter_14_gray_  mt-2'>Por favor déjanos saber tu número de documento para cargar a tu tarjeta los puntos que obtengas en compras</div>
        <div className={`w-100 mt-3 form-input-container ${documentValue ? 'input-filled' : ''}`}>

          <label className="form-label  ">Documento</label>
          <input
            type="number"
            name="document"
            value={documentValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className="form-input w-100 border-bottom-primary bg-gray-light"
          />
        </div>
        <div className="center-content  px-2 ">

          <button onClick={updateDocument} className="button-primary w-100">Guardar</button>
        </div>
        <div className='modal-footer'>
          <a href="#" className="close-link" onClick={handleCloseModal}>Agregar después</a>
        </div>
      </div>
    </div>
  );
};

export default ModalDocument;
