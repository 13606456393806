import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import ImageComponent from "./ImageComponent";
import ScrollContainer from "../utils/ScrollContainer";
const ItemProductEcommerce = ({ title, data }) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const randomNumber = Math.floor(Math.random() * 1000);

  const handleCardClick = (code) => {
    if (isDragging) {
      return;
    }
    navigate(`/home/EcommerceProducto?code=${code}&rand=${randomNumber}`);
    window.location.reload();
  };

  return (
    <div className="mt-0 pt-0">
      {data.length > 0 && (
        <div className="inter_18_gray mb-2 left ml-2">{title}</div>
      )}
      <ScrollContainer onDragChange={setIsDragging}>
        <div style={{ height: "auto", width: "auto", display: "inline-flex" }}>
          {data.map((item) => (
            <div
              className=" ml-4 mr-2 card_back center"
              key={item.id_fleksi_products}
              onClick={() => handleCardClick(item.id_fleksi_products)}
            >
              <div
                className="card-products center"
                style={{ backgroundColor: item.background }}
              >
                <div className="img-top-round">
                  <ImageComponent
                    src={item.image}
                    alt={item.title}
                    defaultSrc={
                      process.env.PUBLIC_URL + "/images/defaultimage.png"
                    }
                    cssclass={"img-resposive-adjust"}
                  />
                </div>
                <span className="overlay-text-type bg-red color-white">
                  E-commerce
                </span>
                </div>
                <div className="container-text-prduct mx-auto">
                {item.discount !== "" && item.discount > 0 && (
                  <>
                    <span className="overlay-promo-text color-white">
                      {item.discount}
                    </span>
                  </>
                )}
              
              <span
                className="inter_14_600 gray-dark mt-2 px-2 w-100 left rm-2 title-desk"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.ecommerce}
              </span>
              <span
                className="inter_14_600 gray-dark mt-2 px-2 w-100 left rm-5 "
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.title}
              </span>
              <span
                className="inter_12 gray-dark pb-md-3  px-2  w-100 left rm-5 description-desk"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.subtitle}
              </span>
              <span className="inter_14_600 gray-dark px-2 w-100 left rm-2 mt-md-2 d-flex justify-content-start ">
                {numeral(item.valor_de_venta).format("0") !== "0" ? (
                  <div>{numeral(item.valor_de_venta).format("$0,0")} pesos</div>
                ) : (
                  <div style={{ color: "white" }}>.</div>
                )}
              </span>
              <span className="inter_14_600_green px-2 w-100 rm-2 mb-md-2">
                Hasta {item._return_to_presentation_users}% Cashback
              </span>
              </div>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default ItemProductEcommerce;
