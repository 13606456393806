import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCart from '../utils/useCart';
const HeaderTitle = ({ title, totalcart=0 }) => {

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1)
  };
  const handleGogocart = () => {
    navigate("/home/Cart")
  };
  


  return (
    <div  className='w-100 bg-white ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
     <div className="banner">
        <div className="left-div pt-2"  onClick={handleGoBack}>
        <svg
            version="1.1"
            viewBox="0 0 1600 1600"
            width="40px"
            height="auto"
            xmlns="http://www.w3.org/2000/svg"
            className="back-icon"
          >
            <path
              transform="translate(663,330)"
              d="m0 0 5 1 8 6 54 54 8 7 12 12 2 1v2l4 2 8 9v5l-14 15-8 8-7 8-4 4h-2l-2 4-47 47-4 5-9 9h-2l-1 3-45 45h-2l-1 3-21 21h-2l-2 4-6 5-5 6-5 5h-2l-1 3-8 7-54 54-8 7-9 10-22 22h-2l-2 4-2 2 736 2 192 1 6 2 1 127-4 3-10 1-711 1h-191l-20-1 6 8 19 19 8 7 4 5 3 2v2l4 2 168 168v2l4 2 8 8v2l4 2v2l4 2 8 8v2l4 2v2l4 2 8 8v2l4 2 34 34 6 9v5l-8 8-8 7-26 26h-2v2h-2l-2 4-32 32-7 8-8 6-4 2v-2l-4-2-12-12-7-8-429-429-8-7-4-4v-2h-2l-3-5 10-13 13-12 17-16 17-17 1-2h2l2-4 390-390 7-8z"
            />
          </svg>
        </div>
        <div className="center-div align-items-center">
        <div className="header-title center w-100">{title}</div>
        </div>
        <div className="right-div"   onClick={handleGogocart}> 
        <div className='d-flex gap-1'>
        <svg
              version="1.1"
              viewBox="0 0 1600 1600"
              width="28px"
              height="auto"
              xmlns="http://www.w3.org/2000/svg"
              className="cart-icon"
            >
              <path
                transform="translate(91,63)"
                d="m0 0h180l41 3 23 4 17 5 23 11 13 9 10 8v2l4 2 7 8 10 13 10 18 11 23 10 28 10 35 21 87 11 44 20 85 6 21 3 9 970 1 17 1 12 3 8 5 4 4 2 8-1 15-3 15-11 33-11 29-15 41-13 34-14 37-11 29-9 24-21 55-11 29-20 53-17 44-7 20-10 26-16 43-15 39-11 29-14 34-7 12-6 7-5 2-55 1h-266l-279 1 6 19 16 64 13 45 7 17 8 15 8 10 12 8 12 5 17 4 17 2 38 1h357l113 1 16 2 6 4 6 12 9 27 17 33 7 19 3 14 1 9v27l-3 15-8 18-11 20-6 8-11 13-10 9-14 9-14 7-25 9v2h-55v-2h-2v-2l-10-3-23-11-16-11-12-11-11-13-10-16-7-16-5-15-2-9-1-19 2-21 7-25 5-14 2-5h-193l10 22 4 13 3 21v29l-3 14-8 18-10 18-9 12-12 13-14 11-18 10-27 10-1 2h-55l-4-4-21-9-13-7-17-12-9-9-13-17-9-16-8-21-4-17v-35l5-21 11-21 7-11 3-7-1-4-5-4-13-9-9-7-6-5-7-8-14-21-9-19-10-27-9-31-8-29-16-64-11-46-6-24-18-73-24-97-17-67-9-37-20-81-9-37-32-128-28-113-20-81-17-69-14-56-9-34-9-26-8-18-9-16-11-13-10-7-16-7-12-3-27-3-17-1h-137l-4 2-4 19-10 14-12 14-10 7-9 4-9 2h-21l-13-3-12-6-10-9-9-10-9-11-1-1v-44l9-11 8-10 9-9 13-8 10-3 12-1zm566 479-121 1 11 44 18 73 27 110 47 188 14 59 9 38 8 30 1 2 5 1 569-1 5-2 4-5 11-28 9-25 6-15 15-40 30-79 13-35 10-26 8-21 16-43 7-18 14-37 19-50 13-35 11-28 9-23 10-29v-5l-218-1zm162 866-13 4-16 10-8 8-9 16-4 11-2 10v8l3 14 4 10 11 16 9 8 12 7 15 4 16 1 13-3 16-8 10-8 8-9 8-16 2-8v-21l-3-14-6-11-11-13-11-8-10-5-14-3zm415 0-10 3-14 8-10 9-7 10-6 13-3 11v16l4 16 6 12 8 10 5 5 11 7 11 4 15 3h9l11-2 16-8 11-8 9-10 7-14 3-13v-12l-2-15-4-11-6-9-9-10-13-9-14-5-7-1zm-431 189m416 0m-413 1 4 1zm417 0 4 1z"
              />
              <path transform="translate(859,1598)" d="m0 0 3 2-4-1z" />
              <path transform="translate(791,1598)" d="m0 0 3 1-3 1z" />
              <path transform="translate(1275,1599)" d="m0 0 3 1z" />
              <path transform="translate(1207,1598)" d="m0 0h2l-1 2z" />
            </svg>
           <span className='cantidad' id='cantidad'>{totalcart}</span>
          </div>
        </div>
      </div>
  </div>
  );
};

export default HeaderTitle;
