import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import CardComponent from "../layouts/CardComponent";
import { Link, useNavigate } from "react-router-dom";
import ColorPages from "../layouts/Colorpages";
import { getdata, getsingle } from "../../apiService";
import numeral from "numeral";
import { getAuth, signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { googleLogout } from "@react-oauth/google";
import { Icons } from "react-toastify";
import {
  BsHouse,
  BsClockHistory,
  BsCreditCard,
  BsPersonPlus,
  BsChatLeftTextFill,
  BsQuestionCircle,
  BsChatDots,
  BsChatSquareText,
  BsCardText,
  BsExclamationOctagon,
  BsBell,
  BsTicket,
  BsShieldExclamation
} from "react-icons/bs";

const Menu = () => {
  const [userPoints, setUserPoints] = useState({ total: 0, name: "" });
  const [termsData, setTermsData] = useState("");
  let navigate = useNavigate();

  const handleSignOut = () => {
    // localStorage.removeItem('appDesign');
    localStorage.clear();
    googleLogout();

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        //  localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
        // localStorage.clear();
      });
    navigate("/");
  };

  const fetchData = async () => {
    try {
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      const checkTermsAcceptance = await getdata("checkTermsAcceptance");
      setTermsData(checkTermsAcceptance.terms)
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <ColorPages />
      <Headerback title="" />
      <div className="page">
        <div className="px-4 mt-4 content-container mx-auto">
          <CardComponent
            puntos={numeral(userPoints.total).format("0,0")}
            usuario={userPoints.name}
            puntosTexto="Mis puntos"
          />
        </div>
        <div className="center-content buttons-row  px-3  pt-4">
          <Link
            to="../Home/Mibilletera"
            className="link-no-underline button-primary "
          >
            {" "}
            Convertir puntos{" "}
          </Link>
          <Link
            to="../Home/Perfil"
            className="link-no-underline button-secundary-login  bg-white "
          >
            Completa tu perfil
          </Link>
        </div>
        <div className="container-menu">
          <ul className=" center-menu px-4 mt-3">
            <li className="left ">
              <Link to="../Home/" className="close-link_black left W-100">
                <BsHouse className="icon" /> Home
              </Link>
            </li>

            <li className="left ">
              <Link
                to="../Home/HistorialCompras"
                className="close-link_black left W-100"
              >
                <BsClockHistory className="icon" /> Historial de compras
              </Link>
            </li>

            <li className="left ">
              <Link
                to="../Home/HistorialPuntos"
                className="close-link_black left W-100"
              >
                <BsTicket className="icon" /> Fleksi Puntos
              </Link>
            </li>

            <li className="left ">
              <Link
                to="../Home/DatosBancarios"
                className="close-link_black left W-100"
              >
                <BsCreditCard className="icon" /> Datos Bancarios
              </Link>
            </li>

            <li className="left ">
              <Link
                to="../Home/InvitarAmigo"
                className="close-link_black left W-100"
              >
                <BsPersonPlus className="icon" /> Invitar a un amigo
              </Link>
            </li>
            <li className="left ">
              <Link
                to="../Home/MisInvitados"
                className="close-link_black left W-100"
              >
                <BsPersonPlus className="icon" /> Mis invitados
              </Link>
            </li>
            <li className="left ">
              <Link
                to="../Home/Mensajes"
                className="close-link_black left W-100"
              >
                <BsChatLeftTextFill className="icon" /> Mensajes
              </Link>
            </li>
            <li className="left ">
              <Link
                to="../Home/CentroNotificaciones"
                className="close-link_black left W-100"
              >
                <BsChatSquareText className="icon" /> Centro de Notificaciones
              </Link>
            </li>
            <li className="left ">
              <Link
                to="../Home/CambioContraseña"
                className="close-link_black left W-100"
              >
                <BsShieldExclamation className="icon" /> Cambiar contraseña
              </Link>
            </li>
            </ul>
            <h2 className="left mt-3 inter_16_700 px-4"> Sobre fleksi </h2>
            <ul className=" center-menu px-4 mt-3">
                <li className="left ">
                  <a
                    href={termsData.politica_datos}
                    className="close-link_black left W-100"
                  >
                    <BsCardText className="icon" /> Tratamiento de Datos
                  </a>
                </li>
                <li className="left ">
                  <a
                    href={termsData.content}
                    className="close-link_black left W-100"
                  >
                    <BsCardText className="icon" /> Términos y condiciones
                  </a>
                </li>
                <li className="left ">
                  <a
                    href={termsData.politicadeprivacidad}
                    className="close-link_black left W-100"
                  >
                    <BsCardText className="icon" /> Políticas de Privacidad
                  </a>
                </li>
            <li className="left ">
              <Link
                to="../Home/"
                className="close-link_black left W-100"
              >
                <BsChatDots className="icon" /> Contacto
              </Link>
            </li>
            <li className="left ">
                  <a
                    href={termsData.linksuper}
                    className="close-link_black left W-100"
                  >
                    <BsExclamationOctagon className="icon" />{" "}
                    {termsData.textolinksuper ? termsData.textolinksuper : "SIC"}
                  </a>
                </li>
                </ul>
            {/*   <li className='left mt-3'>
            <Link to="../Home/Ayuda" className="close-link_black left W-100">
              <BsQuestionCircle /> Ayuda
            </Link>
  </li> */}
          
        </div>

        <div className="center px-3 py-2 ">
          <button
            className="link-no-underline button-secundary-login  bg-white mt-2"
            onClick={handleSignOut}
          >
            Cerrar sesión
          </button>
          {/*       <a href="#" className="close-link mt-3">Eliminar cuenta</a> */}
        </div>
      </div>
    </div>
  );
};

export default Menu;
