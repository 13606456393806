import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollContainer from "../utils/ScrollContainer";

const SubCategorySection = ({ data, code, clickload }) => {
  const [isDragging, setIsDragging] = useState(false);
  const handleClick = (code) => {
    if (isDragging) {
      return;
    }
    clickload(code);
  };
  return (
    <div className=" section-category pt-5 pt-md-5 pt-lg-0">
      <div className=" inter_18_gray left ml-4">Categorías</div>
      <ScrollContainer onDragChange={setIsDragging}>
        <div className="w-100 mt-2 pl-2 " style={{ display: "inline-flex" }}>
          {data.map((item) => (
            <div
              className=" size-content-categories "
              key={item.id_sub_categories_fleski}
              onClick={() => handleClick(item.id_sub_categories_fleski)}
            >
              <div
                style={{ backgroundColor: item.back_color }}
                className={`card_gray_dark_resize center ${
                  item.id_sub_categories_fleski == code ? "bac_primary" : ""
                }`}
              >
                <div >
                  <img
                    src={process.env.PUBLIC_URL + item.image}
                    className="centered-image "
                    alt="Imagen"
                    onDragStart={(e) => e.preventDefault()}
                    style={{ width: "100px", height: "90px" }}
                  />
                </div>
              </div>
              <span className="inter_12 gray mt-2">{item.name}</span>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default SubCategorySection;
