import React from "react";
import { Navbar, Nav, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./splash.module.css";

const SplashScreen = () => {
  return (
    <div className={styles.container}>
      {/* Navigation */}
      <Navbar expand="lg" className={styles.navbar}>
        <Navbar.Brand href="#">
          <img
            src="https://fleksiimg.s3.amazonaws.com/aliados/logos/Logo_fleksi.png"
            alt="Logo"
            className={`${styles["navbar-brand-img"]} ${styles["logo-fleksi"]}`}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className={styles["navbar-nav-right"]}>
            <Nav.Link className={styles["nav-link"]} href="#nosotros">
              Nosotros
            </Nav.Link>
            <Nav.Link className={styles["nav-link"]} href="#comercios">
              Comercios
            </Nav.Link>
            <Nav.Link className={styles["nav-link"]} href="#empresas">
              Empresas
            </Nav.Link>
            <Nav.Link className={styles["nav-link"]} href="#contacto">
              Contáctanos
            </Nav.Link>
            {/*  <Nav.Link  className={styles['nav-link']} href="Session/Login" style={{ fontWeight: 700 }}>Ingresar</Nav.Link>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Hero Section */}
      <section
        className={`${styles["hero-section"]} d-lg-block d-none`}
        id="hero"
      >
        <div className="row">
          <div className="col pt-5">
            <img
              src="images/Banner.png"
              alt=""
              className={styles["hero-image"]}
            />
          </div>
          <div className="col">
            <h1 className={styles["title-text"]}>
              Donde las empresas premian a la medida y sus clientes eligen lo
              que para ellos es importante
            </h1>
          </div>
        </div>
        <div className={styles["hero-text"]}>
          <p className={styles["poppins_32_white"]}>
            Llegó tu momento de ganar, descarga Fleksi de una.
          </p>
          <div className={styles["icons-playstore"]}>
            <a href="#">
              <img
                src="google-play.png"
                alt="Google Play"
                style={{ width: "180px" }}
                className="img-fluid"
              />
            </a>
            <a href="#">
              <img
                src="app-store.png"
                alt="App Store"
                style={{ width: "180px" }}
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </section>
      <section
        className={`${styles["hero-section-responsive"]} d-lg-none `}
        id="hero"
      >
        <div className="row">
          <div className="col">
            <h1 className={styles["title-text"]}>
              Donde las empresas premian a la medida y sus clientes eligen lo
              que para ellos es importante
            </h1>
          </div>
        </div>
        <div className={styles["hero-text"]}>
          <p className={styles["poppins_32_white"]}>
            Llegó tu momento de ganar, descarga Fleksi de una.
          </p>
          <div className={styles["icons-playstore"]}>
            <a href="#">
              <img
                src="google-play.png"
                alt="Google Play"
                style={{ width: "180px" }}
                className="img-fluid"
              />
            </a>
            <a href="#">
              <img
                src="app-store.png"
                alt="App Store"
                style={{ width: "180px" }}
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="nosotros" className={styles["about-section"]}>
        <div className="container">
          <div className="row">
            <div className="text-content col">
              <h2 className={styles["section-title"]}>¿Qué es Fleksi?</h2>
              <p
                className={`${styles["text-justify"]} ${styles["poppins_24"]}`}
              >
                Fleksi es una súper App que reúne las mejores marcas de
                diferentes categorías para brindar a las personas un entorno
                completo en el que realicen sus compras y reciban dinero de
                vuelta, descuentos y beneficios en un solo lugar.
              </p>
            </div>
            <div className="col image-content">
              <div className={styles["image-wrapper_nosotros"]}>
                <img
                  src="images/_prueba.png"
                  alt="Qué es Fleksi"
                  className={styles["img-empresa"]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="comercios" className={styles["features-section"]}>
        <div className="container">
          <h2 className={styles["section-title"]}>Fleksi para comercios</h2>
          <p className={styles["poppins_28"]}>
            Tu marca necesita una plataforma fleksible que se adapte a las
            necesidades de tu cliente y a los objetivos de tu compañía.
          </p>
          <p className={styles["poppins_22"]}>
            Esto es lo que podrás hacer con nosotros:
          </p>
          <div className="row mt-5">
            <div className="col-md col-lg-3 mb-3">
              <div className={`${styles.card} ${styles["feature-box"]}`}>
                <div className="card-body d-flex align-items-center flex-column">
                  <i
                    className={`fas fa-user mb-4 mt-2 mt-sm-0 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>

                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">
                      Conoce a tu shopper
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Conoce las preferencias y hábitos de tus clientes a través
                      de su comportamiento dentro de la app de Fleksi y mantente
                      actualizado sobre sus constantes cambios.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 ">
              <div className={`${styles.card} ${styles["feature-box"]}`}>
                <div className="card-body d-flex align-items-center flex-column">
                  <i
                    className={`fas fa-chart-line mb-4 mt-2 mt-sm-0  ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>
                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">
                      Personalización por hábitos de compra
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Podrás enviar campañas de recomendación de productos y
                      promociones, personalizarlas con tu identidad, y
                      seleccionar el número de clientes nuevos y recurrentes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 ">
              <div className={`${styles.card} ${styles["feature-box"]}`}>
                <div className="card-body d-flex align-items-center flex-column">
                  <i
                    className={`fas fa-shopping-cart mt-2 mt-sm-0  mb-4 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>

                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">
                      Nuevo canal de venta
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Con Fleksi, aumentarás tus ventas, ya que dirigimos
                      usuarios segmentados a tus canales de venta.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3">
              <div className={`${styles.card} ${styles["feature-box"]}`}>
                <div className="card-body d-flex align-items-center flex-column">
                  <i
                    className={`fas fa-user-friends mt-2 mt-sm-0  mb-4 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>
                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">
                      Canal de convenios
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Con Fleksi, podrás acceder a convenios exclusivos que te
                      permitirán comunicarte directamente con clientes
                      potenciales, lo que posibilita una mayor conversión y un
                      mejor posicionamiento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className={`${styles["poppins_28"]}`} style={{ marginTop: 15 }}>
            Conecta con tu cliente premiándolo con lo que de verdad es
            importante para él:
          </p>
          <div
            className="row justify-content-center"
            style={{ paddingTop: 30 }}
          >
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div
                className={`${styles.card} ${styles["feature-box"]}`}
                style={{ minHeight: "370px" }}
              >
                <div className="card-body d-flex align-items-center flex-column justify-content-center">
                  <i
                    className={`fas fa-money-bill-wave mt-2 mt-sm-0  mb-4 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>

                  <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">
                      CASHBACK
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Devolución en dinero de un porcentaje del valor de la
                      compra realizada por el cliente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div
                className={`${styles.card} ${styles["feature-box"]}`}
                style={{ minHeight: "370px" }}
              >
                <div className="card-body d-flex align-items-center flex-column justify-content-center">
                  <i
                    className={`fas  fa-gift mt-2 mt-sm-0 mb-4 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>
                  <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">
                      BENEFICIOS
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                      Premia su fidelidad con beneficios exclusivos dentro de
                      Fleksi, de acuerdo con lo que te resulte más beneficioso
                      como comercio.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div
                className={`${styles.card} ${styles["feature-box"]}`}
                style={{ minHeight: "370px" }}
              >
                <div className="card-body d-flex align-items-center flex-column justify-content-center">
                  <i
                    className={`fas fa-tags mt-2 mt-sm-0  mb-4 ${styles["icon-outside"]}`}
                    style={{ fontSize: "30px" }}
                  ></i>
                  <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">
                      PROMOCIONES
                    </h4>
                    <p
                      className={`text-center ${styles["poppins_18_semibold"]}`}
                    >
                     Crea promociones personalizadas premiando los hábitos de
                     compra de los clientes que más te interesan como marca.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="poppins_22">
            Tú eliges la estrategia, Fleksi la comunica por ti.
          </p>
        </div>
      </section>

      {/* Empresas Section */}
      <section id="empresas" className={styles["empresas-section"]}>
        <div className="container">
          <div className="row">
            <div className="image-content col">
              <div className={styles["image-wrapper"]}>
                <img
                  src="images/Empresas.png"
                  alt="Imagen de teléfonos"
                  className={styles["img-empresa"]}
                />
              </div>
            </div>
            <div className="text-content col">
              <h2 className={styles["section-title"]}>Fleksi para empresas</h2>
              <p
                className={`${styles["poppins_24"]} ${styles["text-justify"]} mb-5`}
              >
                Conoce el plan de beneficios para tus empleados, todo se trata
                de premiar a la medida, y aquí sabemos cómo hacerlo
              </p>
              <h4 className={styles["poppins_primary_color"]}>
                ¡Con puntos Fleksi!
              </h4>
              <p className={styles["poppins_18_semibold"]}>
                Ponte en contacto con nosotros y descubre mucho más.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contacto" className={styles["whatsapp-section"]}>
        <div className="container">
          <h2 className={styles["section-title"]}>Contáctanos</h2>
          <p className={styles["poppins_22"]}>
            Transformemos juntos la forma de premiar
          </p>
          <a
            href="https://wa.me/3239003988"
            className={styles["whatsapp-button"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            Chatea con nosotros en WhatsApp
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className={`text-center py-4 fs-6 ${styles.footer}`}>
        <div
          className={`container d-none d-sm-block d-lg-none ${styles["d-md-block"]}`}
        >
          <div
            className="row row-cols-1 row-cols-lg-3"
            style={{ color: "#02b8fc" }}
          >
            <div className="col">
              <p className="text-muted my-2">Sitios Oficiales</p>
            </div>
            <div className="col">
              <ul className="list-inline my-2">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/fleksirewards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/facebook-40x40.png"
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/fleksirewards/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/instagram-40x40.png"
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/fleksirewards/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/linkedin-40x40.png"
                      alt="LinkedIn"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UC6KblGTP3NjdsWaiKoFmIIg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/youtube-40x40.png"
                      alt="YouTube"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col">
              <p className="text-muted my-2 derechos-fleksi">
                © Fleksi 2022. Todos los derechos reservados
              </p>
            </div>
            <div className="col">
              <ul className="list-inline my-2 footer-links">
                <li className="list-inline-item">
                  <a href="politicadeprivacidadpre-registro.html">
                    Política de Privacidad
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="terminosycondiciones.html">Términos y Condiciones</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://fleksirewards.tawk.help">Soporte</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={`container d-none d-sm-none d-md-block ${styles["d-lg-block"]} fs-2`}
        >
          <div
            className="row row-cols-1 row-cols-lg-3"
            style={{ color: "#02b8fc" }}
          >
            <div className="col-lg-8">
              <p className="text-muted my-2 derechos-fleksi">
                © Fleksi 2022. Todos los derechos reservados
              </p>
              <ul className="list-inline my-2 footer-links">
                <li className="list-inline-item">
                  <a href="politicadeprivacidadpre-registro.html">
                    Política de Privacidad
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="terminosycondiciones.html">Términos y Condiciones</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://fleksirewards.tawk.help">Soporte</a>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-inline my-2">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/fleksirewards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/facebook.png"
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/fleksirewards/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/instagram.png"
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/fleksirewards/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/linkedin.png"
                      alt="LinkedIn"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UC6KblGTP3NjdsWaiKoFmIIg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://fleksirewards.com/images/social/youtube.png"
                      alt="YouTube"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SplashScreen;
