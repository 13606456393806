import React from 'react';
import { useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';

const Endcanjear = () => {
  const navigate = useNavigate();


  
  const verhsitorial = () => {
    navigate("/home/Verhistorial");
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='App backend' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <ColorPages />
      <div className='w-100 backend pt-3' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
        <div className="banner">
          <div className="left-div" onClick={handleGoBack}>
            <img
              src={process.env.PUBLIC_URL + '/images/img-back-white.png'}
              alt="Slider 1"
              style={{ width: '28px', height: 'auto' }}
            />
          </div>
          <div className="center-div align-items-center">
            <div className="header-title left w-100"></div>
          </div>
          <div className="right-div"></div>
        </div>
      </div>
      <div className='mt-3 center d-block'>
      <p className='w-100  gray-dark inter_18 pb-3'>Tu solicitud ha sido creada</p>
        <button  onClick={verhsitorial} className='button-secundary backend  button-text'>Ver Historial</button>
      </div>
    </div>
  );
};

export default Endcanjear;
