import React, { useEffect, useState } from 'react';
import HeaderEcommerce from '../layouts/HeaderEcommerce';
import ItemProductExternal from '../layouts/ItemProductExternal';
import ColorPages from '../layouts/Colorpages';
import { Link, useNavigate } from 'react-router-dom';
import { getdata, getsingle, post } from '../../apiService';
import BannerSlider from '../layouts/BannerSlider';
import ItemProduct from '../layouts/ItemProduct';
import Headerback from '../layouts/Headerback';

const ExternalAliados = () => {

    const superior_card = localStorage.getItem('logo_splash');
    const [all, setAll] = useState([]);
    const [alltwo, setAlltwo] = useState([]);
    const [banners, setBanners] = useState([]);

    const fetchData = async () => {
        try {
     
            const homeresponse = await getdata("allied_ecommercehome")
            setAll(homeresponse.productsone);
            setAlltwo(homeresponse.productstwo);

        } catch (error) {
            console.error(error);
        }

        return () => {}
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    return (

        <div>
<ColorPages />
            <Headerback title="" />
            <div className='page '>
                <div className='section mt-2 mb-4'>
                    <div className='bg-primary-color p-3 pt-5 pb-5'>
                    <img
                    src={superior_card}
                    className="center"
                    alt="Imagen"
                    style={{ width:"100%" , height:"auto" }}
                  />    </div>
                </div>
     
                <div className='mt-2'  >
                    <ItemProduct title="Beneficios" data={all} />
                    {alltwo.length > 0 && <ItemProduct title="" data={alltwo} />}
                </div>

                <div style={{ marginTop: "40px" }} >
                    <BannerSlider banners={banners} />
                </div>

            </div>
        </div>
    );
};

export default ExternalAliados;
