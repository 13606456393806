import axios from 'axios';

const baseUrl = 'https://www.apifleksiprod.site/';
//const baseUrl = 'http://localhost:80/Apifleksi_v_produccion/public/api/';
export const getBaseUrl = () => {
  return baseUrl;
}

export const showErrorsFromJson = (jsonString) => {
  const jsonObject = JSON.parse(jsonString);
  const dataObject = jsonObject.data;
  if (dataObject && dataObject.errores) {
      const errorsArray = dataObject.errores;
      let errorMessages = '';
      for (let i = 0; i < errorsArray.length; i++) {
          const error = errorsArray[i];
          errorMessages += error.errorMessage + '\n';
      }

      // Show the error messages in an alert dialog
      alert(errorMessages.trim());
  }
}

export const generateI = () => {
  const iv = "0000000000000000";
  const iv64 = btoa(iv);
  return iv64.replace("\n", "");
}

let generatedNumbers = new Set();

export const generateRandomNumberWithTimestamp = () => {
  let uniqueNumber;
  do {
    // Extend the range of the random number to increase uniqueness
    const randomNumber = Math.floor(Math.random() * (9999 - 100 + 1)) + 100;

    // Include more components of the date and time to increase uniqueness
    const currentDateTime = new Date();
    const timeString = currentDateTime.getMinutes().toString().padStart(2, '0') +
      currentDateTime.getSeconds().toString().padStart(2, '0') +
      currentDateTime.getMilliseconds().toString().padStart(3, '0');
    // Combine the random number with the full timestamp.
    uniqueNumber = timeString + randomNumber.toString();
    // This loop checks for uniqueness within this run,
    // make sure to implement proper checking if you need persistence between runs
  } while (generatedNumbers.has(uniqueNumber));
  // Add the generated number to the set for future checks
  generatedNumbers.add(uniqueNumber);

  return uniqueNumber;
}


export const getAccessToken = () => {
  return localStorage.getItem('accessToken'); // Obtener el token de localStorage
};


export const accestokenvalid = () => {

  var accessToken = localStorage.getItem('accessToken'); // Obtén el token de acceso almacenado en localStorage
  if (accessToken != "" && accessToken != undefined && accessToken != 'undefined') {

    if (!accessToken.startsWith('Bearer ')) {
      accessToken = `Bearer ${accessToken}`; // Agrega "Bearer" al inicio del accessToken
    }
    return accessToken;
  }
  window.location.href = "/Session/Login";
}


export const loginfire = async (data, callback, onError) => {
  const apiUrl = baseUrl + "login_fire";
  try {
    const response = await axios.post(apiUrl, data);

    if (typeof callback === 'function') {
      callback(response.data.token);
    }

  } catch (error) {

  }
};

export const login = async (username, password, callback, errorcallback) => {
  const requestData = {
    grant_type: 'password',
    client_id: '2',
    client_secret: 'twaVLjiUqefEfiacIUYA1mHAUfPbrcJIIU6VeitT8O3VKGXWz7olvtZlgobNpaMQ',
    username,
    password
  };

  try {
    const response = await axios.post(baseUrl + 'oauth', requestData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (typeof callback === 'function') {
      callback(response.data.access_token);
    }
  } catch (error) {

    if (typeof errorcallback === 'function') {
      errorcallback();
    }
  }
};


export const getdata = async (service) => {
  const apiUrl = baseUrl + service;

  var accessToken = accestokenvalid(); // Agrega "Bearer" al inicio del accessToken

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    if (response.data == "Unauthorized guest") {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }
    try {
      if (error.response.status == 403) {
        return error
      }
    } catch (e) {
      return error
    }

  }
};

export const getsingle = async (service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    if (response.data == "Unauthorized guest") {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }
  }
};

export const getsinglenoauth = async (service) => {
  const apiUrl = baseUrl + service;
  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {

  }
};

export const post = async (data, service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    if (response.data == "Unauthorized guest") {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }

    return response.data.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }
  }
};

export const postReponse = async (data, service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    if (response.data == "Unauthorized guest") {
      localStorage.clear();
      window.location.href = "/Session/Login";
    }

    return { code: 200, data: response.data.data };

  } catch (error) {

    return { code: 403, data: error.response.data.error };


  }
};




export const postReponseregister = async (data, service) => {
  const apiUrl = baseUrl + service;

  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `` // Agrega el token de acceso como parte del encabezado
      }
    });

    return { code: 200, data: response.data };

  } catch (error) {

    return { code: 403, data: error.response.data.error };


  }
};

export const postsingle = async (data, service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    return response.data[0];
  } catch (error) {

  }
};

export const postAnyresponse = async (data, service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    return response.data;
  } catch (error) {

  }
};

export const postsinglenoauth = async (data, service) => {
  const apiUrl = baseUrl + service;

  try {
    const response = await axios.post(apiUrl, data);

    return response.data;
  } catch (error) {

  }
};

export const  postmodallogin = async (data, service, handleAuthError) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleAuthError();
    }
    throw error;
  }
};


export const postarray = async (data, service) => {
  const apiUrl = baseUrl + service;
  var accessToken = accestokenvalid();
  try {
    const response = await axios.post(apiUrl, data, {
      headers: {
        Authorization: `${accessToken}` // Agrega el token de acceso como parte del encabezado
      }
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
      window.location.href = "/Session/Login";
    }
  }
};

export const postarraynoauth = async (data, service, errorreponse) => {
  const apiUrl = baseUrl + service;
  try {
    const response = await axios.post(apiUrl, data);

    return response.data;
  } catch (error) {
    if (error.response) {
      // Si hay una respuesta del servidor con un código de estado
      const statusCode = error.response.status;

      if (statusCode === 403) {
        // Manejar el error de Prohibido (Forbidden)
        // Puedes mostrar un mensaje al usuario o tomar otras acciones
        errorreponse(error.response.data)

      } else if (statusCode === 404) {
        // Manejar el error de No encontrado (Not Found)
        // Puedes mostrar un mensaje al usuario o tomar otras acciones

      } else {
        // Otros códigos de estado pueden ser manejados aquí

      }
    } else if (error.request) {
      // Si no se recibe respuesta del servidor

    } else {
      // Otros errores

    }
  }
};



export const postarraynoauthg = async (data, service) => {
  const apiUrl = baseUrl + service;
  try {
    const response = await axios.post(apiUrl, data);

    return response.data;
  } catch (error) {
    if (error.response) {
      // Si hay una respuesta del servidor con un código de estado
      const statusCode = error.response.status;

      if (statusCode === 403) {
        // Manejar el error de Prohibido (Forbidden)
        // Puedes mostrar un mensaje al usuario o tomar otras acciones


      } else if (statusCode === 404) {
        // Manejar el error de No encontrado (Not Found)
        // Puedes mostrar un mensaje al usuario o tomar otras acciones

      } else {
        // Otros códigos de estado pueden ser manejados aquí

      }
    } else if (error.request) {
      // Si no se recibe respuesta del servidor

    } else {
      // Otros errores

    }
  }
};