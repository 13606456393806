import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import ItemProductBono from '../layouts/ItemProductBono';
import CardComponentVerticalsmall from '../layouts/CardComponentVerticalsmall';
import TabLayout from '../layouts/TabLayout';
import ItemProductEcommerce from '../layouts/ItemProductEcommerce';
import ColorPages from '../layouts/Colorpages';

const Didiproducto = () => {
    const navigate = useNavigate();
    const gobeneficios = (transactionId) => {
        // Realiza la acción deseada al hacer clic en el elemento
        navigate(`/home/Endbeneficios`);
    };


    const [activeTabs, setActiveTabs] = useState([0]);

    const handleTabClick = (index) => {
        if (activeTabs.includes(index)) {
            setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
        } else {
            setActiveTabs([...activeTabs, index]);
        }
    };

    const data = [
        {
            id: 1,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/royalfilms/confiteria-combo-1.png',
            name: 'Cinelandia',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        },
        {
            id: 2,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/popsy/popsy-maxicono-plus.PNG',
            name: 'Popsy',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        },
        {
            id: 3,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/royalfilms/confiteria-combo-1.png',
            name: 'Cinelandia',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        },
        {
            id: 4,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/popsy/popsy-maxicono-plus.PNG',
            name: 'Popsy',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        }, {
            id: 5,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/royalfilms/confiteria-combo-1.png',
            name: 'Cinelandia',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        },
        {
            id: 6,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/popsy/popsy-maxicono-plus.PNG',
            name: 'Popsy',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        }, {
            id: 7,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/royalfilms/confiteria-combo-1.png',
            name: 'Cinelandia',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        },
        {
            id: 8,
            image: 'https://fleksirewards.com/images/productos-aliados/aliado/popsy/popsy-maxicono-plus.PNG',
            name: 'Popsy',
            background: '#f0f0f0',
            price: "$35.000",
            subtitle: "Bono de descuento",
            cash: "Hasta 5% Cashback"
        }
    ];


    return (
        <div>
                 <ColorPages />
            <HeaderTitle title="" />
            <div className='page '>
                <div className='mt-2 center' >
                    <img
                        src={process.env.PUBLIC_URL + '/images/deleteme13.png'}
                        alt="Slider 1"

                        style={{ width: '100%', height: 'auto' }}
                    />
                </div>

                <div className='center'>
                    <img
                        src={process.env.PUBLIC_URL + '/images/deleteme13.png'}
                        alt="Slider 1"

                        style={{ width: '50px', height: 'auto' }}
                    />

                    <img
                        src={process.env.PUBLIC_URL + '/images/deleteme13.png'}
                        alt="Slider 1"
                        className='m-2'
                        style={{ width: '50px', height: 'auto' }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + '/images/deleteme13.png'}
                        alt="Slider 1"

                        style={{ width: '50px', height: 'auto' }}
                    />

                </div>

                <div className='px-4'>
                    <div className='mt-2 col-12 row '>
                        <div className='col'>
                            <p className='inter_18_600 left gray-dark'>Airpods Pro</p>
                        </div>
                    </div>
                    <div className=' col-12 mt-2'>
                        <p className='inter_10_400 color-gray-light left'>SKU AXNJS58348273</p>
                    </div>


                    <div className='row m-0  mt-3'>
                        <div className='col-4 '>
                            <p className='inter_18_600 left gray-dark'>$ 798.989</p>
                        </div>
                        <div className='col d-inline center p-0 m-0'>
                            <img
                                src={process.env.PUBLIC_URL + '/images/discount.png'}
                                style={{ width: '20px', height: '14px' }}
                            />
                            <div className='inter_14_700 left  color-green ml-1'>7% Cashback devuelto</div>
                        </div>
                    </div>

                    <div className='center mt-5'>
                        <button type="button" onClick={() => gobeneficios(1)} className="button-primary">Comprar ahora</button>
                    </div>


                    <div className="tab-layout mt-5">
                        <div className="tab-navigation">
                            <ul>
                                <TabLayout
                                    title="Beneficios"
                                    description="Contenido del Tab 1 - Lorem ipsum dolor sit amet"
                                    active={activeTabs.includes(0)}
                                    handleTabClick={() => handleTabClick(0)}
                                />
                                <TabLayout
                                    title="Términos y condiciones"
                                    description="Contenido del Tab 2 - Lorem ipsum dolor sit amet"
                                    active={activeTabs.includes(1)}
                                    handleTabClick={() => handleTabClick(1)}
                                />


                            </ul>
                        </div>
                    </div>
                    
                    <ItemProductEcommerce title="Productos que te podrían gustar" data={data} />
                </div>

            </div>


        </div>
    );
};

export default Didiproducto;
