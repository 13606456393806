import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import ImageComponent from "./ImageComponent";
import ScrollContainer from "../utils/ScrollContainer";

const ItemProduct = ({ title, data }) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  if (!data || data.length === 0) {
    return null;
  }

  const handleCardClick = (code, id_type_of_shops) => {
    if (isDragging) {
      return;
    }
    const randomNumber = Math.floor(Math.random() * 1000);

    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate(`/home/EcommerceProducto?code=${code}&rand=${randomNumber}`);
      window.location.reload();
    }
    if (id_type_of_shops === 3) {
      navigate(`/Bono/${code}&rand=${randomNumber}`);
      window.location.reload();
    }
    if (id_type_of_shops === 2) {
      navigate(`/home/TiendaProductoFisico?code=${code}&rand=${randomNumber}`);
      window.location.reload();
    }
    if (id_type_of_shops === 7) {
      navigate(`/home/ProductoExternal?code=${code}&rand=${randomNumber}`);
      window.location.reload();
    }
  };
  const text_color_percentage = localStorage.getItem("text_color_percentage");
  return (
    <div className=" mt-0 pt-0">
      {data.length > 0 && (
        <div className="inter_18_gray mb-2 left ml-2">{title}</div>
      )}
      <ScrollContainer onDragChange={setIsDragging}>
        <div style={{ height: "auto", width: "auto", display: "inline-flex" }}>
          {data.map((item) => (
            <div
              className=" ml-4  mr-2 card_back center"
              key={item.id_fleksi_products}
              onClick={() =>
                handleCardClick(item.id_fleksi_products, item.id_type_of_shops)
              }
            >
              <div
                className="card-products center"
                style={{ backgroundColor: item.background }}
              >
                {[6, 1, 4, 5].includes(item.id_type_of_shops) && (
                  <>
                    <span className="overlay-text-type  bg-red color-white">
                      E-commerce
                    </span>
                  </>
                )}

                {item.id_type_of_shops === 3 && (
                  <>
                    <span className="overlay-text-type bg-yellow gray-dark">
                      Bonos
                    </span>
                  </>
                )}

                {item.id_type_of_shops === 2 && (
                  <>
                    <span className="overlay-text-type  bg-tienda color-white">
                      Tienda
                    </span>
                  </>
                )}

                {item.discount !== "" && item.discount > 0 && (
                  <>
                    <span className="overlay-promo-text color-white">
                      {item.discount}% Descuento
                    </span>
                  </>
                )}

                <div className="img-top-round">
                  <ImageComponent
                    src={item.image}
                    alt={item.title}
                    defaultSrc={
                      process.env.PUBLIC_URL + "/images/defaultimage.png"
                    }
                    cssclass={"img-resposive-adjust"}
                  />
                </div>
              </div>
              <div className="container-text-prduct mx-auto">
                <span
                  className="inter_14_600 gray-dark mt-2 px-2 w-100 left rm-2 title-desk"
                  style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.ecommerce}
                </span>
                <span
                  className="inter_14_600 gray-dark mt-2 px-2 w-100 left rm-5   "
                  style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.title}
                </span>
                <span
                  className="inter_12 gray-dark pb-md-3  px-2  w-100 left rm-5 description-desk"
                  style={{
                    maxWidth: "200px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.subtitle}
                </span>

                <span className="inter_12 gray-dark px-2 w-100 left rm-2 mt-md-2 d-flex justify-content-start  ">
                  {numeral(item.valor_de_venta).format("0") !== "0" ? (
                    <div>
                      {numeral(item.valor_de_venta).format("$0,0")} pesos
                    </div>
                  ) : (
                    <div style={{ color: "white" }}>.</div>
                  )}
                </span>
                <span
                  className="inter_14_600_green px-2  w-100 rm-2 mb-md-2"
                  style={{ color: text_color_percentage }}
                >
                  {" "}
                  {item.shop_text_points}
                </span>
              </div>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default ItemProduct;
