import React, { useEffect } from 'react';

const LinkOpener = () => {
    useEffect(() => {
        const link = localStorage.getItem('link');
        if (link) {
            window.open(link, '_blank');
        }
    }, []);

    return <div>Opening link...</div>;
};

export default LinkOpener;