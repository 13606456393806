import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import CardComponent from "../layouts/CardComponent";
import numeral from "numeral";
import { getsingle, postarray } from "../../apiService";
import { useNavigate } from "react-router-dom";
import CardComponentBilletera from "../layouts/CardComponentBilletera";
import CardsBono from "../layouts/CardsBono";
import ColorPages from "../layouts/Colorpages";

const MiBilletera = () => {
  const [userPoints, setUserPoints] = useState({ total: 0, name: "" });

  let navigate = useNavigate();
  const goredempoints = () => {
    navigate("/Home/Solicitarpuntos");
  };
  const goConsultPoints = () => {
    navigate("/Home/HistorialPuntos");
  };

  const fetchData = async () => {
    try {
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
    } catch (error) {
      console.error(error);
    }

    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div >
      <ColorPages />
      <div>
        <Headerback title="Mi Billetera" />
      </div>
      <div className="container mx-auto content-container">
        <div>
          <div className="content-card">
            <CardComponent
              puntosTexto="Mis puntos"
              puntos={numeral(userPoints.total).format("0,0")}
              usuario={userPoints.name}
            />
          </div>
        </div>
        <div className="cardBilletera mt-4">
          <div className="content">
            <img
              src="/images/money.png"
              alt="money icon"
              className="icon"
              width="60px"
            />
            <div className="text">Convertir puntos en dinero</div>
          </div>
          <button className="convert-button" onClick={goredempoints}>
            Convertir
          </button>
        </div>
        <div class="card_gray_light mt-4 py-4">
          <div class="content">
            <div>
              <p class="inter_14_600  left">Recuerda</p>
            </div>
            <div>
              <p class="text  left">1 PUNTO FLEKSI = 1 PESO COP</p>
            </div>
            <div class="content-points">
                <div class="inter_14_600  ">Puntos por confirmar:</div>
              <div class="point-row">
              <div class="inter_14_600 ">0 puntos</div>
              <button class="btn-consultar" onClick={goConsultPoints}>Consultar</button>
              </div>
            </div>
          </div>
        </div>
        <CardComponentBilletera showMessage={true}/>      
      </div>
        <div>
          <div className=" mt-3 pt-2 inter_18_gray left ml-2 ">
            <span> Bonos</span>
          </div>
        </div>
        <CardsBono />
    </div>
  );
};

export default MiBilletera;
