import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const PinModal = ({ show, onHide, pin, expirationTime }) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (expirationTime) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const timeRemaining = Math.max(0, expirationTime - now);
        setTimeLeft(timeRemaining);
        if (timeRemaining === 0) {
          clearInterval(interval);
          onHide();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [expirationTime, onHide]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
    <Modal show={show} onHide={onHide} centered className="pin-modal modal-responsive d-block d-md-none" >
      <Modal.Header closeButton >
        <Modal.Title>Clave Dinámica</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mt-3">
          <h4>Tu Clave es: {pin}</h4>
          <p>Se restablecerá en: {formatTimeLeft(timeLeft)}</p>
        </div>
      </Modal.Body>
    </Modal>
     <Modal show={show} onHide={onHide} centered className="pin-modal d-none d-md-block ">
     <Modal.Header closeButton >
       <Modal.Title>Clave Dinámica</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <div className="text-center mt-3">
         <h4>Tu Clave es: {pin}</h4>
         <p>Se restablecerá en: {formatTimeLeft(timeLeft)}</p>
       </div>
     </Modal.Body>
   </Modal>
   </>
  );
};

export default PinModal;
