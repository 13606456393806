import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CategorySectionEcommerce from '../layouts/CategorySectionEcommerce';
import ItemProductEcommerce from '../layouts/ItemProductEcommerce';
import HeaderEcommerce from '../layouts/HeaderEcommerce';
import ItemComerciosEcommerce from '../layouts/ItemComerciosEcommerce';
import { postarray} from '../../apiService';
import ColorPages from '../layouts/Colorpages';
import LoadingSpinner from '../layouts/LoadingSpinner';
import SubCategorySection from '../layouts/SubCategorySection';
import ItemProduct from '../layouts/ItemProduct';
import ItemComercios from '../layouts/ItemComercios';
import HeaderCategory from '../layouts/HeaderCategory';

const Subcategory = () => {
   let navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(true);
   const [categories, setCategories] = useState([]);
    const [comercios, setComercios] = useState([]);
    const [comerciostwo, setComerciostwo] = useState([]);
    const [cashbackespecial, setCashbackespecial] = useState([]);
    const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);   
    const [destacados, setDestacados] = useState([]);
    const [destacadostwo, setDestacadostwo] = useState([]);   
    const [lomasnuevo, setLomasnuevo] = useState([]);
    const [lomasnuevotwo, setLomasnuevotwo] = useState([]);   

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id_sub_categories_fleski = queryParams.get('id_sub_categories_fleski');
    
    const clickload = (code) => {     
        const randomNumber = Math.floor(Math.random() * 1000);
        navigate(`/home/Subcategory?id_sub_categories_fleski=${code}&rand=${randomNumber}`);
        window.location.reload();
      };

      const fetchData = async () => {
        try {
  //subcategoriasecommerce funciona para todos los comercios
          const subcategories = await postarray({id_sub_categories_fleski:id_sub_categories_fleski},"subcategoriasfleksi_v3");

         setCategories(subcategories.categories);
         setComercios(subcategories.shops);
         setCashbackespecial(subcategories.cashbackespecial.productsone)
         setCashbackespecialtwo(subcategories.cashbackespecial.productstwo)
          
         setDestacados(subcategories.destacados.productsone)
         setDestacadostwo(subcategories.destacados.productstwo)
          
         setLomasnuevo(subcategories.lomasnuevo.productsone)
         setLomasnuevotwo(subcategories.lomasnuevo.productstwo)
                
         setComercios(subcategories.shops.shops)
         setComerciostwo(subcategories.shops.shopstwo)

         setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
        return () => {}
      };
      
    useEffect(() => {
        fetchData();
      }, []);    
console.log("PRIMEROS",comercios);
console.log("SEGUNDOS",comerciostwo);

   
    return (

        <div>
               <ColorPages />
               <HeaderCategory title="" pathsearch={"searchallproducts"} />
            {isLoading && <LoadingSpinner   />}
            <div className='page'>
              

                <SubCategorySection data={categories} code={id_sub_categories_fleski} clickload={clickload}  />
                <div  style={{ marginTop:"30px" }}  >     
                {comercios.length > 0 && <ItemComercios  title={"Comercios Aliados"}  data={comercios}/>} 
                {comerciostwo.length > 0 && <ItemComercios title="" data={comerciostwo} />}     
                </div>
                <div  style={{ marginTop:"40px" }}>     

                <ItemProduct title="Productos destacados" data={destacados} />
                {destacadostwo.length > 0 && <ItemProduct title="" data={destacadostwo} />}
                </div>
                <div  style={{ marginTop:"40px" }} >
                <ItemProduct title="Cashbacks especiales para ti" data={cashbackespecial} />
                {cashbackespecialtwo.length > 0 && <ItemProduct title="" data={cashbackespecialtwo} />}
                </div>
                <div  style={{ marginTop:"40px" }} >
                <ItemProduct title="Lo mas nuevo" data={lomasnuevo} />
                {lomasnuevotwo.length > 0 && <ItemProduct title="" data={lomasnuevotwo} />}
                </div>

              
            </div>
        </div>
    );
};

export default Subcategory;
