import React from 'react'
import Headerback from '../layouts/Headerback'
import { useNavigate } from "react-router-dom";
import ColorPages from '../layouts/Colorpages';
const MetodosDePago = () => {
    const navigate = useNavigate();
const handleGoAgerarMetodo = () => {
    navigate("/home/AgregarMetodo");
}
  return (
    <div className="container">
      <div>
        <ColorPages />
        <Headerback title="Metodos de Pago" />
      </div>
      <div className="row">
        <div className="col-9 mt-5 inter_20_normal left ml-2 ">
          <span> Tus metodos de pago </span>
        </div>
        <div
          className="col mt-5 inter_14_gray right mr-3"
          
        >
          <img  width="25" height="25" src="https://img.icons8.com/tiny-color/16/add.png" onClick={handleGoAgerarMetodo} alt="add"/>
        </div>
      </div>
      <div className="container ml-2">
        <div className="col-12 row  d-flex ml-4 ">
          <div className="col-3 d-flex align-items-center">
            <img
              width="48"
              height="48"
              src="https://img.icons8.com/color/48/mastercard.png"
              alt="mastercard"
            />
          </div>
          <div className="col inter_14_gray p-0 left mt-2   ">
            <span>Credito **4528</span>
            <p className="inter_12 ml-2 "> Master Card </p>
          </div>
          
        </div>
      </div>
      <hr className="mx-2"
        style={{
          border: "none",
          height: "2px",
          background:
            "linear-gradient(to right, transparent, #53B5F6, transparent)",
        }}/>
      <div className="container ml-2">
        <div className="col-12 row  d-flex ml-4 ">
          <div className="col-3 d-flex align-items-center">
          <img width="48" height="48" src="https://img.icons8.com/fluency/48/visa.png" alt="visa"/>
          </div>
          <div className="col inter_14_gray p-0 left mt-2   ">
            <span>Debito **6745</span>
            <p className="inter_12 ml-2 "> Visa </p>
          </div>
        </div>
      </div>
      <hr className="mx-2"
        style={{
          border: "none",
          height: "2px",
          background:
            "linear-gradient(to right, transparent, #53B5F6, transparent)",
        }}/>
      <div className="container ml-2">
        <div className="col-12 row  d-flex ml-4 ">
          <div className="col-3 d-flex align-items-center">
          <img width="48" height="48" src="https://img.icons8.com/plasticine/100/cash--v2.png" alt="cash--v2"/>
          </div>
          <div className="col inter_14_gray p-0 left mt-2   ">
            <span>Efectivo</span>
            <p className="inter_12 ml-2 "> Efectivo</p>
          </div>
        </div>
      </div>
      <hr className="mx-2"
        style={{
          border: "none",
          height: "2px",
          background:
            "linear-gradient(to right, transparent, #53B5F6, transparent)",
        }}/>
      <div className="row ">
        <div className="col-7 mt-5 inter_18_600 left ml-2 ">
          <span> Metodos de reembolso </span>
        </div>
        <div
          className="col mt-5 inter_14_gray right mr-3"
          
        >
          <img width="30" height="30" src="https://img.icons8.com/fluency/48/forward--v1.png" alt="forward--v1"/>
        </div>
      </div>
      <div className="row">
        <div className="col-7 mt-5 inter_18_600 left ml-2 ">
          <span> Pagos Pendientes</span>
        </div>
        <div
          className="col mt-5 inter_14_gray right mr-3"
          
        >
          <img width="30" height="30" src="https://img.icons8.com/fluency/48/forward--v1.png" alt="forward--v1"/>
        </div>
      </div>
      </div>
      
  )
}

export default MetodosDePago