import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import TabsComponentIntereses from '../layouts/TabsComponentIntereses';
import ColorPages from '../layouts/Colorpages';


const Misintereses = () => {


  const tabsData = [
    {
      title: 'Deportes',
      items: [
        { image: '/images/deleteme8.png', text: 'Texto de la imagen 1' },
        { image: '/images/deleteme9.png', text: 'Texto de la imagen 2' },
        { image: '/images/deleteme10.png', text: 'Texto de la imagen 3' },
        { image: '/images/deleteme11.png', text: 'Texto de la imagen 4' },

      ]
    },
    {
      title: 'Cine y televisión',
      items: [
        { image: '/images/deleteme11.png', text: 'Texto de la imagen 4' },
        { image: '/images/deleteme10.png', text: 'Texto de la imagen 3' },
        { image: '/images/deleteme8.png', text: 'Texto de la imagen 1' },
        { image: '/images/deleteme9.png', text: 'Texto de la imagen 2' }
      ]
    },
    {
      title: 'Música',
      items: [
        { image: '/images/deleteme11.png', text: 'Texto de la imagen 4' },
        { image: '/images/deleteme8.png', text: 'Texto de la imagen 1' },
        { image: '/images/deleteme9.png', text: 'Texto de la imagen 2' },
        { image: '/images/deleteme10.png', text: 'Texto de la imagen 3' }
      ]
    },
    {
      title: 'Lectura',
      items: [
        { image: '/images/deleteme8.png', text: 'Texto de la imagen 1' },
        { image: '/images/deleteme9.png', text: 'Texto de la imagen 2' },
        { image: '/images/deleteme11.png', text: 'Texto de la imagen 4' },
        { image: '/images/deleteme10.png', text: 'Texto de la imagen 3' },

      ]
    }
    // Agrega más objetos de datos para más pestañas
  ];


  return (
    <div>
             <ColorPages />
      <HeaderTitle title="Mis intereses" />
      <div className='page px-4'>
        <TabsComponentIntereses tabsData={tabsData} />
      </div>
    </div>
  );
};

export default Misintereses;
