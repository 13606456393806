import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import QRCode from 'qrcode.react';
import ColorPages from '../layouts/Colorpages';
import Headerback from '../layouts/Headerback';
import { getdata } from '../../apiService';
import NotificationModal from '../layouts/NotificationModal';

const InvitarAmigo = () => {
  const [qrcode, setqr] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const fetchData = async () => {
    try {
      const pc = await getdata("prospectcode");
      setqr(pc.prospectus_code);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const whatsappMessage = `¡Hola! Descarga la app de Fleksi en fleksirewards.com y usa mi código ${qrcode} para registrarte. ¡Ambos ganaremos recompensas exclusivas!`;
  const whatsappLink = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrcode);
    setModalMessage('Código copiado al portapapeles');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ColorPages />
      <Headerback title="Mi código Fleksi" />
      <div className="page">
        <div className="container-amigo ">
          <div>
            <p className='inter_18_gray ms-0 my-4 my-md-2 mb-md-3'>Use este código para invitar amigos y familiares</p>
          </div>
          <div className="row center ">
            <div className="card_gray_light  card_desktop d-flex flex-row justify-content-center  gap-4   text-center">
              <div className="mt-1 mb-1 qrcode-text">
                {qrcode}
              </div>
              <button className="copy-button" onClick={copyToClipboard}>
                <img src ={process.env.PUBLIC_URL + '/images/copiar.png'} alt="Copiar" />
              </button>
            </div>
            <button className="button-primary btn-compartir mt-3" onClick={() => window.open(whatsappLink, '_blank')}>Compartir código</button>
            <p className="inter_14_normal  gray ">
              🎉 ¡Tu oportunidad de brillar en Fleksi! Comparte tu código único con amigos y desbloqueen juntos recompensas exclusivas. Es sencillo: <br /><br />
              1. Toca 'Compartir código' y elige tu método preferido. 📲<br />
              2. Tus amigos se registran en Fleksi con tu código. 🌐<br />
              3. ¡Ambos ganan! Disfruten de beneficios únicos por ser parte de nuestra comunidad. 🥳<br /><br />
              No esperes más, ¡comienza a compartir y ganar hoy!
            </p>
          </div>
        </div>
      </div>
      {showModal && <NotificationModal message={modalMessage} onClose={closeModal} />}
    </div>
  );
};

export default InvitarAmigo;
