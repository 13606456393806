import {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles-comercial.css";

const ContactoComercial = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: true, 
        });
    }, []);
  return (
    <div>
      {/* Navigation */}
      <Navbar expand="lg" className="navbar">
        <Navbar.Brand href="#">
          <img
            src="https://fleksiimg.s3.amazonaws.com/aliados/logos/Logo_fleksi.png"
            alt="Logo"
            className="navbar-brand-img logo-fleksi"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="navbar-nav-right">
            <Nav.Link className="nav-link" href="#nosotros">
              Nosotros
            </Nav.Link>
            <Nav.Link className="nav-link" href="#comercios">
              Comercios
            </Nav.Link>
            <Nav.Link className="nav-link" href="#contacto">
              Contáctanos
            </Nav.Link>
            {/*<Nav.Link className="nav-link" href="https://fleksipartners.com">Ingresar</Nav.Link>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Personal Presentation Section */}
      <section id="presentation" className="presentation-section">
        <h1 className="section-title mb-4">Contacto Comercial</h1>
        <Container className="text-center d-flex flex-row align-items-center justify-content-around">
          <div className="row gap-5 align-items-center">
          <div className="col-md-6 col-sm">
            <img
              src={process.env.PUBLIC_URL + "/images/PerfilComercial.jpeg"}
              alt="Adriana Rojas Dias"
              className="rounded-circle mb-3 "
            />
          </div>
          <div className="col left card-info d-flex flex-column align-items-start mx-2  p-md-5">
            <h1 className="poppins_28">Adriana Rojas Diaz</h1>
            <h2>Gerente Comercial</h2>
            <p className="fs-4 ">
              Email:{" "}
              <a href="mailto:adriana@fleksirewards.com">
                adriana@fleksirewards.com
              </a>
            </p>
            <p  className="fs-4">
              Teléfono: <a href="tel:3153086627">3153086627</a>
            </p>
            <p className="fs-4">Fleksi SAS</p>
          </div>
          </div>
        </Container>
      </section>

      {/* Video Section */}
      <section id="video" className="video-section mb-4">
        <Container>
          <h2 className="section-title" data-aos="fade-up">
            Descubre Más
          </h2>
          <div
            className="video-wrapper"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <iframe
              width="100%"
              height="600"
              src="https://www.youtube.com/embed/Weikr5EnTOw"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Container>
      </section>

      {/* About Section */}
      <section id="nosotros" className="about-section">
        <Container>
          <Row>
            <Col className="text-content">
              <h2 className="section-title">¿Qué es Fleksi?</h2>
              <p className="text-justify poppins_24">
                Fleksi es una súper App que reúne las mejores marcas de
                diferentes categorías para brindar a las personas un entorno
                completo en el que realicen sus compras y reciban dinero de
                vuelta, descuentos y beneficios en un solo lugar.
              </p>
            </Col>
            <Col className="image-content">
              <div className="image-wrapper_nosotros">
                <img
                  src="images/_prueba.png"
                  alt="Qué es Fleksi"
                  className="img-empresa"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section id="comercios" className="features-section">
        <div className="container">
          <h2 className="section-title">Fleksi para comercios</h2>
          <p className="poppins_28"> Tu marca necesita una plataforma fleksible que se adapte a las necesidades de tu cliente y a los objetivos de
            tu compañía.</p>
          <p className="poppins_22"> Esto es lo que podrás hacer con nosotros:</p>
          <div className="row mt-5">
            <div className="col-md col-lg-3 mb-3">
              <div className="card feature-box" >
                <div className="  d-flex align-items-center flex-column">
                <i className="fas fa-user mb-4 mt-2 mt-sm-0 icon-outside" style={{ fontSize: '30px' }}></i>
   
                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">Conoce a tu shopper</h4>
                    <p className="text-center poppins_18_semibold" >Conoce las preferencias y hábitos de tus clientes a través de su comportamiento dentro de la app de Fleksi y mantente actualizado sobre sus constantes cambios.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 ">
              <div className="card feature-box">
                <div className=" d-flex align-items-center flex-column">
                  
                <i className="fas fa-chart-line mb-4 mt-2 mt-sm-0  icon-outside" style={{ fontSize: '30px' }}></i>
                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">Personalización por hábitos de compra</h4>
                    <p className="text-center poppins_18_semibold" >Podrás enviar campañas de recomendación de productos y promociones, personalizarlas con tu identidad, y seleccionar el número de clientes nuevos y recurrentes.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 ">
              <div className="card feature-box">
                <div className="d-flex align-items-center flex-column">
                <i className="fas fa-shopping-cart  mt-2 mt-sm-0  mb-4 icon-outside" style={{ fontSize: '30px' }}></i>

                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">Nuevo canal de venta</h4>
                    <p className="text-center poppins_18_semibold" >Con Fleksi, aumentarás tus ventas, ya que dirigimos usuarios segmentados a tus canales de venta.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3">
              <div className="card feature-box">
                <div className=" d-flex align-items-center flex-column">
                <i className="fas fa-user-friends mt-2 mt-sm-0  mb-4 icon-outside" style={{ fontSize: '30px' }}></i>
                  <div>
                    <h4 className="poppins_22 text-center pb-2 pt-4">Canal de convenios</h4>
                    <p className="text-center poppins_18_semibold" >Con Fleksi, podrás acceder a convenios exclusivos que te permitirán comunicarte directamente con clientes potenciales, lo que posibilita una mayor conversión y un mejor posicionamiento.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="poppins_28"  style={{ marginTop:15 }}>Conecta con tu cliente premiándolo con lo que de verdad es importante para él:</p>
          <div className="row justify-content-center" style={{ paddingTop:30 }}>
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div className="card feature-box" style={{ minHeight: '370px' }}>
                <div className=" d-flex align-items-center flex-column justify-content-center">
                <i className="fas fa-money-bill-wave mt-2 mt-sm-0  mb-4 icon-outside" style={{ fontSize: '30px' }}></i>

                  <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">CASHBACK</h4>
                    <p className="text-center poppins_18_semibold" >Devolución en dinero de un porcentaje del valor de la compra realizada por el cliente.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div className="card feature-box" style={{ minHeight: '370px' }}>
                <div className="d-flex align-items-center flex-column justify-content-center">
                <i className="fas fa-gift mt-2 mt-sm-0 mb-4 icon-outside" style={{ fontSize: '30px' }}></i>
                    <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">BENEFICIOS</h4>
                    <p className="text-center poppins_18_semibold" >Premia su fidelidad con beneficios exclusivos dentro de Fleksi, de acuerdo con lo que te resulte más beneficioso como comercio.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md col-lg-3 mb-3 mx-3">
              <div className="card feature-box" style={{ minHeight: '370px' }}>
                <div className=" d-flex align-items-center flex-column justify-content-center">
                <i className="fas fa-tags mt-2 mt-sm-0 mb-4 icon-outside" style={{ fontSize: '30px' }}></i>
                  <div>
                    <h4 className="poppins_22 pb-2 pt-4 text-center">PROMOCIONES</h4>
                    <p className="text-center poppins_18_semibold" >Crea promociones personalizadas premiando los hábitos de compra de los clientes que más te interesan como marca.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="poppins_22">Tú eliges la estrategia, Fleksi la comunica por ti.</p>
        </div>
      </section>

      {/* Contact Section */}
      {/* Contact Section */}
      <section id="contacto" className="whatsapp-section">
  <div className="container">
    <h2 className="section-title">Contáctanos</h2>
    <p className="poppins_22">Transformemos juntos la forma de premiar</p>
    <a 
      href="https://wa.me/3239003988" 
      className="whatsapp-button" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      Chatea con nosotros en WhatsApp
    </a>
  </div>
</section>

      {/* Footer */}
   <footer className="text-center py-4 fs-6 footer">
        <div className="container d-none d-sm-block d-lg-none d-md-block">
          <div className="row row-cols-1 row-cols-lg-3" style={{ color: '#02b8fc' }}>
            <div className="col">
              <p className="text-muted my-2">Sitios Oficiales</p>
            </div>
            <div className="col">
              <ul className="list-inline my-2">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/fleksirewards" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/facebook-40x40.png" alt="Facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/fleksirewards/" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/instagram-40x40.png" alt="Instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/fleksirewards/" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/linkedin-40x40.png" alt="LinkedIn" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.youtube.com/channel/UC6KblGTP3NjdsWaiKoFmIIg" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/youtube-40x40.png" alt="YouTube" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col">
              <p className="text-muted my-2 derechos-fleksi">© Fleksi 2022. Todos los derechos reservados</p>
            </div>
            <div className="col">
              <ul className="list-inline my-2 footer-links">
                <li className="list-inline-item">
                  <a href="politicadeprivacidadpre-registro.html">Política de Privacidad</a>
                </li>
                <li className="list-inline-item">
                  <a href="terminosycondiciones.html">Términos y Condiciones</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://fleksirewards.tawk.help">Soporte</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container d-none d-sm-none d-md-block d-lg-block fs-2">
          <div className="row row-cols-1 row-cols-lg-3" style={{ color: '#02b8fc' }}>
            <div className="col-lg-8">
              <p className="text-muted my-2 derechos-fleksi">© Fleksi 2022. Todos los derechos reservados</p>
              <ul className="list-inline my-2 footer-links">
                <li className="list-inline-item">
                  <a href="politicadeprivacidadpre-registro.html">Política de Privacidad</a>
                </li>
                <li className="list-inline-item">
                  <a href="terminosycondiciones.html">Términos y Condiciones</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://fleksirewards.tawk.help">Soporte</a>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="list-inline my-2">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/fleksirewards" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/facebook.png" alt="Facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/fleksirewards/" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/instagram.png" alt="Instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/fleksirewards/" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/linkedin.png" alt="LinkedIn" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.youtube.com/channel/UC6KblGTP3NjdsWaiKoFmIIg" target="_blank" rel="noopener noreferrer">
                    <img src="https://fleksirewards.com/images/social/youtube.png" alt="YouTube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ContactoComercial;
