import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postarray } from "../../apiService";
import ImageComponent from "./ImageComponent";
import { Link } from "react-router-dom";
import useCart from "../utils/useCart";
import PinModal from "./PinModal";
import PinButton from "./PinButton";

const HeaderBusquedaHome = ({ title }) => {
  const navigate = useNavigate();
  const { addToCart, total_cart } = useCart();
  const [totalcart, setTotalcart] = useState(0);
  const logo_inferior_card = localStorage.getItem("logo_inferior_card");
  useEffect(() => {
    setTotalcart(total_cart());
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCardClick = (code, id_type_of_shops) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    setShowSuggestions(false);

    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate(`/home/EcommerceProducto?code=${code}&rand=${randomNumber}`);
    }
    if (id_type_of_shops === 3) {
      navigate(`/Bono/${code}&rand=${randomNumber}`);
    }
    if (id_type_of_shops === 2) {
      navigate(`/home/TiendaProductoFisico?code=${code}&rand=${randomNumber}`);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultados, setResultados] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    try {
      const response = await postarray(
        { searchTerm: value },
        "searchallproducts"
      );
      setSuggestions(response.results);
      setShowSuggestions(value !== "");
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list mt-2">
        {suggestions.map((suggestion, index) => (
          <li
            className="left"
            key={index}
            onClick={() =>
              handleCardClick(
                suggestion.id_fleksi_products,
                suggestion.id_type_of_shops
              )
            }
          >
            <ImageComponent
              src={suggestion.image}
              alt={suggestion.title}
              defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
              cssclass={"img-search"}
            />

            <span
              className="inter_12_500 ml-2"
              style={{ marginTop: "15px", position: "absolute" }}
            >
              {" "}
              {suggestion.title}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const handleGogocart = () => {
    navigate("/home/Cart");
  };

  return (
    <div
      className="w-100 bg-white pb-md-0 "
      style={{ position: "fixed", top: 0, zIndex: 999, paddingBottom: '1rem' }}
    >
      <div className="banner ">
        <div className="left-div pt-3">
          <Link to="Menu" className="link-no-underline">
            <img
              src={process.env.PUBLIC_URL + "/images/main.png"}
              alt="Slider 1"
              style={{ width: "28px", height: "auto" }}
              className="hamburger-icon"
            />
          </Link>
          
        </div>
        <div className="logo-left-div ">
          <img
            src={logo_inferior_card}
            alt="Logo"
            className="logo-icon"

            // Añadir clase para el logo
          />
          
        </div>
        

        <div className="center-div gap-3">
          <div className="search-container ">
          
            <span className="search-icon">
              <img
                src={process.env.PUBLIC_URL + "/images/buscar.png"}
                alt="Buscar"
              />
            </span>
            <input
              style={{ marginTop: "5px", paddingLeft: "40px" }}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              className="form-input form_rounded"
              placeholder="Buscar aquí"
            />
          </div>
          
        </div>
        <div className="right-div  pt-3 ms-3" style={{ width: "80px" }}>
          <Link to="Menu">
            <svg
              version="1.1"
              viewBox="0 0 1600 1600"
              width="28px"
              height="auto"
              xmlns="http://www.w3.org/2000/svg"
              className="user-icon"
            >
              <path
                transform="translate(769,250)"
                d="m0 0h59l21 2 28 5 26 7 20 7 21 9 16 8 20 11 24 16 16 12 14 12 7 7 8 7 7 8 11 12 11 14 13 18 11 18 12 23 9 19 9 23 10 37 5 26 2 19 1 19v15l-2 34-3 19-5 23-9 32-8 20-9 20-10 20-12 19-10 15-18 24h-2l-2 4-7 7-6 7-8 7-9 9-14 11-14 8-5 3h-2v2l9 3 18 10 22 12 23 14 22 15 13 10 14 11 10 9 11 9 7 7 8 7 12 12 7 8 11 12 8 10 10 13 13 18 15 23 13 24 9 17 12 27 11 29 8 27 7 28 5 28 6 43 3 23v7l-3 3-6 2-85 2-5-3-3-4-2-11-4-36-5-28-9-36-8-24-9-22-12-25-11-20-18-27-10-13-9-11-18-20-10-10-8-7-14-12-16-13-17-12-9-6-3 1-5 11-9 16-10 15-7 9-11 13-16 16-11 9-15 11-17 10-19 9-27 9-25 6-27 3h-27l-26-3-20-5-18-6-24-11-14-8-14-10-13-10v-2l-4-2-20-20-11-14-12-17-9-15-7-10-4-4-6 3-12 9-14 11-13 11-11 9-20 20-7 8-12 14-9 11-11 15-9 14-8 14-9 16-11 23-11 29-7 22-7 31-5 28-4 33-1 10-4 6-1 1h-80l-13-1-3-3v-14l5-47 6-37 7-28 10-33 9-24 10-23 8-16 13-24 13-21 10-15 7-10 10-13 12-14 6-8h2l2-4 15-16 11-11 8-7 10-9 11-9 8-7 13-10 17-12 22-14 21-12 27-14 8-4-2-4-21-16-13-12-11-11-2-1v-2h-2l-7-8-12-14-12-16-18-27-12-22-8-16-10-25-9-27-6-27-4-28-2-31v-18l2-24 5-30 8-31 8-24 7-16 12-25 9-16 7-11 8-12 10-14 13-15 16-17 14-14 10-8 12-10 16-11 18-11 22-12 15-7 20-8 21-7 30-7 25-4zm7 100-17 2-24 5-30 10-23 11-18 11-10 7-13 10-12 11-8 7-7 8-9 10-10 14-12 20-7 12-11 25-7 21-6 31-2 19v33l3 25 6 27 6 17 11 25 10 18 10 15 11 14 9 10 6 7h2l1 3 8 7 13 11 14 10 22 13 16 8 19 8 20 6 25 5 16 2h41l23-3 26-6 18-6 21-9 25-14 19-13 14-11 13-12 15-16 10-14 10-16 10-17 11-25 6-19 5-23 3-25v-35l-3-28-7-30-7-20-13-27-10-17-10-14-13-16-18-18-11-9-15-11-13-8-14-8-16-8-20-8-22-6-20-4-19-2zm4 600-27 2-32 5-22 5-14 5-1 6 9 16 11 15 5 6 8 7 11 9 13 8 17 8 19 5 8 1h30l16-3 19-7 15-8 13-10 12-11 7-7 13-18 4-9v-4h-2v-3h-2l-1-3-8-3-36-7-29-3-31-2z"
              />
              <path transform="translate(590,1017)" d="m0 0" />
              <path transform="translate(585,1016)" d="m0 0" />
              <path transform="translate(912,973)" d="m0 0" />
            </svg>
          </Link>
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={handleGogocart}
          >
            <svg
              version="1.1"
              viewBox="0 0 1600 1600"
              width="28px"
              height="auto"
              xmlns="http://www.w3.org/2000/svg"
              className="cart-icon"
            >
              <path
                transform="translate(91,63)"
                d="m0 0h180l41 3 23 4 17 5 23 11 13 9 10 8v2l4 2 7 8 10 13 10 18 11 23 10 28 10 35 21 87 11 44 20 85 6 21 3 9 970 1 17 1 12 3 8 5 4 4 2 8-1 15-3 15-11 33-11 29-15 41-13 34-14 37-11 29-9 24-21 55-11 29-20 53-17 44-7 20-10 26-16 43-15 39-11 29-14 34-7 12-6 7-5 2-55 1h-266l-279 1 6 19 16 64 13 45 7 17 8 15 8 10 12 8 12 5 17 4 17 2 38 1h357l113 1 16 2 6 4 6 12 9 27 17 33 7 19 3 14 1 9v27l-3 15-8 18-11 20-6 8-11 13-10 9-14 9-14 7-25 9v2h-55v-2h-2v-2l-10-3-23-11-16-11-12-11-11-13-10-16-7-16-5-15-2-9-1-19 2-21 7-25 5-14 2-5h-193l10 22 4 13 3 21v29l-3 14-8 18-10 18-9 12-12 13-14 11-18 10-27 10-1 2h-55l-4-4-21-9-13-7-17-12-9-9-13-17-9-16-8-21-4-17v-35l5-21 11-21 7-11 3-7-1-4-5-4-13-9-9-7-6-5-7-8-14-21-9-19-10-27-9-31-8-29-16-64-11-46-6-24-18-73-24-97-17-67-9-37-20-81-9-37-32-128-28-113-20-81-17-69-14-56-9-34-9-26-8-18-9-16-11-13-10-7-16-7-12-3-27-3-17-1h-137l-4 2-4 19-10 14-12 14-10 7-9 4-9 2h-21l-13-3-12-6-10-9-9-10-9-11-1-1v-44l9-11 8-10 9-9 13-8 10-3 12-1zm566 479-121 1 11 44 18 73 27 110 47 188 14 59 9 38 8 30 1 2 5 1 569-1 5-2 4-5 11-28 9-25 6-15 15-40 30-79 13-35 10-26 8-21 16-43 7-18 14-37 19-50 13-35 11-28 9-23 10-29v-5l-218-1zm162 866-13 4-16 10-8 8-9 16-4 11-2 10v8l3 14 4 10 11 16 9 8 12 7 15 4 16 1 13-3 16-8 10-8 8-9 8-16 2-8v-21l-3-14-6-11-11-13-11-8-10-5-14-3zm415 0-10 3-14 8-10 9-7 10-6 13-3 11v16l4 16 6 12 8 10 5 5 11 7 11 4 15 3h9l11-2 16-8 11-8 9-10 7-14 3-13v-12l-2-15-4-11-6-9-9-10-13-9-14-5-7-1zm-431 189m416 0m-413 1 4 1zm417 0 4 1z"
              />
              <path transform="translate(859,1598)" d="m0 0 3 2-4-1z" />
              <path transform="translate(791,1598)" d="m0 0 3 1-3 1z" />
              <path transform="translate(1275,1599)" d="m0 0 3 1z" />
              <path transform="translate(1207,1598)" d="m0 0h2l-1 2z" />
            </svg>
            <span className="cantidad" id="cantidad">
              {totalcart}
            </span>
          </div>
        </div>
        {renderSuggestions()}
      </div>
      <div className="row d-none d-md-block">
       
        <div className="nav-links">
          <Link className="col" to="/home">
            Home
          </Link>
          <Link className="col" to="/Home/HistorialCompras">
            Historial de compras
          </Link>
          <Link className="col" to="/Home/HistorialPuntos">
            Fleksi puntos
          </Link>
          <Link className="col" to="/Home/DatosBancarios">
            Datos bancarios
          </Link>
          <Link className="col" to="/Home/InvitarAmigo">
            Invitar a un amigo
          </Link>
          <div className="col py-2 d-flex align-items-center" >
          <PinButton/>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default HeaderBusquedaHome;
