import React from "react";
import ImageComponent from "./ImageComponent";

const CardComponentVertical = ({
  usuario,
  apellido,
  cedula,
  puntos,
  puntosTexto,
}) => {
  const superior_card = localStorage.getItem("logo_superior_card");
  const logo_inferior_card = localStorage.getItem("logo_inferior_card");
  const class_img_end_card = localStorage.getItem("class_img_end_card");
  const themecode = localStorage.getItem("themecode");

  return (
    <div className="card_main mt-0 p-2 pb-3" style={{ width: "100%",height:"auto" }}>
      <div className="row p-0 m-0">
        <div className="col-12 pt-3 center">
          <img
            src={superior_card}
            alt="Logo"
            className={class_img_end_card}
            style={{ width: "112px", height: "auto" }}
          />
        </div>
      </div>

      <div className="row p-0 m-0">
        <div className="col-12 pt-5 cardMembresia">
          <div className="inter_30_normal text-uppercase">{usuario}</div>
          <div className="inter_30_normal text-uppercase ml-0 ml-md-2">
            {apellido}
          </div>
        </div>
        <div className="col-12 pt-1 center">
          <div className="inter_20_500 color-white w-100">CC {cedula}</div>
        </div>
      </div>

      <div className="row p-0 m-0">
    <div className="col-12 col-md-6 pb-2 mt-5 d-flex align-items-center ">
        <div>
            <div className="inter_26_normal">{puntosTexto}</div>
            <div className="inter_30_normal">{puntos}</div>
        </div>
    </div>
    <div className="col-12 col-md-4 pt-4 d-flex justify-content-end mr-4   ">
        {themecode !== "Fleksi" && (
            <img
                src={logo_inferior_card}
                alt="Fleksi Logo"
                style={{ width: "76px", height: "auto", objectFit: "contain"}}
            />
        )}
    </div>
</div>

    </div>
  );
};

export default CardComponentVertical;
