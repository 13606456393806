import React, { useEffect, useState } from "react";
import PinModal from "./PinModal";
import { getdata, post } from "../../apiService";
const PinButton = () => {
  const [status, setStatus] = useState("disabled");
  const [pin, setPin] = useState("");
  const [expirationTime, setExpirationTime] = useState(null);
  const [showPinModal, setShowPinModal] = useState(false);
  const fetchPin = async () => {
    try {
      const response = await getdata("getCurrentPin");
      if (response.status === "enabled") {
        setPin(response.pin);
        setExpirationTime(new Date(response.expirationTime).getTime());
        setShowPinModal(true);
      }
    } catch (error) {
      console.error("Error fetching PIN:", error);
    }
  };
  const togglePinStatus = async () => {
    try {
      const newStatus = status === "enabled" ? "disabled" : "enabled";
      const response = await post({ pin_status: newStatus }, "activePin");
      setStatus(newStatus);
    } catch (error) {
      console.error("Error toggling PIN status:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await getdata("getCurrentPin");
      setStatus(response.status);
      if (response.status === "enabled") {
        setPin(response.pin);
        setExpirationTime(new Date(response.expirationTime).getTime());
      }
    } catch (error) {
      console.error("Error fetching PIN:", error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const handleShowPinModal = () => {
    setShowPinModal(true);
  };
  return (
    <div>
      {status === "disabled" ? (
        <button onClick={togglePinStatus} className="btn btn-primary">
          Activar Clave Dinámica
        </button>
      ) : (
        <>
        <button
          onClick={fetchPin}
          className="btn btn-info primary-color-text d-md-none d-block"
          style={{ backgroundColor: "var(--primary-color)" }}
        >
          Clave Dinámica <i className="icon-pin" />
        </button>
        <button
        onClick={fetchPin}
        className="d-md-block d-none flex-colum"
        style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
            color: "black",
            borderRadius: "5px",
            padding: "3px 5px",
            cursor: "pointer",
            
            minWidth: "150px",
            textAlign: "center",
            
          }}
      >
        Clave Dinámica
      </button>
      </>
      )}
      {showPinModal && (
        <PinModal
          show={showPinModal}
          onHide={handleClosePinModal}
          pin={pin}
          expirationTime={expirationTime}
        />
      )}
    </div>
  );
};

export default PinButton;
