import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';

const EcommerceComercio = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Establecer la URL con el valor de code
    const url = code;
    setLoading(false);
    // Redirigir automáticamente después de 2 segundos
    setTimeout(() => {
      window.open(url, '_blank');
    }, 2000); // 2 segundos
  }, [code]);

  return (
    <div className='App m-0 p-0 bg-primary-color' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <ColorPages />
      <div className='w-100 bg-primary-color ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
        <div className="banner">
          <div className="left-div" onClick={handleGoBack}>
            <img
              src={process.env.PUBLIC_URL + '/images/img-back-white.png'}
              alt="Slider 1"
              style={{ width: '28px', height: 'auto' }}
            />
          </div>
          <div className="center-div align-items-center">
            <div className="header-title left w-100"></div>
          </div>
          <div className="right-div"></div>
        </div>
      </div>

      {loading ? (
        <div className='mt-3 center d-block m-0 p-0'>
          <p className='w-100 color-white inter_18 pb-3 px-3'>ESTAS SIENDO REDIRIGIDO AL COMERCIO PARA FINALIZAR TU COMPRA</p>
        </div>
      ) : (
        <div>
          <p className='w-100 color-white inter_18 pb-3 px-3'>Serás redirigido al enlace externo en 2 segundos...</p>
        </div>
      )}
    </div>
  );
};

export default EcommerceComercio;
