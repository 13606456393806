import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postarray } from '../../apiService';
import ImageComponent from './ImageComponent';


const HeaderTiendaFisica = ({ title }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCardClick = (code) => {
 
    const randomNumber = Math.floor(Math.random() * 1000);
    setShowSuggestions(false)
    navigate(`/home/TiendaProductoFisico?code=${code}&rand=${randomNumber}`);
 
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultados, setResultados] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    try {
      const response = await postarray( { searchTerm: value },'searchProductstiendafisica');
      console.log(response);
      setSuggestions(response.results);
      setShowSuggestions(value !== '');
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list mt-2">
        {suggestions.map((suggestion, index) => (
          <li  className='left' key={index}  onClick={() => handleCardClick(suggestion.id_fleksi_products)}>
   
            <ImageComponent
                        src={suggestion.image}
                        alt={suggestion.title}
                        defaultSrc={process.env.PUBLIC_URL + '/images/defaultimage.png'}
                        cssclass={'img-search'} />

            <span  className='inter_12_500 ml-2'  style={{marginTop:'15px', position:'absolute'}}>  {suggestion.title}</span>
          </li>
        ))}
      </ul>
    );
  };



  return (
    <div  className='w-100 bg-white ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
    <div className="banner ">
    <div className="left-div " onClick={handleGoBack}>
    <svg
            version="1.1"
            viewBox="0 0 1600 1600"
            width="40px"
            height="auto"
            xmlns="http://www.w3.org/2000/svg"
            className="back-icon"
          >
            <path
              transform="translate(663,330)"
              d="m0 0 5 1 8 6 54 54 8 7 12 12 2 1v2l4 2 8 9v5l-14 15-8 8-7 8-4 4h-2l-2 4-47 47-4 5-9 9h-2l-1 3-45 45h-2l-1 3-21 21h-2l-2 4-6 5-5 6-5 5h-2l-1 3-8 7-54 54-8 7-9 10-22 22h-2l-2 4-2 2 736 2 192 1 6 2 1 127-4 3-10 1-711 1h-191l-20-1 6 8 19 19 8 7 4 5 3 2v2l4 2 168 168v2l4 2 8 8v2l4 2v2l4 2 8 8v2l4 2v2l4 2 8 8v2l4 2 34 34 6 9v5l-8 8-8 7-26 26h-2v2h-2l-2 4-32 32-7 8-8 6-4 2v-2l-4-2-12-12-7-8-429-429-8-7-4-4v-2h-2l-3-5 10-13 13-12 17-16 17-17 1-2h2l2-4 390-390 7-8z"
            />
          </svg>
    </div>
    <div className="center-div ">
        <div className="search-container">
            <span className="search-icon">
                <img src={process.env.PUBLIC_URL + '/images/buscar.png'} alt="Buscar" />
            </span>
            <input
                style={{ marginTop: '5px', paddingLeft: '40px' }}
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                className="form-input form_rounded"
                placeholder="Buscar aquí"
            />
        </div>
    </div>
    <div className="right-div  pt-3">
 
    </div>
    {renderSuggestions()}
</div>
</div>
  );
};

export default HeaderTiendaFisica;
